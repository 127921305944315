import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addProductSingle, editProduct } from '../../actions/productAction'
import { useDispatch, useSelector } from 'react-redux'


export const useProducts = (toast, setShow, typeEdit) => {

	const dispatch = useDispatch()
	const [ bloquear, setBloquear ] = useState(false)
	const [ titleBtn, setTitleBtn ] = useState('')
	const [ titleModal, setTitleModal ] = useState('')
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit, errors, reset } = useForm()
	const { tenant, product  } = useSelector((state) => {
		return {
			tenant: state.tenant,
			product: state.product
		}
	})
	const { tenant_id  } = tenant
	const { item  } = product

	useEffect(() => {

		reset(item)
		setTitleBtn('Agregar')
		setTitleModal('Agregar Producto')
		if(item.sku!==''){
			setBloquear(true)
			setTitleBtn('Editar')
			setTitleModal('Editar Producto')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])

	const onSubmit =  async (data, e) => {
		e.preventDefault()
		setLoading(true)

		let product = {
  			tenant_id,
  			product_name: data.product_name,
  			sku: data.sku.toString(),
  			attributes: {
  				product_image: data.product_image,
  				description_product: data.description_product,
  				url: data.url,
  				categories: data.categories ? data.categories.split(",") : '',
  				count: typeof data?.count!=="undefined" ? parseInt(data?.count) : 0,
  				total_sales_before_rocio: typeof data?.total_sales_before_rocio!=="undefined" ? parseInt(data?.total_sales_before_rocio) : 0
  			},
  			active: item.active
  		}

  		let response

  		if(typeof item.id==='undefined'){
  			response = await dispatch(addProductSingle(product, data, typeEdit))
  		}else{
  			response = await dispatch(editProduct(item.id, product, data))
  		}

  		setLoading(false)
		if(response.err===null){
			toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
			reset(item)
			setShow(false)
		}else{
			toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
		}
	}

	return {
		register,
		onSubmit,
		handleSubmit, 
		errors,
		bloquear,
		titleBtn,
		titleModal,
		loading
	}
}