import API from '../config/api'
import {
	ADD_TENANT,
	LIST_TENANT_ASSIGN_USER,
	LOADING_TENANT,
	URL_DASHBOARD,
	SEARCH_TENANT,
	UPDATE_TENANT
} from '../types/tenantType'
import { verifyAccessToken } from '../utils/aws-cognito'

const baseURL = process.env.REACT_APP_API_URL 

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const addTenant = (tenant_name) => async (dispatch, getState) => {
	try{
		const body = { tenant_name, active: true }

		const session = await verifyAccessToken()

	  	let response = await API.post("tenant/", body, session?.token)

	  	const { data, err } = response.data
		
	    if(err===null){
			const payload = {
				data,
				id: data.id
			}
	        dispatch(requestSuccess(ADD_TENANT, payload))
	        localStorage.setItem("tenant", data.id)
		}

		return { err, msg: err }
    }catch(error){
      return {err: false, msg:error}
    }
}


export const assignTenant = (id) => (dispatch, getState) => {
	const { tenant_data } = getState().tenant

	const payload = { data: tenant_data, id }

	dispatch(requestSuccess(ADD_TENANT, payload))
    localStorage.setItem("tenant", id)

	return { err: null, msg: null, id }
}

export const searchTenantAssignUser = (email) => async (dispatch, getState) => {
	try{
		const initial = { listado: [], loading: true}
		dispatch(requestSuccess(LOADING_TENANT, initial))

		const session = await verifyAccessToken()

	  	let response = await API.get(`admins/tenants/${email}`, session?.token)

	  	let { data, err, msg } = response.data

	   	const payload = {
	   		listado: (data.length>0) ? data : [],
	   		loading: false
	   	}
	    dispatch(requestSuccess(LIST_TENANT_ASSIGN_USER, payload))
		
		if(data.length===0){
			err = false
			msg = "El usuario no tiene organizaciones asignadas"
		}

		return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}

export const getUrlDashboard = () => async (dispatch, getState) => {

	try{

		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

		const body = { tenant_id }

		if(baseURL==="https://prod.api.rocio.ai"){
			let response = await API.post('analytics/', body, session?.token)

		  	let { data, err, msg } = response.data

		   	if(err===null){
		   		msg=data
		   		dispatch(requestSuccess(URL_DASHBOARD, data))
		   	}else{
		   		msg = "Error al generar la url"
		   	}

			return { err, msg }
		}else{
			return { err:false, msg: "Esta característica solo esta disponible en el ambiente Productivo" }
		}
	  	
    }catch(error){
      return {err: false, msg:error}
    }
}

export const searchByTenant = () => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		
		if(tenant_id){
			const session = await verifyAccessToken()

		  	let response = await API.get(`tenant/${tenant_id}`, session?.token)
		  	const { data, err, msg } = response.data
			
		    if(err===null){
		    	const information = {...data, email: data?.personalize_data?.anonymous_email }
		        dispatch(requestSuccess(SEARCH_TENANT, information))
			}

			return { err, msg }
		}
		
    }catch(error){
      return {err: false, msg:error}
    }
}

export const updateTenant = (url_api) => async (dispatch, getState) => {
	try{
		const { tenant_data } = getState().tenant

		const body = { 
			...tenant_data, 
			personalize_data: {
				...tenant_data.personalize_data,
				url_api_product: url_api
			}
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("tenant/update", body, session?.token)

	  	let { err } = response.data
		
	    if(err===null){
			const payload = { data: body }
	        dispatch(requestSuccess(UPDATE_TENANT, payload))
		}else{
			err = "Error al agregar la url de la api de producto"
		}

		return { err, msg: err }
    }catch(error){
      return {err: false, msg:error}
    }
}

