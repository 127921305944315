import { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import { useForm } from 'react-hook-form'
import Layout from '../../Components/Template/Layout'

import Files from '../../Components/Template/Products/Files'
import Template from '../../Components/Template/Products/Template'
import Information from '../../Components/Template/Products/Information'
import { useDispatch } from 'react-redux'
import { getValidationFileProduct, resetListProducts } from '../../actions/productAction'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const CreateProduct = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const { register, handleSubmit, errors, setValue } = useForm()
	const [ loading, setLoading ] = useState(false)


	const onSubmit =  async (data, e) => {
		e.preventDefault()
		
		setLoading(true)
		const response = await dispatch(getValidationFileProduct(data.cargas))

		setLoading(false)
		if(response.err===null){
			history.push('/configure/product/preview')
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	useEffect(() => {
		dispatch(resetListProducts())
		// eslint-disable-next-line react-hooks/exhaustive-deps	
	}, [])

	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  			titleMenu="Carga de productos" 
		  	  			tab="1" 
		  	  			title="Cargar productos recomendados" 
		  	  			subtitle="Ingresa los productos que quieres comenzar a recomendar" 
		  	  			isVisible={true}
		  	  			url="/configure/type"
		  	  			isClass="col-xs-12 col-sm-9 col-lg-6 mx-auto padding-top-and-buttom"
		  	>
		  		<Form className="mt-5" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" autoComplete="false">
			  		<Row>
					    <Col xs={12} className="d-flex justify-content-between mb-3 flex-column flex-md-row">
							<Template 
								title="Base de datos"
							/>
					    </Col>
					    <Col xs={12} className="mb-4">
					    	<Files 
					    		title="Base de datos producto*" 
					    		errors={errors} 
					    		register={register} 
					    		setValue={setValue}
					    	/>
					    </Col>
					    <Col xs={12}>
					    	<Information title="La tabla debe contener los siguientes datos obligatorios" />
					    </Col>
					    
					    <Col md={12} xs={12} className="mb-5">
					    	<Button variant="primary" className="btn-continuar btn-panel-config mt-5" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' }
							</Button>
							<p className="text-help"><a href="/help">¡Necesito ayuda!</a></p>
					    </Col>
					</Row>
				</Form>
		  	</MenuStep>
	  	</Layout>
  );
}

export default CreateProduct;