import Layout from '../../Components/Dashboard/Layout'
import { Row, Col, Card, Button, Form } from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

const PanelUser = () => {

	const { userAdmin } = useSelector((state) => state.userAdmin)

	const { register, handleSubmit, errors } = useForm()

	const onSubmit =  async (data, e) => {
		e.preventDefault()
	}

	return (
	    <Layout title="MI PERFIL" modulo="user" isVisible={false}>
	    	<Form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
		    	<Row>
				    <Col xs={11} className="mb-5 mx-auto">
				      	<Card body className="mb-5 pt-5 pl-5 pr-5 card-shadow ">
				      		<Row className="mb-3">
							    <Col md={6} xs={12} className="mb-3">
							       <div className="input-group-perfil">
							      		<label className="label" htmlFor="name">Nombre</label>
							      		<input 
							      			type="text" 
							      			name="name"
							      			autoFocus
							      			id="name"  
							      			className="input input--readonly" 
							      			defaultValue={userAdmin?.attributes?.name}
											readOnly={true}
							      			ref={register({required: {value: true, message: 'Requerido'}})}
							      		/>
									    {errors.name && (
									    	<Form.Text className="text-danger">
									      		{errors?.name?.message}
									    	</Form.Text>
									    )}
							        </div>	
							    </Col>
							    <Col md={6} xs={12} className="mb-3">
							      	<div className="input-group-perfil">
							      		<label className="label" htmlFor="lastname">Apellido</label>
							      		<input 
							      			type="text" 
							      			name="lastname" 
							      			id="lastname" 
							      			className="input input--readonly"
							      			defaultValue={userAdmin?.attributes?.lastname}
											readOnly={true}
							      			ref={register({required: {value: true, message: 'Requerido'}})} 
							      		/>
							      		{errors.lastname && (
									    	<Form.Text className="text-danger">
									      		{errors?.lastname?.message}
									    	</Form.Text>
									    )} 
							        </div>	
							    </Col>
							</Row>
							<Row className="mb-3">
							    <Col md={6} xs={12} className="mb-3">
							       <div className="input-group-perfil">
							      		<label className="label" htmlFor="email">Correo</label>
							      		<input 
							      			type="text" 
							      			name="email" 
							      			id="email" 
							      			className="input input--readonly" 
							      			defaultValue={userAdmin?.external_id}
							      			readOnly={true}
							      			ref={register({required: {value: true, message: 'Requerido'}})}
							      		/>
							      		{errors.email && (
									    	<Form.Text className="text-danger">
									      		{errors?.email?.message}
									    	</Form.Text>
									    )} 
							        </div>	
							    </Col>
							    {/* <Col md={6} xs={12} className="mb-3">
							       <div className="input-group-perfil">
							      		<label className="label" htmlFor="password">Contraseña</label>
							      		<input 
							      			type="password" 
							      			name="password" 
							      			id="password" 
							      			className="input input--readonly" 
							      			placeholder="Escribe aquí tu contraseña"
											readOnly={true}
							      			ref={register({required: {value: true, message: 'Requerido'}})}
							      		/>
							      		{errors.password && (
									    	<Form.Text className="text-danger">
									      		{errors?.password?.message}
									    	</Form.Text>
									    )} 
							        </div>	
							    </Col> */}
							</Row>
							{/* <Row className="mb-3">
							    <Col md={6} xs={12} className="mb-3">
							      	<div className="input-group-perfil">
							      		<label className="label" htmlFor="new_password">Nueva contraseña</label>
							      		<input 
							      			type="new_password" 
							      			name="new_password" 
							      			id="password" 
							      			className="input" 
							      			placeholder="Escribe aquí tu contraseña"
							      			ref={register({required: {value: true, message: 'Requerido'}})}
							      		/>
							      		{errors.new_password && (
									    	<Form.Text className="text-danger">
									      		{errors?.new_password?.message}
									    	</Form.Text>
									    )} 
							        </div>	
							    </Col>
							</Row> */}
							<Row className="d-none"> 
						        <Col md={{ span: 4, offset: 8 }} xs={12} className="mb-5 mt-5">
							    	<Button variant="outline-primary" className="text-script pt-3 pb-3" type="button" block>
									    Guardar
									</Button>
							    </Col>
						    </Row>
				      	</Card>
				    </Col>
				</Row>
			</Form>
	    </Layout>
	);
}

export default PanelUser;