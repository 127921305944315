import { Button, Form } from 'react-bootstrap'

import ModalProduct from '../../Products/ModalProduct'

import { useInteraction } from '../../../../hooks/Interactions/useInteraction'

const FormModalEdit = ({ toast, handleClose, show, listNameInteraction, listOptions }) => {

	const {
	    register,
		onSubmit,
		handleSubmit, 
		errors,
		bloquear,
		titleBtn,
		titleModal,
		loading,
		visibleAttrib,
		setVisibleAttrib
	  } = useInteraction(toast)

	const verifyTypeAttribute = (e) => {
		if(e.target.value==='data'){
			setVisibleAttrib(true)
		}else{
			setVisibleAttrib(false)
		}
	}


	return (
		<ModalProduct show={show} handleClose={handleClose} titleModal={titleModal}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
				    <div className="form-group col-md-3 form-interaccion">
				    	<label htmlFor="name_login">Nombre</label>
						<select 
					    	name="name" 
					    	id="name_login" 
					     	ref={register({required: {value: true, message: 'Requerido'}})}
					     	className={`form-control ${bloquear ? 'input--readonly' : ''}`}
					    	disabled={bloquear}
					     >
						    <option value="">Seleccione</option>
						    {
					    		listNameInteraction.length>0 && 
					    		listNameInteraction.map((item, key) => 
					    			<option key={key} value={item.name}>{item.name}</option>
					    		)
					    	}
						</select>
					    {errors.name && (
					    	<Form.Text className="text-danger">
					      		{errors?.name?.message}
					    	</Form.Text>
					    )}
				    </div>
				    <div className="form-group col-md-4 form-interaccion">
				      	<label htmlFor="clase_login">Clase</label>
					    <textarea 
					    	name="class_action" 
					    	id="clase_login"
					    	cols="2" 
					    	rows="2"
					    	className="form-control" 
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    	placeholder="Escribe la clase"
					    ></textarea>
					    {errors.clase && (
					    	<Form.Text className="text-danger">
					      		{errors?.clase?.message}
					    	</Form.Text>
					    )}
				    </div>
				    <div className="form-group col-md-5 form-interaccion">
				    	<label htmlFor="description_login">Descripción</label>
					    <textarea 
					    	name="description"
					    	id="description_login"
					    	cols="2" 
					    	rows="2"
					    	className={`form-control ${bloquear ? 'input--readonly' : ''}`}
					    	readOnly={bloquear}
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    	placeholder="Escribe la descripción"
					    ></textarea>
					    {errors.description && (
					    	<Form.Text className="text-danger">
					      		{errors?.description?.message}
					    	</Form.Text>
					    )}
				    </div>
				</div>
				<div className="form-row">
				    <div className="form-group col-md-3 form-interaccion">
				    	<label htmlFor="type_attribute" >Tipo de Atributo</label>
					    <select 
					    	name="type_attribute" 
					    	id="type_attribute" 
					     	className="form-control"
					     	ref={register}
					     	onChange={(e)=>verifyTypeAttribute(e)}
					     >
						    <option value="">Seleccione</option>
						    {
								listOptions.length>0 &&
								listOptions.map((item, key) => 
								<option key={key} value={item.name}>{item.name}</option>
								)
							}
						</select>
					    {errors.type_attribute && (
					    	<Form.Text className="text-danger">
					      		{errors?.type_attribute?.message}
					    	</Form.Text>
					    )}
				    </div>
				    {
				    	visibleAttrib && (
				    		 <div className="form-group col-md-3 form-interaccion">
						    	<label htmlFor="value_attribute">Valor del Atributo</label>
							    <input 
							    	type="text"
							    	name="value_attribute"
							    	id="value_attribute"
							    	className="form-control"
							    	ref={register({required: {value: true, message: 'Requerido'}})}
							    	placeholder="Escribe el valor del attributo"
							    />
						    </div>
				    	)
				    }				   
				</div>
				<div className="form-row">
					<div className="form-group col-md-12 text-right">
			          	<Button type="submit" className="btn-primary--height mr-2" disabled={loading}>
				            { loading ? 'Procesando...' : titleBtn }
				        </Button>
			          	<Button variant="secondary" onClick={handleClose}>
			            	Cerrar
			          	</Button>
					</div>
				</div>
			</Form>
		</ModalProduct>
	)
}

export default FormModalEdit