import { useState } from 'react'
import { Row, Col, Card, Accordion, useAccordionToggle  } from 'react-bootstrap'
import Layout from '../Components/Template/Layout'

import Arrow from '../assets/images/down-arrow.svg'
import ArrowUp from '../assets/images/up-arrow.svg'

const Help = () => {
	const [activeKey, setActiveKey] = useState('0')

	const text_help = [
		{
			id: 1,
			title: '¿Cómo subir el archivo de productos correctamente? ',
			description: 'Es necesario alimentar previamente el algoritmo de ROCIO para su correcto funcionamiento. En primera instancia, el orden de los productos que muestra el widget corresponderá al mismo orden en que se suban. Una vez que se empiece a interactuar con el sitio web este orden cambiará y los productos que el algoritmo estime que te puedan interesar, se posicionarán arriba de la lista.',
			description2: 'Suelen ocurrir ciertos errores al subir los productos, por lo que te aconsejamos descargar el archivo ejemplo y rellenar todos los campos correctamente: ',
			items: ['Asegúrate de que los datos no tengan saltos de líneas o viñetas.',
				'¡Ninguna celda debe quedar en blanco!', 
				'Verifica que el tamaño del archivo no supere el máximo permitido.', 
				'Revisa que los encabezados sean los correspondientes.',
				'Revisa que los encabezados sean los correspondientes '
			]
		},
		{
			id: 2,
			title: '¿Por qué no aparece el widget en mi página web? ',
			description: '¡Tranquilo! Asegúrate de que la URL del sitio web corresponda con la del e-commerce. Por el momento, es obligación ser un usuario registrado e iniciar sesión dentro del sitio web para que aparezca ROCIO. ¡No lo olvides!',
			description2: 'Estamos en constante desarrollo, por seguridad, asegúrese de seguir estrictamente los 3 pasos de la guía de instalación de ROCIO. ',
			items: []
		}//,
		// {
		// 	id: 3,
		// 	title: '¿Lorem ipsum dolor sit amet 3?',
		// 	description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit mi sem, euismod mauris netus vivamus mus nulla enim venenatis placerat in, nibh vestibulum habitasse habitant egestas nostra nullam praesent.'
		// },
		// {
		// 	id: 4,
		// 	title: '¿Lorem ipsum dolor sit amet 4?',
		// 	description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit mi sem, euismod mauris netus vivamus mus nulla enim venenatis placerat in, nibh vestibulum habitasse habitant egestas nostra nullam praesent.'
		// },
		// {
		// 	id: 5,
		// 	title: '¿Lorem ipsum dolor sit amet 5?',
		// 	description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit mi sem, euismod mauris netus vivamus mus nulla enim venenatis placerat in, nibh vestibulum habitasse habitant egestas nostra nullam praesent.'
		// },
		// {
		// 	id: 6,
		// 	title: '¿Lorem ipsum dolor sit amet 6?',
		// 	description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit mi sem, euismod mauris netus vivamus mus nulla enim venenatis placerat in, nibh vestibulum habitasse habitant egestas nostra nullam praesent.'
		// }
	];


	const CustomerHead = ({children, eventKey, callback}) => {
	     const decoratedOnClick = useAccordionToggle(
		    eventKey,
		    () => callback && callback(eventKey),
		  );
	    return (<div className='accordion-header d-flex w-100 justify-content-between' onClick={decoratedOnClick}>
	    			<p>{children}</p>
	    			<img src={activeKey === eventKey ? Arrow : ArrowUp} alt="" />
	    		</div>)
	  }

	return (
	    <Layout volver={false}>
	    	<Row>
		        <Col xs={12} lg={9} md={{ span: 10, offset: 1 }} className="mx-auto">
		          <Card className="mt-3 mb-md-5 bg-white">
		            <Card.Header className="encabezado-help bg-white pl-5">Preguntas frecuentes</Card.Header>
		        	<Card.Body className="p-0 bg-white pl-4 pr-4">
		        		<Accordion  activeKey={activeKey} onSelect={(event) => setActiveKey(event)}>
		        			{
		        				text_help.length>0 && (
		        					text_help.map(texto => (
	        							<Card className="border-0" key={texto.id}>
									        <Card.Header className="bg-white align-items-center">
									          <CustomerHead  eventKey={`${texto.id}`}>{texto.title}</CustomerHead>
									        </Card.Header>
									        <Accordion.Collapse eventKey={`${texto.id}`}>
									          <Card.Body className="pt-2 pb-5">{texto.description}<br/><br/>
											  {texto.description2}<br/><br/>
											  {Array.isArray(texto.items) && <ul>{texto.items.map(item => <li>{item}</li>)}</ul>}</Card.Body>
									        </Accordion.Collapse>
									    </Card>
										 
		        					))
		        				)
		        			} 
						</Accordion>
		        	</Card.Body>
		          </Card>
		        </Col>
		      </Row>
	    </Layout>
	);
}

export default Help;
