import { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import XLSX from 'xlsx'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {  addProduct, getValidationFileProduct } from '../../../actions/productAction'


const UploadFile = ({ toast }) => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors, setValue } = useForm()
	const [ titleFile, setTitleFile ] = useState('Subir archivo CVC, XLS')
	const [ loading, setLoading ] = useState(false)

	const onSubmit =  async (data, e) => {
		e.preventDefault()
		setLoading(true)

		let response = await dispatch(getValidationFileProduct(data.products))

		if(response.err===null){
			response = await dispatch(addProduct("single"))
			if(response.err===null){
				toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
			}
		}else{
			toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
		}
		setLoading(false)
	}

	const handleFile = (event) => {
		if (!event.target.files || event.target.files.length === 0) {
			setTitleFile('Subir archivo CVC, XLS')
		}else{
			let file = event.target.files[0]
			setTitleFile(file.name)		

			let reader = new FileReader()

	        reader.onload = function (e) {
	            let data = e.target.result
	            data = new Uint8Array(data)

	            let workbook = XLSX.read(data, {type: 'array'})

	            let first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
				let result = XLSX.utils.sheet_to_json(first_worksheet, {header:2})
	            
	            register({ name: "products" })
	            setValue("products", result)
	        };
	        reader.readAsArrayBuffer(file);
	    }
	}

	return (
    	<Form onSubmit={handleSubmit(onSubmit)}>
	    	<Row>
	    		<Col md={6}>
					<p className="input-title mb-1">Base de datos producto</p>
					<div className="form-group custom-file">
					    <input 
					    	type="file" 
					    	className="custom-file-input mb-5" 
					    	name="base_producto" 
					    	id="base_producto"
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    	accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					    	onChange={(event)=>handleFile(event)} 
					    />
					    <label className="custom-file-label" htmlFor="base_producto" >{titleFile}</label>
					    {errors.base_producto && (
					    	<Form.Text className="text-danger">
					      		{errors?.base_producto?.message}
					    	</Form.Text>
					    )}
					</div>
	    		</Col>
	    		<Col md={{ span: 4, offset: 2 }} xs={12} className="mb-5 mt-4">
			    	<Button variant="outline-primary" className="text-script btn-add-product" type="submit" block disabled={loading}>
					   {loading ? 'Procesando...' : 'Guardar'} 
					</Button>
			    </Col>
	    	</Row>
    	</Form>
	);
}

export default UploadFile;