import { Container, Row, Col } from 'react-bootstrap'


const PanelDemo = ({children}) => {
	

	return (
		<div className="container-fluid bg-demo">
			<Container>
				<Row className="row-top">
					<Col md={5} className="pt-5">
						<p className="title-demo mb-0">Solicite una</p>
						<p className="title-demo mb-3">demostración</p>
						<p className="subtitle-demo mb-0">¿Tienes dudas? Nuestro equipo se pondrá en </p>
						<p className="subtitle-demo">contacto para aclarar todas tus dudas.</p>
					</Col>
					<Col md={7}>
						{children}
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default PanelDemo;
