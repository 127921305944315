import API from '../config/api'
import {
	LIST_PRODUCT,
	LOADING_PRODUCT,
	CHECK_BELL_STATUS_PRODUCT,
	EDIT_PRODUCT,
	DATA_PRODUCT,
	DELETE_PRODUCTS,
	SELECT_DELETE_PRODUCTS,
	ADD_PRODUCT_SINGLE,
	RESET_LIST_PRODUCT,
	DELETE_PRODUCTS_PREVIEW,
	LIST_PRODUCT_PREVIEW,
	ADD_PRODUCT_LIST,
	FILTERED_PRODUCT_DASHBOARD,
	GROUP_BY_CATEGORY_PRODUCT,
	GRAFIC_DATA_DASHBOARD
} from '../types/productType'
import { verifyAccessToken } from '../utils/aws-cognito'
import { getListValidate, validateRow, validateDuplicateProduct, crearSlug, 
	generateArrayCategory, validateProductClic } from '../utils/validation-product'
import { formatMonto } from '../utils/format-number'
import { format } from "date-fns"
import { getMonthDate, groupProductByMonth, filterProductGroup, groupProductPercentageByMonth } from '../utils/tool-date'

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const getValidationFileProduct = (list_products) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		//const { list } = getState().product
	
		if(tenant_id){
			let products_correct = []

			const isValid = getListValidate(list_products)
			
			if(isValid.err === null){

				const session = await verifyAccessToken()

				let products = list_products.map(item => {
					
					let subcategories = []
					
					if(item?.subcategories && item?.subcategories!==""){
						/**if(item?.subcategories.includes("-")){
							subcategories = item?.subcategories.split("-").map(item => item.trim())
						}else{
							subcategories = item?.subcategories
						}**/
						subcategories = item?.subcategories.trim()
					}
					
					products_correct.push({
			  			tenant_id,
			  			product_name: item.item_name.trim(),
			  			sku: item.sku.toString().trim(),
			  			attributes: {
			  				product_image: item.product_image ? item.product_image.trim() : "",
			  				description_product: item.description_item ? item.description_item.trim() : "",
			  				url: item.url ? item.url.trim() : "",
			  				categories: item.categories,
							subcategories,
							count: typeof item?.count!=="undefined" ? parseInt(item?.count) : 0,
							total_sales_before_rocio: typeof item?.total_sales_before_rocio!=="undefined" ? parseInt(item?.total_sales_before_rocio) : 0,
							ecommerceId: item.ecommerceId
			  			},
			  			active: true
			  		})

					const body = { sku: item.sku.toString().trim(), tenant_id }

			  		return API.post('product/getproductbytenantidandsku', body, session?.token)
			  	})
			
			  	let items = await Promise.all(products)

			  	const error = items.map(x => x.data).filter(p=>p.err!==null)
			  	
			  	let err = null, msg = null

			  	if(error.length > 0){
			  		err = true	    	
			    	msg=`Algunos productos ya están asignado al tenant`
			  	}else{
			  		const payload = {products: products_correct, is_loading: false}
		        	dispatch(requestSuccess(LIST_PRODUCT_PREVIEW, payload))
			  	}

		        return { err, msg }
			}

			return isValid
		}

		return { err: true, msg: 'Debes crear una cuenta de usuario con el nombre de la organización'}
	  	
    }catch(error){
      return {err: true, msg:error}
    }
}

export const getValidationFileProductApi = (list_products) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		//const { list } = getState().product
	
		if(tenant_id){
			let products_correct = []

			const isValid = getListValidate(list_products)
			
			if(isValid.err === null){

				const session = await verifyAccessToken()

				let products = list_products.map(item => {
					
					let subcategories = []
					
					if(item?.subcategories && item?.subcategories!==""){
						/**if(item?.subcategories.includes("-")){
							subcategories = item?.subcategories.split("-").map(item => item.trim())
						}else{
							subcategories = item?.subcategories
						}**/
						subcategories = item?.subcategories.trim()
					}
					
					products_correct.push({
			  			tenant_id,
			  			product_name: item.item_name.trim(),
			  			sku: item.sku.toString().trim(),
			  			attributes: {
			  				product_image: item.product_image ? item.product_image.trim() : "",
			  				description_product: item.description_item ? item.description_item.trim() : "",
			  				url: item.url ? item.url.trim() : "",
			  				categories: item.categories,
							subcategories,
							count: typeof item?.count!=="undefined" ? parseInt(item?.count) : 0,
							total_sales_before_rocio: typeof item?.total_sales_before_rocio!=="undefined" ? parseInt(item?.total_sales_before_rocio) : 0,
							ecommerceId: item.ecommerceId
			  			},
			  			active: true
			  		})

					const body = { sku: item.sku.toString().trim(), tenant_id }

			  		return API.post('product/getproductbytenantidandsku', body, session?.token)
			  	})
			  	let items = await Promise.all(products)

			  	const error = items.map(x => x.data).filter(p=>p.err!==null)
			  	
			  	let err = null, msg = null

			  	if(error.length > 0){
			  		for(let index in error){
			  			products_correct = products_correct.filter(item => item.sku!==error[index].data.sku)
			  		}
			  	}

			  	const payload = {products: products_correct, is_loading: false}
		        dispatch(requestSuccess(LIST_PRODUCT_PREVIEW, payload))

		        return { err, msg }
			}

			return isValid
		}

		return { err: true, msg: 'Debes crear una cuenta de usuario con el nombre de la organización'}
	  	
    }catch(error){
      return {err: true, msg:error}
    }
}


export const addProduct = (type="step") => async (dispatch, getState) => {
	try{
		let { list_product_preview, list } = getState().product

		if(list_product_preview.length>0){
			const session = await verifyAccessToken()
			
		  	let products = list_product_preview.map(item => {
		  		
		  		const url = (type==="single") ? "product/addproductpersonalize" : "product/"
		  		
		  		return API.post(`${url}`, item, session?.token)		  		
		  	})

		  	let items = await Promise.all(products)
		  	
		  	const error = items.filter(item => item.data.err!==null)

		  	let err = error.length > 0 ? true : null
		  	let msg = error.length > 0 ? "Error al registar los productos" : "Se han agregado con éxito"

		  	if(err === null){
		  		const payload = [...list, ...list_product_preview]
		        dispatch(requestSuccess(ADD_PRODUCT_LIST, payload))
		  	}

	        return { err, msg }
		}

		return { err: true, msg: "Debe ingresar los productos"}
		
    }catch(error){
      return {err: true, msg:error}
    }
}

export const addProductSingle = (body, form, typeEdit) => async (dispatch, getState) => {
	try{
		const { list, list_product_preview } = getState().product

		const session = await verifyAccessToken()

		const isValid = validateRow(form)

		if(isValid.err === null){

			if(typeEdit){
				const response = await API.post("product/addproductpersonalize", body, session?.token)

			  	let { err, msg, data } = response.data

			  	msg=err

			  	if(data!==null){
			  		msg = "Se ha agregado con éxito"
			  		const payload = [ ...list, data ]
			  		dispatch(requestSuccess(ADD_PRODUCT_SINGLE, payload))
			  	}

			  	return { err, msg }
			}else{
				const index = list_product_preview.findIndex(obj => obj.sku===body.sku)
				list_product_preview[index]= body
		    	dispatch(requestSuccess(EDIT_PRODUCT, list_product_preview))
		    	return { err: null, msg: "Se ha editado con éxito" }
			}	        
	    }
	    return isValid
	  	
    }catch(error){
      return {err: true, msg:error}
    }
}

export const listProducts = (body) => async (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_PRODUCT, true))

		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

	  	let response = await API.get(`product/index/${tenant_id}`, session?.token)

	  	const { data, err } = response.data

	    if(err===null){

	    	let items = data.map(item => {
	    		
	  			return {
	  				id: item.id,
			        product_name: typeof item?.product_name!=="undefined" ? item?.product_name : "",
			        attributes: {
			        	description_product: typeof item?.attributes?.description_product!=="undefined" ? item?.attributes?.description_product : "",
					    product_image: typeof item?.attributes?.product_image!=="undefined" ? item?.attributes?.product_image : "",
					    count: typeof item?.attributes?.count!=="undefined" ? item?.attributes?.count : 0,
					    total_sales_before_rocio: typeof item?.attributes?.total_sales_before_rocio!=="undefined" ? item?.attributes?.total_sales_before_rocio : 0,
					    categories: typeof item?.attributes?.categories!=="undefined" ? item?.attributes?.categories : "",
					    subcategories: typeof item?.attributes?.subcategories!=="undefined" ? item?.attributes?.subcategories : "",
					    url: typeof item?.attributes?.url!=="undefined" ? item?.attributes?.url : ""
					},
			        sku: item.sku,
			        active: item.active
			      }
	  		})
	    	
			  
	  		const payload = {products: items, is_loading: false}
	        dispatch(requestSuccess(LIST_PRODUCT, payload))
		}
    }catch(error){
      return {status: false, message:error}
    }
}

export const checkStatusBellProduct = () => async (dispatch, getState) => {
	try{
		
		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

	  	let response = await API.get(`campaign/get/${tenant_id}`, session?.token)

	  	let { err, msg, data } = response.data

		let status_sin_campana = 'No ha iniciado el proceso de creación de una campaña.'
		let status_in_proccess = 'La campaña aun está en proceso de creación.'
		let status_create_pending = 'CREATE PENDING'
		let status_create_in_progress = 'CREATE IN_PROGRESS'
		
	    if(err===null && (data.campaign_status==='ACTIVE')){
	    	dispatch(requestSuccess(CHECK_BELL_STATUS_PRODUCT, data.campaign_status))
	    	msg=data.campaign_status
	    	err=null
		}else if(err===null && (data.campaign_status.toLowerCase()===status_sin_campana.toLowerCase())){
			msg=data.campaign_status
			err=true
		}else if(err===null && 
			(
				data.campaign_status.toLowerCase()===status_create_pending.toLowerCase()
				|| data.campaign_status.toLowerCase()===status_create_in_progress.toLowerCase()
			)
			){
			msg=status_in_proccess
			err=null
		}else{
			msg=data.campaign_status
			err=null
		}

	    return {err, msg}
	    
    }catch(error){
      return {err: false, msg:error}
    }
}

export const createBellProduct = () => async (dispatch, getState) => {
	try{
		
		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

	  	let response = await API.get(`campaign/create/${tenant_id}`, session?.token)
	  	
	  	let { err, msg } = response.data

	    return {err, msg}
	    
    }catch(error){
      return {err: false, msg:error}
    }
}

export const deleteProducts = (products) => async (dispatch, getState) => {
	try{

		const { list } = getState().product
		
		let item = list
	    for(let x=0; x<products.length; x++){
    		item = removeItemFromArr(item, products[x].sku)
    	}
    	const payload = { item, delete_selected: []}
    	dispatch(requestSuccess(DELETE_PRODUCTS, payload))
    		  	
	    return { err: null, msg: "Se han eliminados los productos con éxito"}
	    
    }catch(error){
      return { err: true, msg: error}
    }
}

export const editProductAll = (products) => async (dispatch, getState) => {
	try{

		const { list, deleted_option } = getState().product
		const { tenant_id } = getState().tenant
		
		const session = await verifyAccessToken()
			
		let items_edit = products.map(product => {
			const item = {
				...product, 
				active: !product.active,
				tenant_id
			}

	  		return API.post(`product/addproductpersonalize`, item, session?.token)
	  	})

	  	let items = await Promise.all(items_edit)

	  	const error = items.filter(item => item.data.err!==null)

	  	let result = { err: null, msg: ''}

	  	if(error.length===0){
	    	let item = list
	    	for(let x=0; x<products.length; x++){
	    		const index = list.findIndex(obj => obj.sku===products[x].sku)
    			item[index].active = !item[index].active
	    	}
	    	const payload = { item, delete_selected: []}
	    	dispatch(requestSuccess(DELETE_PRODUCTS, payload))
	    	result.msg= `Los productos se han ${deleted_option ? 'desactivado' : 'activado' } satisfactoriamente`
	  	}else{
	  		result = { err: true, msg: error[0]}
	  	}
	  	
	    return result
	    
    }catch(error){
      return { err: true, msg: error}
    }
}

const removeItemFromArr = ( arr, item ) => {
    return arr.filter( function(product ) {
        return product.sku !== item;
    });
    
}

export const editProduct = (id, body, form) => async (dispatch, getState) => {
	try{
		let { list } = getState().product

		const session = await verifyAccessToken()

		const index = list.findIndex(obj => obj.id===id)
		
		const subcategories = form?.subcategories ? form?.subcategories.replaceAll("\n"," - ") : []

		const dataForm = {...form, categories: list[index].attributes?.categories, subcategories }

		const isValid = validateRow(dataForm)

		if(isValid.err === null){
			const information = {
				...body,
				attributes: {
					...body.attributes,
					categories: list[index].attributes?.categories,
					subcategories: (dataForm?.subcategories && dataForm?.subcategories.length>0) ? dataForm?.subcategories.split("-").map(item => item.trim()) : []
				},
				id
			}

			//let response = await API.put(`product/${id}`, information, session?.token)
			let response = await API.post(`product/addproductpersonalize`, information, session?.token)
			
		  	let { err, msg, data } = response.data

		    if(err===null){
		    	list[index]= data
		    	dispatch(requestSuccess(EDIT_PRODUCT, list))
		    	msg = "Se ha editado con éxito"
			}else{
				msg = err
			}

		    return { err, msg }		  
		}

		return isValid

    }catch(error){
      return {err: true, msg:error}
    }
}

export const initialValueFormProduct = (row) => (dispatch, getState) => {
	let payload = {
		sku: (Object.keys(row).length>0) ? row.sku : '',
		product_name: (Object.keys(row).length>0) ? row.product_name : '',
		categories: (Object.keys(row).length>0) ? row.attributes.categories : '',
		subcategories: (Object.keys(row).length>0) ? row.attributes?.subcategories : '',
		product_image: (Object.keys(row).length>0) ? row.attributes.product_image : '',
		description_product: (Object.keys(row).length>0) ? row.attributes.description_product : '',
		url: (Object.keys(row).length>0) ? row.attributes.url : '',
		active: (Object.keys(row).length>0) ? row.active : true,
		id: (Object.keys(row).length>0) ? row.id : 0,
		count: (Object.keys(row).length>0) ? row?.attributes?.count : 0,
  		total_sales_before_rocio: (Object.keys(row).length>0) ? row?.attributes?.total_sales_before_rocio : 0 
	}
	dispatch(requestSuccess(DATA_PRODUCT, payload))
}

export const selectedItem = (row, status) => (dispatch, getState) => {

	const { item_delete_selected, deleted_option } = getState().product

	let search = item_delete_selected.find(x => x.id===row.id)

	let items = []

	if(typeof search === 'undefined'){
		items = [...item_delete_selected, row]
	}else{
		items = item_delete_selected.filter(x => x.id!==row.id)
	}
	let option = ''
	if(items.length===1){
		option = status
	}else if(items.length>1){
		option= deleted_option
	}
	
	const payload = { item_delete_selected: items, option }

	dispatch(requestSuccess(SELECT_DELETE_PRODUCTS, payload))
}

export const selectedItemDelete = (row) => (dispatch, getState) => {

	const { items_delete } = getState().product

	let search = items_delete.find(x => x.sku===row.sku)

	let items = []

	if(typeof search === 'undefined'){
		items = [...items_delete, row]
	}else{
		items = items_delete.filter(x => x.sku!==row.sku)
	}

	dispatch(requestSuccess(DELETE_PRODUCTS_PREVIEW, items))
}

export const deselectedCheckedProduct = () => (dispatch, getState) => {

	const payload = { item_delete_selected: [], option: '' }

	dispatch(requestSuccess(SELECT_DELETE_PRODUCTS, payload))
}


export const changeActiveProduct = (product) => async (dispatch, getState) => {
	try{

		const { list } = getState().product
		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()
		
		const data = { ...product, tenant_id }

		let response = await API.post(`product/addproductpersonalize`, data, session?.token)		
		  	
	  	let { err, msg } = response.data
	
	    if(err===null){
	    	const index = list.findIndex(obj => obj.sku===product.sku)
	    	list[index].active = product.active
	    	const payload = list
	    	dispatch(requestSuccess(EDIT_PRODUCT, payload))
	    	
	    	msg = `Se ha ${product.active ? 'activado' : 'desactivado'} el producto con éxito`
		}else{
			msg = err
		}

	    return { err, msg }

    }catch(error){
      return {err: true, msg:error}
    }
}

export const resetListProducts = () =>  (dispatch, getState) => {
	dispatch(requestSuccess(RESET_LIST_PRODUCT, []))
}

export const getListProductByAPi = (body) =>  async (dispatch, getState) => {
	
	try{

		let response = await API.getDataAPi(body.url,  "")
		const { data } = response.data
		
		let bodyProduct = [], msgValidation = "", errValidation=null

		if(body.visibleFKey==="si"){
			let error_count =false
			for(let key in data){
				let product = data[key]
				if(typeof product[body.inputItemName]=== "undefined" || typeof product[body.inputProductImage]=== "undefined"
					|| typeof product[body.inputUrl]=== "undefined" || typeof product[body.inputCategories]=== "undefined"
					|| typeof product[body.inputSku]=== "undefined" || typeof product[body.inputDescriptionItem]=== "undefined"){
						error_count=true
				}
			}
			if(!error_count){
				
				for(let index in data){

					let product = data[index]
				
					let subcategories = []
					if(body?.inputSubCategories && body?.inputSubCategories!==""){
						if(product[body.inputSubCategories]?.includes("-")){
							subcategories = product[body.inputSubCategories]?.split("-")?.map(item => item.trim())
						}else{
							subcategories= product[body.inputSubCategories]
						}
					}



					bodyProduct.push({
						item_name: product[body.inputItemName],
						product_image: product[body.inputProductImage],
						url: product[body.inputUrl],
						categories: product[body.inputCategories],
						sku: product[body.inputSku],
						description_item: product[body.inputDescriptionItem],
						subcategories,
						count: typeof product[body.inputFCantidad]=== "undefined" ? 0 : product[body.inputFCantidad],
						total_sales_before_rocio: typeof product[body.inputFventa]=== "undefined" ? 0 : product[body.inputFventa],
					})
				}
			}else{
				msgValidation = "Algunas claves no existe en la api"
				errValidation = true
			}
			
		}else{
			/**let count_column = 0
			const column_keys = [
				"item_name", "product_image", "url", "categories", "sku", "description_item", "ecommerceId", "count"
			]

			const column_data = Object.keys(data[0])
			for(let index in column_data){
				if(column_keys.includes(column_data[index])){
					count_column++
				}
			}

			if(count_column<column_keys.length){
				msgValidation = "Falta una clave en la api ingresada"
				errValidation = true
			}**/
			
			if(!errValidation){
				bodyProduct = data.map(item => {
					return {
						item_name: item?.item_name,
						categories: item?.categories.map(item => item.name).join(" | "),
						product_image: typeof item?.product_image === "undefined" ? "https://www.ambar.pet/wp-content/uploads/2021/12/COPIA-DE-LOGO.jpeg" : item?.product_image,
						url: typeof item?.url === "undefined" ? "" : item?.url,
						ecommerceId: typeof item?.ecommerceId === "undefined" ? "" : item?.ecommerceId.toString(),
						sku: typeof item?.sku === "undefined" ? "" : item?.sku,
						description_item: typeof item?.description_item === "undefined" ? "" : item?.description_item,
						subcategories: typeof item?.subcategories === "undefined" ? "" : item?.subcategories,
						count: (typeof item?.count=== "undefined" || item?.count===null) ? 0 : item?.count,
						total_sales_before_rocio: typeof item?.total_sales_before_rocio=== "undefined" ? 0 : parseInt(item?.total_sales_before_rocio),
					}
				})
			}
		}		

	    return { err: errValidation, msg:msgValidation, data: bodyProduct }

    }catch(error){
      return {err: true, msg:error}
    }
}

export const getEventByTenant = (data) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant

		let err=null
	
		const body = { 
			tenant_id,
			start_time: data.dateFrom.toString(),
			end_time:  data.dateTo.toString()
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("event/eventsbytenantdate", body, session?.token)
		
	    if(response.data.length>0){
			let listAddToCart = response.data.find(item => item.event_type==="add_to_cart")
			let listSuccesfulPurchase = response.data.find(item => item.event_type==="succesful_purchase")
			let listCrossSelling = listAddToCart?.items?.filter(item => item?.attributes!==null && item?.attributes?.from_page_event===true)
			
			listAddToCart = validateDuplicateProduct(listAddToCart?.items.length > 0 ? listAddToCart?.items : [])
			listSuccesfulPurchase = validateDuplicateProduct(listSuccesfulPurchase?.items.length > 0 ? listSuccesfulPurchase?.items : [])
			listCrossSelling = validateDuplicateProduct(listCrossSelling?.length > 0 ? listCrossSelling : [])
			
			const payload = { 
				listFilteredProducts: { 
					total_cart_generated: {items: listAddToCart, total: 0},
					total_cart_sold:  {items: listSuccesfulPurchase, total: 0},
					total_cross_selling: {items: listCrossSelling, total: 0}
				 }
			}
			 
	        dispatch(requestSuccess(FILTERED_PRODUCT_DASHBOARD, payload))
		}else{
			err = "No hay eventos disponibles"
		}

		return { err, msg: err }
    }catch(error){
      return {err: false, msg:error}
    }
}

export const getProductByDetails = () => (dispatch, getState) => {
	try{
		let dataProduct = {}
		const { listFilteredProducts, list } = getState().product
		
		for(let [key, data] of Object.entries(listFilteredProducts)){

			dataProduct[key] = []
			let productItems = []

			for(let index in data?.items){
				const product = list.find(item => item.id===data?.items[index].product_id)
				if(product){
					productItems.push({...product })
				}
			}
			dataProduct[key] = {
				items: productItems,
				total: formatMonto(productItems.reduce((total, item) => {
					let total_sales = item?.attributes?.total_sales_before_rocio ? item?.attributes?.total_sales_before_rocio : 0
					return total + total_sales
				}, 0))
			}
		}

		const payload = { listFilteredProducts: dataProduct }
		
		dispatch(requestSuccess(FILTERED_PRODUCT_DASHBOARD, payload))

		return { err: null, msg: ""}

    }catch(error){
      return {err: false, msg:error}
    }
}

export const getGroupByCategories = () => (dispatch, getState) => {
	try{
		const { listFilteredProducts, list } = getState().product
		const { lists_categories } = getState().categories

		let categoriesList = {}

		for(let index in lists_categories){
			categoriesList[crearSlug(lists_categories[index])] = { 
				title: lists_categories[index], 
				items: [],
				count: 0
			}
		}

		if(listFilteredProducts["total_cart_sold"]?.items.length>0){
			
			for(let index in listFilteredProducts["total_cart_sold"]?.items){
				const product = listFilteredProducts["total_cart_sold"]?.items[index]
			
				for(let key in product?.attributes?.categories){

					const categories = product?.attributes?.categories[key]
					const slug = crearSlug(categories)
					if(categoriesList.hasOwnProperty(slug)){
						categoriesList[slug]?.items.push(product)
					}
					categoriesList[slug].count = categoriesList[slug]?.items.length
				}
			}
		}
		
		const sortCategories = Object.values(categoriesList).sort((a, b) => {
			if(a.count < b.count) return 1;
			if(a.count > b.count) return -1;
			return 0;
		})

		let categoriesView = generateArrayCategory(sortCategories, list?.length)

		const payload = { categoriesView }

		dispatch(requestSuccess(GROUP_BY_CATEGORY_PRODUCT, payload))

    }catch(error){
      return {err: false, msg:error}
    }
}

export const getEventByTenantGrafic = (data) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		const { list } = getState().product

		let err=null
	
		const body = { 
			tenant_id,
			start_time: data.dateFrom.toString(),
			end_time:  data.dateTo.toString()
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("event/eventsbytenantdate", body, session?.token)
		
	    if(response.data.length>0){
			let listAddToCart = response.data.find(item => item.event_type==="add_to_cart")
			let listSuccesfulPurchase = response.data.find(item => item.event_type==="succesful_purchase")

			listAddToCart = typeof listAddToCart !== "undefined" ? listAddToCart?.items : []
			listSuccesfulPurchase = typeof listSuccesfulPurchase !== "undefined" ? listSuccesfulPurchase?.items : []

			listAddToCart = listAddToCart
										.filter(item => item?.attributes!==null && item?.attributes?.from_page_event===true)
										.map(item => {
												item.fecha = format(new Date(parseInt(item.time_stamp)), 'dd/MM/yyyy')
												item.month = getMonthDate(parseInt(format(new Date(parseInt(item.time_stamp)), 'M')))
												return item
											})

			listSuccesfulPurchase = listSuccesfulPurchase
										.map(item => {
												item.fecha = format(new Date(parseInt(item.time_stamp)), 'dd/MM/yyyy')
												item.month = getMonthDate(parseInt(format(new Date(parseInt(item.time_stamp)), 'M')))
												return item
											})

			let listCrossSelling = validateProductClic(
					listAddToCart?.length > 0 ? listAddToCart : [],
					listSuccesfulPurchase?.length > 0 ? listSuccesfulPurchase : [],
				)

			let productItems = []

			for(let index in listCrossSelling){
				const product = list.find(item => item.id===listCrossSelling[index].product_id)
				if(product){
					productItems.push({...product, month: listCrossSelling[index].month })
				}
			}

			const dataGraficLinealSell = filterProductGroup(groupProductByMonth(
				parseInt(format(new Date(parseInt(data.dateFrom)), 'M')),
				parseInt(format(new Date(parseInt(data.dateTo)), 'M')),
				productItems
			))

			const dataGraficLinealPercentaje = filterProductGroup(groupProductPercentageByMonth(
				parseInt(format(new Date(parseInt(data.dateFrom)), 'M')),
				parseInt(format(new Date(parseInt(data.dateTo)), 'M')),
				productItems,
				listSuccesfulPurchase
			))

			const payload = { dataGraficLinealSell, dataGraficLinealPercentaje }
			
			dispatch(requestSuccess(GRAFIC_DATA_DASHBOARD, payload))

			err= null

		}else{
			err = "No hay eventos disponibles"
		}

		return { err, msg: err }
    }catch(error){
      return {err: false, msg:error}
    }
}

