
const TypeAction = ({ register, value, is_class, setValue, name, changeCategory, id, initial }) => {

	return (
		<div className="input-group-design mb-2 top_select"> 
      		<select 
                className={is_class}
                id={name} 
                name={name}
                //ref={register({required: {value: true, message: 'Requerido'}})}
                ref={register}
                onChange={changeCategory}
                defaultValue={value}
          >
          <option value="">- Sin opciones -</option>
          <option value="add-to-cart">Carrito de compra</option>
          </select>
          <i></i>
		</div>
  );
}

export default TypeAction;