import Header from './Header'
//import '../../assets/css/style.css'
import { ToastContainer } from 'react-toastify'
const Layout = ({
  titleMenu,
  children,
  volver,
  ...props
}) => {
  return (
    <div className="container-fluid bg-demo">
      <Header volver={volver} {...props} />
      {children}
      <ToastContainer />
    </div>
  );
}

export default Layout;
