import { 
	LIST_CATEGORIES
} from "../types/categoriesType"

const INITIAL_STATE = {
	lists_categories: []
}

const categoriesReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_CATEGORIES:
			return {
				...state,
				lists_categories: action.payload
			}
		default: return state
	}
}

export default categoriesReducer