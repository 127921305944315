import { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import { useForm } from 'react-hook-form'
import Layout from '../../Components/Template/Layout'
import { isUrlApi } from "../../utils/validation-product"

import Information from '../../Components/Template/Products/Information'
import { useDispatch, useSelector } from 'react-redux'
import { getValidationFileProductApi, getListProductByAPi } from '../../actions/productAction'
import { updateTenant, searchByTenant } from '../../actions/tenantAction'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'


const CreateProductApi = () => {

    const history = useHistory()
	const dispatch = useDispatch()
	const { register, handleSubmit, errors } = useForm()
	const [ loading, setLoading ] = useState(false)
    const [ visibleKey, setVisibleKey ] = useState(false)
    const [ visiblePage, setVisiblePage ] = useState(false)
    const [ visibleFilter, setVisibleFilter ] = useState(false)
    const { tenant_data } = useSelector((state) => state.tenant)


	const onSubmit =  async (data, e) => {
		e.preventDefault()
        setLoading(true)
 
        let url = ""

        if(data.urlApi!==""){
            url = data.urlApi
        }
        if(data.inputPageInitial!==""){
            url = `${url}?${data.inputPageInitial}`
        }
        if(data.inputPageEnd!==""){
            url = `${url}&${data.inputPageEnd}`
        }
        if(data.inputFilter!==""){
            url = `${url}&${data.inputFilter}`
        }
        let body = data
        body.url = url

        const validation = isUrlApi(body.url)
        
        if(validation.err===false){
            setLoading(false)
            toast.error(validation.msg,{position: toast.POSITION.TOP_RIGHT})
            return false
        }

        let response = await dispatch(getListProductByAPi(body))
      
        if(response.err===null){
            response = await dispatch(getValidationFileProductApi(response.data))

            setLoading(false)
            if(response.err===null){
                if(Object.keys(tenant_data).length===0){
                    await dispatch(searchByTenant())
                }
                const responseTenant = await dispatch(updateTenant({ url_api: body.url}))
                if(responseTenant.err===null){
                    history.push('/configure/product/preview')
                }else{
                    toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
                    return false
                }
            }else{
                toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
                return false
            }
        }else{
            setLoading(false)
            toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
            return false
        }
	}

    return (
        <Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  			titleMenu="Carga de productos" 
		  	  			tab="1" 
		  	  			title="Cargar productos recomendados" 
		  	  			subtitle="Ingresa la api que contiene el arreglo json de los productos que quieres comenzar a recomendar" 
		  	  			isVisible={true}
		  	  			url="/configure/type"
		  	  			isClass="col-xs-12 col-sm-9 col-lg-6 mx-auto padding-top-and-buttom"
		  	>
		  		<Form className="form-api-product" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" autoComplete="false">
			  		<Row>
                        <Col xs={12}>
					    	<Information 
                                title="A continuación el request con los datos obligatorios de la API" 
                                visibleTable={false}
                                visibleCode={true}
                            />
					    </Col>
					    <Col xs={12} className="mb-2">
                            <p className="input-title mt-4 mb-1">URL de la API</p>
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="urlApi" 
                                    id="urlApi"
                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                />
                                {errors.urlApi && (
                                    <Form.Text className="text-danger">
                                        {errors?.urlApi?.message}
                                    </Form.Text>
                                )}
                            </div>
					    </Col>
                        <Col xs={12} className="mb-2">
                            <p className="mb-2 mt-1 font-16">¿La API contiene paginación?</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFPage" 
                                        id="visibleFPageSi" 
                                        value="si"
                                        onChange={()=>setVisiblePage(true)}
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleFPageSi">Si</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFPage" 
                                        id="visibleFPageNo" 
                                        value="no"
                                        onChange={()=>setVisiblePage(false)}
                                        defaultChecked
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleFPageNo">No</label>
                                </div>
                            </div>
                        </Col>
                        {
                            visiblePage && (
                                <Col xs={12} className="mb-3">
                                    <hr className="mt-4"/>
                                    <p className="mb-2 mt-2 font-16">A continuación especifique los nombres de la páginación:</p>
                                    <div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputPageInitial">Nombre inicial</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputPageInitial"
                                                    name="inputPageInitial"
                                                    aria-describedby="inputPageInitial" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputPageInitial && (
                                                <span className="text-danger">
                                                    {errors?.inputPageInitial?.message}
                                                </span>
                                            )} 
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputPageEnd">Nombre final</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputPageEnd"
                                                    name="inputPageEnd"
                                                    aria-describedby="inputPageEnd" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputPageEnd && (
                                                    <span className="text-danger">
                                                        {errors?.inputPageEnd?.message}
                                                    </span>
                                            )} 
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        <Col xs={12} className="mb-2">
                            <p className="mb-2 mt-1 font-16">¿La API contiene filtro?</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFilterPage" 
                                        id="visibleFilterPageSi" 
                                        value="si"
                                        onChange={()=>setVisibleFilter(true)}
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleFilterPageSi">Si</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFilterPage" 
                                        id="visibleFilterPageNo" 
                                        value="no"
                                        onChange={()=>setVisibleFilter(false)}
                                        defaultChecked
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleFilterPageNo">No</label>
                                </div>
                            </div>
                        </Col>
                        {
                            visibleFilter && (
                                <Col xs={12} className="mb-3">
                                    <hr className="mt-4"/>
                                    <p className="mb-2 mt-2 font-16">A continuación el filtro:</p>
                                    <div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputFilter">Filtro</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFilter"
                                                    name="inputFilter"
                                                    aria-describedby="inputFilter" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputFilter && (
                                                <span className="text-danger">
                                                    {errors?.inputFilter?.message}
                                                </span>
                                            )} 
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        <Col xs={12} className="mb-2">
                            <p className="mb-2 mt-1 font-16">¿Las claves de la API son distintas?</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFKey" 
                                        id="visibleSi" 
                                        value="si"
                                        onChange={()=>setVisibleKey(true)}
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleSi">Si</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="visibleFKey" 
                                        id="visibleNo" 
                                        value="no"
                                        onChange={()=>setVisibleKey(false)}
                                        defaultChecked
                                        ref={register}
                                    />
                                    <label className="form-check-label" htmlFor="visibleNo">No</label>
                                </div>
                            </div>
                        </Col>
                        {
                            visibleKey && (
                                <Col xs={12}>
                                    <hr className="mt-4"/>
                                    <p className="mb-2 mt-2 font-16">A continuación especifique cada una:</p>
                                    <div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputSku">sku</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFSku"
                                                    name="inputSku"
                                                    aria-describedby="inputSku" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputSku && (
                                                <span className="text-danger">
                                                    {errors?.inputSku?.message}
                                                </span>
                                            )} 
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputCategories">categories</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFCategories"
                                                    name="inputCategories"
                                                    aria-describedby="inputCategories" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputCategories && (
                                                    <span className="text-danger">
                                                        {errors?.inputCategories?.message}
                                                    </span>
                                            )} 
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputSubCategories">subcategories</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFSubCategories"
                                                    name="inputSubCategories"
                                                    aria-describedby="inputSubCategories" 
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputItemName">item_name</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFItemName"
                                                    name="inputItemName"
                                                    aria-describedby="inputItemName" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputItemName && (
                                                    <span className="text-danger">
                                                        {errors?.inputItemName?.message}
                                                    </span>
                                            )} 
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputProductImage">product_image</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFProductImage"
                                                    name="inputProductImage"
                                                    aria-describedby="inputProductImage" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputProductImage && (
                                                    <span className="text-danger">
                                                        {errors?.inputProductImage?.message}
                                                    </span>
                                            )} 
                                        </div>
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputDescriptionItem">description_item</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFDescriptionItem"
                                                    name="inputDescriptionItem"
                                                    aria-describedby="inputDescriptionItem" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputDescriptionItem && (
                                                    <span className="text-danger">
                                                        {errors?.inputDescriptionItem?.message}
                                                    </span>
                                            )} 
                                        </div>
                                        
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputUrl">url</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFUrl"
                                                    name="inputUrl"
                                                    aria-describedby="inputUrl" 
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                />
                                            </div>
                                            {errors.inputUrl && (
                                                        <span className="text-danger">
                                                            {errors?.inputUrl?.message}
                                                        </span>
                                                )} 
                                        </div>

                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputUrl">cantidad</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFCantidad"
                                                    name="inputFCantidad"
                                                    aria-describedby="inputFCantidad" 
                                                    ref={register}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend input-ground__api">
                                                    <span className="input-group-text" id="inputFventa">Total de ventas</span>
                                                </div>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="inputFventa"
                                                    name="inputFventa"
                                                    aria-describedby="inputFventa" 
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
					    
					    <Col md={12} xs={12} className="mb-5">
					    	<Button variant="primary" className="btn-continuar mt-4 btn-panel-config" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' }
							</Button>
							<p className="text-help"><a href="/help">¡Necesito ayuda!</a></p>
					    </Col>
					</Row>
				</Form>
		  	</MenuStep>
	  	</Layout>
    )
}

export default CreateProductApi