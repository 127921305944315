import { useEffect } from 'react'

const Border = ({ register, setValue, value }) => {

	useEffect(() => {
		if(value!==""){
			setValue('icon_type', value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return (
		<div className="d-flex mb-2 flex-column flex-md-row">
  			<div className="form-check d-flex w-md-25 w-50 flex-column">
			  <div>
				  	<input 
				  		className="form-check-input form-check-border" 
				  		type="radio" 
				  		name="icon_type" 
				  		id="icon_type_0" 
				  		value="straight_edges" 
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				 />
				  <label htmlFor="icon_type_0">Bordes rectos</label>
			  </div>
			  <span className="widget-border color-icono"></span>
			</div>
  			<div className="form-check d-flex w-md-25 w-50 flex-column">
			  <div>
				  	<input 
				  		className="form-check-input form-check-border" 
				  		type="radio" 
				  		name="icon_type" 
				  		id="icon_type_1" 
				  		value="oval_edges" 
				  		defaultChecked 
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				  />
				  <label  htmlFor="icon_type_1">Bordes ovalados </label>
			  </div>
			  <span className="widget-border color-icono widget-radius-5"></span>
			</div>
  		</div>
  );
}

export default Border;