import {  useState } from 'react'
import { Row, Col, Navbar } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo_blanco.png'
import iconSearch from '../../assets/icono/search-menu.svg'
import menuBlack from '../../assets/icono/menu-black.png'
import menuHome from '../../assets/icono/home.svg'
import menuHomeSelected from '../../assets/icono/icon-home-selected.svg'
import menuPerfil from '../../assets/icono/icon-perfil.svg'
import menuPerfilSelected from '../../assets/icono/icon-perfil-selected.svg'
import menuInteraccion from '../../assets/icono/icon-interaccion.svg'
import menuInteraccionSelected from '../../assets/icono/icon-interaccion-selected.svg'
import menuProduct from '../../assets/icono/icon-product.svg'
import menuProductSelected from '../../assets/icono/icon-product-selected.svg'
import menuWidget from '../../assets/icono/icon-widget.svg'
import menuWidgetSelected from '../../assets/icono/icon-widget-selected.svg'

import { ToastContainer } from 'react-toastify'
import menuLogout from '../../assets/icono/icon-logout.svg'
import iconBell from '../../assets/icono/icon-bell.svg'
import iconMessage from '../../assets/icono/icon-message.svg'
import iconUser from '../../assets/icono/user-dashboard.svg'
import { useSelector } from 'react-redux'

import '../../assets/css/menu.css'
import '../../assets/css/dashboard.css'

const Layout = ({title, modulo, children, isVisible}) => {

	const history = useHistory()
	const [ visibleMenu, setVisibleMenu ] = useState(false)
	const { userAdmin, widget  } = useSelector((state) => {
		return {
			widget: state.widget,
			userAdmin: state.userAdmin
		}
	})
	const { visible_menu  } =widget
	
	const logout = (event) => {
		event.preventDefault()
		localStorage.clear()
		history.push('/')
        window.location.reload()
	}

	const hiddenMenu = () => {
		setVisibleMenu(!visibleMenu)		
	}
	
	/**useEffect(() => {
		document.body.classList.remove('bg-config')
		document.body.classList.add('bg-dashboard')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])**/

	return (
		<div className="wrapper">
	   		<nav id="sidebar" className={`menu-left ${visibleMenu ? 'active' : ''}`}>
				<section className="sidebar-menu">
					<div className="menu-top">
						<div className="menu-left--image">
							<Link  to="/dashboard">
								<img src={logo} alt="Logo" className="logo--menu" />
							</Link>
						</div>
						<div className="menu-main">
							<p className="menu-main__title">Buscador</p>

							<div className="search-container">
								<input
									type="text"
									className="menu-input"
									name="search-menu-input"
									id="search-menu-input"
									placeholder="Buscador"
								/>
								<img src={iconSearch} alt="Buscar" className="icon-search" />
							</div>
						</div>

						<div className="menu-main">
							<p className="menu-main__title menu-main__title--top">Menú</p>
						</div>

						<ul className="list-unstyled components">
							<li className={`${modulo==='dashboard' ? 'active' : ''} `}>
								<Link to="/dashboard" className="nav-item-menu nav-item--top">
									<img src={modulo==='dashboard' ? menuHomeSelected : menuHome} alt="Dashboard" className="img-item-menu" />
									<span className="nav-text">Dashboard</span>
								</Link>
							</li>
							{
								(visible_menu) && (
									<li className={`${modulo==='widget' ? 'active' : ''} `}>
										<Link to="/dashboard/widget" className="nav-item-menu">
											<img src={modulo==='widget' ? menuWidgetSelected : menuWidget} alt="Leads" className="img-item-menu" />
											<span className="nav-text">Configurar Widget</span>
										</Link>
									</li>
								)
							}
							<li className={`${modulo==='mis-wigets' ? 'active' : ''} `}>
								<Link to="/dashboard/widget" className="nav-item-menu">
									<img src={modulo==='mis-wigets' ? menuWidgetSelected : menuWidget} alt="Leads" className="img-item-menu" />
									<span className="nav-text">Mis widget</span>
								</Link>
							</li>
							<li className={`${modulo==='product' ? 'active' : ''} `}>
								<Link to="/dashboard/product" className="nav-item-menu">
									<img src={modulo==='product' ? menuProductSelected : menuProduct} alt="Creador Tiendas" className="img-item-menu" />
									<span className="nav-text">Cargar productos</span>
								</Link>
							</li>
							<li className={`${modulo==='interacciones' ? 'active' : ''} `}>
								<Link to="/dashboard/interaccions" className="nav-item-menu">
									<img src={modulo==='interacciones' ? menuInteraccionSelected : menuInteraccion} alt="Mapa" className="img-item-menu" />
									<span className="nav-text">Interacciones</span>
								</Link>
							</li>
							<li className={`${modulo==='user' ? 'active' : ''} `}>
								<Link to="/dashboard/user" className="nav-item-menu">
									<img src={modulo==='user' ? menuPerfilSelected : menuPerfil} alt="Blog" className="img-item-menu" />
									<span className="nav-text">Perfil</span>
								</Link>
							</li>
						</ul>
					</div>
					

					<ul className="list-unstyled components">
						<li>
							<Link to="!#" className="nav-item-menu menu-logout" onClick={(event)=>logout(event)}>
								<img src={menuLogout} alt="Dashboard" className="img-item-menu" />
								<span className="nav-text">Cerrar Sesión</span>
							</Link>
						</li>
					</ul>
				</section>
	        </nav>

	        <div id="content" className={`container-fluid`}>
	        	<Row>
   					<Col xs={12} className="p-0 menu-right--title-panel">
					   <Navbar variant="dark" className="pt-5 pb-3 text-rigth navbar--white menu-bottom">
								<img src={menuBlack} 
									alt="Ocultar/Mostrar menú" 
									id="sidebarCollapse" 
									className={`${visibleMenu ? 'rotate' : ''}`} onClick={()=>hiddenMenu()}
								/>
							<div className="icon-mail">
								<div className="text-left title-main">
									<h1 className="menu-title">{title}</h1>
									<p className="menu-subtitle">Bienvenido, {userAdmin?.userAdmin?.attributes?.name} {userAdmin?.userAdmin?.attributes?.lastname}</p>
								</div>
							</div>
							<div className="iconos-group d-flex">
								<div className="icon-dashboard">
									<img src={iconMessage} alt="Mensaje" />
								</div>
								<div className="icon-dashboard">
									<img src={iconBell} alt="Campana" />
								</div>
								<div className="icon-dashboard">
									<img src={iconUser} alt="Usuario" />
								</div>
							</div>
						</Navbar>
				    </Col>
   				</Row>
   				{children}
	        </div>
	        <ToastContainer />
	   </div>
	);
}

export default Layout;