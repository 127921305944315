import React from 'react'
import Switch from "react-switch"
import '../../assets/css/SwitchToogle.css'

const SwitchToogle = ({ handleActivarDes, checked, color, titleOn, titleOff, nameSwitch }) => {


	const titlecheckedHandleIcon = (title) => {
		return (
			 <div
		        style={{
		          display: "flex",
		          justifyContent: "center",
		          alignItems: "center",
		          height: "100%",
		          fontSize: 14,
		          top: -5,
		          left: 10,
		          position: "absolute",
		          color: "#fff"
		        }}
		      >
		        {title}
		      </div>
		)
	}

	return (
		<>
		<label htmlFor={nameSwitch} className="small_radius-switch">
		  <Switch
		    checked={checked}
		    onChange={(event) => handleActivarDes(event)}
		    handleDiameter={35}
		    offColor="#c8c8c8"
		    onColor="#c8c8c8"
		    offHandleColor="#FF1616"
		    onHandleColor="#16ce87"
		    height={25}
		    width={50}
		    borderRadius={20}
		    uncheckedIcon={false}
            checkedIcon={false}
		    activeBoxShadow="0px 0px 1px 2px transparent"
		    uncheckedHandleIcon={ titlecheckedHandleIcon(titleOff)}
		    checkedHandleIcon={titlecheckedHandleIcon(titleOn)}
		    className="react-switch"
		    id={nameSwitch}
		  />
		</label>
		</>
	)
} 

export default SwitchToogle