import { Row, Col, Container  } from 'react-bootstrap'
import Footer from '../../Components/Landing/Footer'
import Layout from '../../Components/Template/Guest/Layout'

const About = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<Layout url="about">
					<div className="container-about">
						<Container>
							<Row>
								<Col md={7}>
									<h1 className="about--title mt-5">Rocio</h1>
									<div className="mt-5">
										<p className="about--subtitle">Debido a la pandemia, el comercio electrónico se posicionó como la modalidad de compra más usada en el mundo, este crecimiento ha impulsado una fuerte competencia en el comercio electrónico, dejando de manifiesto la importancia de contar con un sistema de recomendación de compras online para lograr una diferenciación.</p>
										<p className="about--subtitle">El mercado no puede cubrir esta necesidad de contar con un óptimo sistema de recomendación, ya que no existe una solución de calidad que, de forma eficiente y costo accesible, satisfaga las necesidades tanto de grandes empresas como las más pequeñas.</p>
										<p className="about--subtitle">Estas soluciones del mercado requieren en mayor o medida RRHH técnico altamente especializado para su implementación y operación, esto es un problema considerando que los usuarios con las áreas de negocio y no las técnicas.</p>
										<p className="about--subtitle">Es por ello que nació ROCIO, un recomendador de compras online basado en Inteligencia Artificial con un modelo de negocios que permite el acceso a todo tipo de empresa. Cuenta con un potente motor de recomendaciones elaborado en base a una serie de algoritmos matemáticos que tienen la capacidad de entrenarse solos y que se van adaptando de forma inteligente y automática a las necesidades de cada cliente.</p>
										<p className="about--subtitle">Es una solución muy simple de configurar e implementar ya que se inserta un código en la página web y queda operativo. Esto permite tener un Go to Market prácticamente instantáneo y que no necesita mantención de ellos. No necesita expertos ni científicos de datos para manejar los resultados, ya que está diseñado para que lo usen las áreas de negocio y no las áreas técnicas.</p>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</Layout>
				<Footer />
			</div>
		</div>
	)
}

export default About