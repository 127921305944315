import {
    Row,
    Col
} from 'react-bootstrap'
import configurar_widget from '../../assets/images/configurar/configurar_widget.png'
import configurar_script from '../../assets/images/configurar/configurar_script.png'
import configurar_producto from '../../assets/images/configurar/configurar_producto.png'
import xls from '../../assets/images/configurar/xls.svg'
import csv from '../../assets/images/configurar/csv.svg'
import eye_rocio from '../../assets/images/configurar/eye_rocio.png'
import { Link } from 'react-router-dom'

const Configurar = () => {
    return (
        <Row className="configurar_widget configurar_widget--top pb-5 w-sm-100">
            <Col xs={12} className="p-5 text-center d-flex flex-column align-items-center justify-content-center">
                <h1>¿Cómo configurar ROCIO en tu sitio?</h1>
                <p>Entra a su panel de configuración y sigue estos tres simples pasos:</p>
            </Col>
            <Col xs={12}>
                   <section className="section_step_1">
                        <div className="configurar ml-md-5">
                            <div className="configurar-radius radius-1 ml-md-5"></div>
                            <div className="circle-global ml-md-5"></div>
                            <div className=" js-scroll slide-left card-configurar card-img-configurar">
                                <img src={configurar_widget} alt="Configurar Widget" />
                                <div className="card-configurar card-icono">
                                    <div className="background-icon"><img src={eye_rocio} alt="Rocio" className="img-icon" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="details-card ml-md-5 mb-5 mb-md-0">
                            <p className="step-widget">Paso 1</p>
                            <p className="title-widget">Customiza tu widget</p>
                            <p className="subtitle-widget">Rocio es 100% customizable, se adapta al estilo de su sitio con 3 simples pasos podrá configura el widget.</p>
                            <ul className="list-unstyled">
                                <li className="mb-2 list-forma"><span></span> Forma</li>
                                <li className="mb-2 list-color"><span></span> Color de fondo</li>
                                <li className="mb-2 list-position"><span></span> Posición</li>
                            </ul>
                            <Link className="btn btn-primary mt-5 btn--guest-primary" to='/demo'>
                                Solicita tu demo
                            </Link>
                        </div>
                   </section>
            </Col>
            <Col xs={12}>
                 <section className="section_step_1 mt-5 section-flex--center">
                    <div className="details-card ml-md-5 mb-5 mb-md-0">
                        <p className="step-widget">Paso 2</p>
                        <p className="title-widget">Sube tus datos</p>
                        <p className="subtitle-widget">Alimenta el algoritmo de ROCIO cargando los datos de tus productos en el formato que se indica.</p>
                         <Link className="btn btn-primary mt-5 btn--guest-primary" to='/demo'>
                            Solicita tu demo
                          </Link>
                    </div>
                     <div className="configurar-product config-paso-2">
                        <div className="configurar-radius radius-1 paso-2"></div>
                        <div className=" js-scroll slide-right card-configurar card-img-configurar card-product card-product---height">
                            <img src={configurar_producto} alt="Configurar Widget" />
                            <div className="card-configurar card-icono-product">
                                <img src={xls} alt="XLS" />
                            </div>
                            <div className="card-configurar card-icono-product-otro">
                                <img src={csv} alt="CSV" />
                            </div>
                            <div className="container-linea">
                               <div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                               </div>
                                <div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                               </div>
                               <div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                                    <div className="product-linea mb-1 mr-1"></div>
                               </div>
                            </div>
                        </div>

                        <div className="configurar-radius radius-1 paso-1"></div>
                    </div>
               </section>
            </Col>
             <Col xs={12}>
                   <section className="section_step_1 mt-5">
                        <div className="configurar ml-md-5">
                            <div className="circle-global-step-2 ml-md-5"></div>
                            <div className=" js-scroll slide-left card-configurar card-img-configurar card-script card-script--left">
                                 <img src={configurar_script} alt="Configurar Script" />
                            </div>
                            <div className="configurar-radius radius-1 radius-script"></div>
                        </div>
                        <div className="details-card ml-md-5 mb-5 mb-md-0">
                            <p className="step-widget">Paso 3</p>
                            <p className="title-widget">Genera tu script</p>
                            <p className="subtitle-widget">Copia y pega el script en tu sitio web y comienza a recomendar.</p>
                            <ul className="list-unstyled">
                                <li className="mb-2 list-script"><span></span> Implementación en menos de 1 hora</li>
                            </ul>
                            <Link className="btn btn-primary mt-5 btn--guest-primary" to='/demo'>
                                Solicita tu demo
                            </Link>
                        </div>
                   </section>
            </Col>
        </Row>
    );
}

export default Configurar;
