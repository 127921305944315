import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

import rocio_black from '../../assets/images/logo.png'
import logo_wingsoft from '../../assets/images/logo/wingsoft.png'
import logo_morris from '../../assets/images/logo/morris.png'

const Footer = () => {
    return (
        <footer>
            <Row className="bg-grey-footer w-sm-100 m-0">
                <Col>
                    <Container>
                        <Row>
                            <Col className="p-5">
                                <p className="text-creacion text-center mb-5">CO-CREACIÓN</p>
                                <div className="logo d-flex flex-md-row flex-column align-items-center justify-content-md-around">
                                    <div><img src={logo_wingsoft} alt="Wingsoft" className="mb-5" /></div>
                                    <div><img src={logo_morris} alt="Morris" className="mb-5" /></div>                                    
                                </div>
                            </Col>
                        </Row>
                        <Row className="line">
                            <Col md={12} className="p-5 text-center text-md-left">
                                <img src={rocio_black} alt="Logo" width={200} height={50} />
                                <p className="mt-4 font-16">© 2021 ROCIO. Todos los derechos reservados.</p>
                            </Col>
                            {/*<Col md={6} className="d-flex justify-content-between ml-1 ml-md-5">
                                 <div>
                                    <p className="title-list mb-4">LOREM IPSUM</p>
                                    <ul className="list-unstyled">
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                    </ul>
                                </div>
                                 <div>
                                    <p className="title-list mb-4">LOREM IPSUM</p>
                                    <ul className="list-unstyled">
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                    </ul>
                                </div>
                                 <div>
                                    <p className="title-list mb-4">LOREM IPSUM</p>
                                    <ul className="list-unstyled">
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                        <li>Lorem Ipsum</li>
                                    </ul>
                                </div> 
                            </Col>*/}
                        </Row>
                    </Container>
                </Col>
            </Row>
            <div className="bg-blue-footer">
            </div>
        </footer>
    );
}

export default Footer;