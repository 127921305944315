import { useEffect } from 'react'

const TypeIcon = ({ register, setValue, value }) => {

	useEffect(() => {
		if(value!==""){
			setValue('icon_type', value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return (
		<div className="d-flex mb-0 flex-column flex-md-row">
  			<div className="form-check d-flex align-items-center w-md-25 w-50 mb-0">
			  <input 
			  		className="form-check-input form-check-widget" 
			  		type="radio" 
			  		name="icon_type" 
			  		id="icon_type_0" 
			  		value="circle" 
			  		ref={register({required: {value: true, message: 'Requerido'}})}
			 />
			  <label className="form-icono-circulo color-icono" htmlFor="icon_type_0"><span></span></label>
			</div>
  			<div className="form-check d-flex align-items-center w-md-25 w-50 mb-0">
			  <input 
			  		className="form-check-input form-check-widget" 
			  		type="radio" 
			  		name="icon_type" 
			  		id="icon_type_1" 
			  		value="square" 
			  		defaultChecked 
			  		ref={register({required: {value: true, message: 'Requerido'}})}
			  />
			  <label className="form-icono-cuadro color-icono" htmlFor="icon_type_1"> <span></span></label>
			</div>
			<div className="form-check d-flex align-items-center w-md-25 w-50 mb-0">
			  <input 
			  		className="form-check-input form-check-widget" 
			  		type="radio" 
			  		name="icon_type" 
			  		id="icon_type_2" 
			  		value="oval" 
			  		ref={register({required: {value: true, message: 'Requerido'}})} 
			  	/>
			  <label className="form-icono-redondo color-icono" htmlFor="icon_type_2"><span></span></label>
			</div>
  		</div>
  );
}

export default TypeIcon;