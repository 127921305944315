
import { Form } from 'react-bootstrap'


const Input = ({ register, errors, value, is_class, is_readOnly, name }) => {

	return (
		<div className="input-group-design mb-2">
      		<input 
      			type="text" 
      			name={name} 
      			id={name}
      			className={is_class} 
                        defaultValue={value}
                        readOnly={is_readOnly}
      			ref={register({required: {value: true, message: 'Requerido'}})} 
      		/>
      		{errors.url_site_web && (
		    	<Form.Text className="text-danger">
		      		{errors?.url_site_web?.message}
		    	</Form.Text>
		    )}  
		</div>
  );
}

export default Input;