import { useEffect } from 'react'

const StyleButton = ({ register, data, setValue }) => {

    const changeColor = (event) => {
		setColorTypeIcon(event.target.value)
	}

    const setColorTypeIcon = (colors) => {
		Array.from(document.getElementsByClassName("check-btn-w")).forEach(function(item) {
		   item.style.backgroundColor=colors
		})
        Array.from(document.getElementsByClassName("check-outbtn-w")).forEach(function(item) {
		   item.style.borderTopColor=colors
           item.style.borderRightColor=colors
           item.style.borderBottomColor=colors
           item.style.borderLeftColor=colors
		})
	}

    useEffect(() => {
		if(data?.color_button!==""){
			setColorTypeIcon(data?.color_button)
            setValue("style_button", data?.style_button)
            setValue("type_button", data?.type_button)
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.color_button])

    return (
        <div>
            <div className="form-group">
                <label className="mr-3 font-label">Estilo:</label>
                <div className="form-check form-check-inline mb-3">
                    <input 
				  		className="form-check-input" 
				  		type="radio" 
				  		name="style_button" 
				  		id="style_button_oval" 
				  		value="w-btn-oval"  
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				    />
                    <button type="button" className="btn btn-secondary font-btn-w radius-btn-w mr-3 check-btn-w">Agregar</button>
                </div>
                <div className="form-check form-check-inline mb-3">
                    <input 
				  		className="form-check-input" 
				  		type="radio" 
				  		name="style_button" 
				  		id="style_button_fixed" 
				  		value="w-btn-fixed" 
				  		defaultChecked 
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				    />
                    <button type="button" className="btn btn-secondary font-btn-w check-btn-w">Agregar</button>
                </div>
            </div>
             <div className="form-group">
                <label className="font-label-right">Tipo:</label>
                <div className="form-check form-check-inline mb-3">
                    <input 
				  		className="form-check-input" 
				  		type="radio" 
				  		name="type_button" 
				  		id="type_button_normal" 
				  		value="w-btn-normal" 
				  		defaultChecked 
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				    />
                    <button type="button" className="btn btn-secondary font-btn-w check-btn-w mr-3">Agregar</button>
                </div>
                <div className="form-check form-check-inline mb-3">
                    <input 
				  		className="form-check-input" 
				  		type="radio" 
				  		name="type_button" 
				  		id="type_button_outline" 
				  		value="w-btn-outline"  
				  		ref={register({required: {value: true, message: 'Requerido'}})}
				    />
                    <button type="button" className="btn btn-outline-secondar font-btn-w check-outbtn-w">Agregar</button>
                </div>
            </div>
            <div className="form-group d-flex">
                <label className="mr-4 font-label">Color:</label>
                <input 
	      			type="color" 
	      			id="color_button" 
	      			name="color_button" 
	      			className="form-control form-control-color" 
	      			title="Choose your color"
	      			defaultValue={data?.color_button}
	      			ref={register({required: {value: true, message: 'Requerido'}})}
	      			onChange={(event)=>changeColor(event)}
	      		/>
            </div>
        </div>
    )
}

export default StyleButton