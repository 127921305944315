import { Row } from 'react-bootstrap'
import Cards from '../../Template/Widget/Cards'
import Input from '../../Template/Widget/Input'
import TypeIcon from '../../Template/Widget/TypeIcon'
import PanelPosition from '../../Template/Widget/PanelPosition'
import BackgroundColor from '../../Template/Widget/BackgroundColor'
import Categories from '../../Template/Widget/Categories'

const Floating = ({config_widget, register, setValue, errors }) => {


	return (	    
    	<Row>
    		<Cards title="Ingrese el nombre" is_class="mb-4 col-md-6 col-12">
  				<Input 
  					register={register} 
  					errors={errors} 
  					value={config_widget?.configuration?.name}
  					is_class={`input height_input pt-1 pb-1 pr-2 pl-3 ${config_widget?.configuration?.name!=="" ? 'input--readonly' : ''}`}
            //is_class={`input height_input pt-1 pb-1 pr-2 pl-3`}
  					is_readOnly={config_widget?.configuration?.name!==""} 
            //is_readOnly={false} 
  					name="name"
  				/>
  			</Cards>

    		<Cards title="Ingrese la URL del sitio web" is_class="mb-4 col-md-6 col-12">
  				<Input 
  					register={register} 
  					errors={errors} 
  					value={config_widget?.configuration?.url_site_web}
  					//s_class={`input height_input pt-1 pb-1 pr-2 pl-3 ${config_widget?.configuration?.url_site_web!=="" ? 'input--readonly' : ''}`}
            is_class={`input height_input pt-1 pb-1 pr-2 pl-3`}
  					//is_readOnly={config_widget?.configuration?.url_site_web!==""} 
            is_readOnly={false} 
  					name="url_site_web"
  				/>
  			</Cards>

  			<Cards title="Seleccione la categoría" is_class="mb-4 col-md-6 col-12">
  				<Categories
  					register={register} 
  					//errors={errors} 
  					value={config_widget?.configuration?.categories} 
  					is_class="input height_input pt-1 pb-1 pr-2 pl-3" 
  					setValue={setValue}
            name="category"
            initial={true}
  				/>
  			</Cards>

		    <Cards title="Seleciona tipo de icono" is_class="mb-4 col-md-6 col-12">
		      	<TypeIcon 
		      		register={register} 
		      		setValue={setValue} 
		      		value={config_widget?.configuration?.icon_type}
		      	/>
		    </Cards>

		    <Cards title="Seleciona color de fondo" is_class="mb-4 col-md-6 col-12">
		      	<BackgroundColor 
		      		register={register} 
		      		errors={errors} 
		      		color={config_widget?.configuration?.background_color}
		      		setValue={setValue}
		      		is_class="color-icono"
		      		name="background_color"
		      	/>
		    </Cards>

		    <Cards title="Seleciona la posición" is_class="mb-4 col-md-6 col-12">
		      	<PanelPosition 
		      		register={register} 
		      		value={config_widget?.configuration?.widget_position} 
		      		setValue={setValue}
		      	/>
		    </Cards>
		</Row>
	);
}

export default Floating;