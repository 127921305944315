

export const dateFormat = (dateCurrent) => {
    const valueDate = dateCurrent.split("/")

    let dayCurrent = (valueDate[0]<10) ? `0${valueDate[0]}` : valueDate[0]
    let monthCurrent = (valueDate[1]<10) ? `0${valueDate[1]}` : valueDate[1]
    
    let dateEnd = `${valueDate[2]}-${monthCurrent}-${dayCurrent}`

    let timeDate = new Date(dateEnd)
	
    return timeDate
}

export const getMonthDate = (intMonth) => {
	const month = ["Enero","Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

	return month[intMonth-1]
}

export const groupProductByMonth = (startMonth, endMonth, products) => {
	let dataByGroup = []
	
	for(let i=startMonth; i<=endMonth; i++){
		let month = getMonthDate(i)

		const listProduct = products.filter(item => item.month===month)

		dataByGroup.push({ month, count: listProduct.length })
	}

	return dataByGroup
}


export const groupProductPercentageByMonth = (startMonth, endMonth, productsFilter, productsTotal) => {
	let dataByGroup = []
	
	for(let i=startMonth; i<=endMonth; i++){
		let month = getMonthDate(i)

		const listProductInitial = productsTotal.filter(item => item.month===month)
		const listProduct = productsFilter.filter(item => item.month===month)

		const percentage = (parseInt(listProductInitial.length) * parseInt(listProduct.length))/100

		dataByGroup.push({ month, count: percentage })
	}

	return dataByGroup
}


export const filterProductGroup = (productsGroup) => {
	const dataGraficLineal = {
		labels: productsGroup.map(item => item.month),
		data: productsGroup.map(item => item.count)
	}

	return dataGraficLineal
}
