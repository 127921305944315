import { useEffect, useState } from 'react'
import {  Container } from 'react-bootstrap'
import Layout from '../../Components/Template/Guest/Layout'
import Deal from '../../Components/Landing/Deal'
import ImproveConversation from '../../Components/Landing/ImproveConversation'
import Footer from '../../Components/Landing/Footer'
import Contactanos from '../../Components/Landing/Contactanos'
import Configurar from '../../Components/Landing/Configurar'
import Funciona from '../../Components/Landing/Funciona'
import ModalInitial from '../../Components/Landing/ModalInitial'
import { Link } from 'react-router-dom'

const Home = () => {

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const scrollElements = document.querySelectorAll(".js-scroll");
    
        const elementInView = (el, dividend = 1) => {
          const elementTop = el.getBoundingClientRect().top
          return (
            elementTop <=
            (window.innerHeight || document.documentElement.clientHeight) / dividend
          );
        };
    
        const elementOutofView = (el) => {
          const elementTop = el.getBoundingClientRect().top;
    
          return (
            elementTop > (window.innerHeight || document.documentElement.clientHeight)
          );
        };
    
        const displayScrollElement = (element) => {
          element.classList.add("scrolled");
        };
    
        const hideScrollElement = (element) => {
          element.classList.remove("scrolled");
        };
        
    
        const handleScrollAnimation = () => {
          scrollElements.forEach((el) => {
            if (elementInView(el, 1.25)) {
              displayScrollElement(el);
            } else if (elementOutofView(el)) {
              hideScrollElement(el)
    
            }
          })
        }
    
        window.addEventListener("scroll", () => { 
          handleScrollAnimation();
        });
       
        // eslint-disable-next-line react-hooks/exhaustive-deps		
	}, [])

    return (
      <div className="container-fluid">
        <div className="background--color-guest row">
          <div className="col-12 p-0">
            <Layout url="home">
                <Container>
                    <div className="main-rocio d-flex justify-content-between">
                        <div className="mt-5 mb-5 mr-md-5 mr-0">
                            <p className="text--title">Aumenta tus ventas</p>
                            <p className="text--title-active">personalizando</p>
                            <p className="text--title mb-3">las experiencias de tus usuarios</p>
                            <p className="text-white text-main-rocio">De manera simple y sencilla instalando solo un script en tu sitio</p>
                            <Link className="btn btn-primary btn--guest-primary" to='/demo'>
                                Solicita tu demo
                            </Link>
                        </div>
                    </div>
                </Container>
            </Layout>
            <Deal />
            <ImproveConversation />
            <Funciona />
            <Configurar />
            <Contactanos />
            <Footer />
          </div>
          {show && <ModalInitial show={show} handleClose={handleClose} />}
        </div>
      </div>
    );
}

export default Home;
