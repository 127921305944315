import { Alert } from 'react-bootstrap'
import iconAlert from '../../../assets/images/alert.svg'

const Information = ({ title, visibleTable=true, visibleCode=false }) => {


	return (
	  	<>
	  		<Alert  variant="warning" className="d-flex flex-column alert-information">
			   <div className="mr-3 mb-3 d-flex flex-row">
			   		<img src={iconAlert} alt="Icono Alerta" className="mr-2" />
			   		<p className="mb-0 mt-1 text-alert-info">{title}</p>
			   </div>
			   {
			   	visibleTable && (
			   		<>
			   		<div className="cuadro-item flex-wrap">
			   			<p className="item_column flex-fill border--left">sku</p>
			   			<p className="item_column flex-fill">categories</p>
						   <p className="item_column flex-fill">subcategories</p>
			   			<p className="item_column flex-fill">item_name</p>
			   			<p className="item_column flex-fill">product_image</p>
			   			<p className="item_column flex-fill">description_item</p>
			   			<p className="item_column flex-fill border--right">url</p>
			   		</div>
			   		<div className="cuadro-item flex-wrap mt-1">
			   			<p className="item_column border--left">count</p>
			   			<p className="item_column">total_sales_before_rocio</p>
			   		</div>
			   		</>
			   	)
			   }
			</Alert>
			{
				visibleCode && (
					<div className="alert-code">
					   <pre className="alert-code__pre">
					   		{JSON.stringify({
						      	"sku": "781718787062",
						      	"categories": "Alimentos | Bajo en Calorías",
						      	"subcategories": "Alimentos: [Opción 1, Opción 2]",
						      	"item_name": "GRANOLA KETO",
						      	"product_image": "https://url-product-imagen.com/",
						        "description_item": "lorem iuspm",
						       	"count": 100,
						       	"total_sales_before_rocio": 120000,
						       	"url": "https://url-product.com/"

							},undefined, 2)}
					   </pre>
				   </div>
				)
			}
			
			<hr className="mt-4"/>
	  	</>
  );
}

export default Information;