import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);


export const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [12, 40],
        backgroundColor: [
          '#DFE2FF',
          '#363993'
        ],
        borderColor: [
          '#DFE2FF',
          '#363993'
        ],
        borderWidth: 2,
        cutout: 27,
        hoverOffset: 4,
      },
    ],
    
  };

const ItemResultCategory = ({ handleGetName, categoriesGroup }) => {

    

    return (
        <div className="col-12 mb-5">
            <div className="dashboard-container">
                <div className="dashboard-header">
                    <p className="dashboard-header__title">Resultados por categorías</p>
                </div>
                <div className="dashboard-body dashboard-doughnut__body">
                    {
                        categoriesGroup.length>0 && (
                            <div className="dashboard-doughnut__item" onClick={()=>handleGetName(categoriesGroup[0])}>
                                
                                <p className="dashboard-doughnut__title">{categoriesGroup[0]?.title}</p>
                                <div className="dashboard-doughnut__grafic">
                                    <Doughnut data={{
                                                datasets: [
                                                {
                                                    label: '# of Votes',
                                                    data: categoriesGroup[0]?.data,
                                                    backgroundColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderWidth: 2,
                                                    cutout: 27,
                                                    hoverOffset: 4,
                                                },
                                                ],
                                                
                                            }}
                                     />
                                </div>
                                <p className="dashboard-doughnut__count">{categoriesGroup[0]?.count} Vendidos</p>
                            </div>
                        )
                    }

                    {
                        categoriesGroup.length>1 && (
                            <div className="dashboard-doughnut__item" onClick={()=>handleGetName(categoriesGroup[1])}>
                                <p className="dashboard-doughnut__title">{categoriesGroup[1]?.title}</p>
                                <div className="dashboard-doughnut__cat">
                                    <Doughnut data={{
                                                datasets: [
                                                {
                                                    label: '# of Votes',
                                                    data: categoriesGroup[1]?.data,
                                                    backgroundColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderWidth: 2,
                                                    cutout: 27,
                                                    hoverOffset: 4,
                                                },
                                                ],
                                                
                                            }} 
                                    />
                                </div>
                                <p className="dashboard-doughnut__count">{categoriesGroup[1]?.count} Vendidos</p>
                            </div>
                        )
                    }

                    {
                        categoriesGroup.length>2 && (
                            <div className="dashboard-doughnut__item" onClick={()=>handleGetName(categoriesGroup[2])}>
                                <p className="dashboard-doughnut__title">{categoriesGroup[2]?.title}</p>
                                <div className="dashboard-doughnut__other">
                                    <Doughnut data={{
                                                datasets: [
                                                {
                                                    label: '# of Votes',
                                                    data: categoriesGroup[2]?.data,
                                                    backgroundColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderColor: [
                                                    '#DFE2FF',
                                                    '#363993'
                                                    ],
                                                    borderWidth: 2,
                                                    cutout: 27,
                                                    hoverOffset: 4,
                                                },
                                                ],
                                                
                                            }}
                                    />
                                </div>
                                <p className="dashboard-doughnut__count">{categoriesGroup[2]?.count} Vendidos</p>
                            </div>
                        )
                    }                    
                </div>
            </div>
        </div>
    )
}

export default ItemResultCategory