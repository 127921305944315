import { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
//import { Link } from 'react-router-dom'
import Panel from '../../Components/Template/Panel'
import Layout from '../../Components/Template/Layout'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { login } from '../../actions/userAdminAction'
import { searchTenantAssignUser, assignTenant } from '../../actions/tenantAction'
import md5 from 'md5'

const Login = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const [ type, setType ] = useState('password')
	const { register, handleSubmit, errors } = useForm()
	const [ loading, setLoading ] = useState(false)
	const { list_tenants_user, loading_tenant } = useSelector((state) => state.tenant)

	const showPassword = () => {
		setType(type==="password" ? "text" : "password")
	}

	const onSubmit =  async (data, e) => {
		e.preventDefault()

		setLoading(true)
		
		const body = { ...data,	email: data.email.trim(), password: md5(data.password) }
		//const body = { ...data,	password: data.password }

		let response = await dispatch(login(body))
		setLoading(false)
		
		if(response.err===null){
			response = dispatch(assignTenant(data.tenant_id))
			if(response.err===null){
				history.push('/dashboard')	
			}
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleSearchUsername = async (event) => {
		const { value } = event.target
		if(value!==""){
			const response = await dispatch(searchTenantAssignUser(value))
			if(response.err!==null){
				toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}
		}		
	}
	

	return (
		<Layout volver={true} isLogoWhile={true}>
			<Panel 
				url="/"
				title="Bienvenido"
				subtitle="Ingresa tus datos y comienza a configurar Rocio"
				isVisible={true}
				isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto padding-top-and-buttom"
				isActiveLink={true}
			>
				<Form  onSubmit={handleSubmit(onSubmit)}>					
					<Row className="mt-5">
						<Col md={12} xs={12} className="mb-4">
							<div className="input-group-design">
								<input 
									type="text" 
									name="email" 
									id="email" 
									className="input" 
									ref={register({required: {value: true, message: 'Requerido'}})}
									onBlur={(event)=>handleSearchUsername(event)}
								/>
								<label className="label" htmlFor="email">Correo</label>
								{errors.email && (
									<Form.Text className="text-danger">
										{errors?.email?.message}
									</Form.Text>
								)}  
							</div>	
						</Col>
						<Col md={12} xs={12} className="mb-4">
							<div className="input-group-design">
								<select 
									className="input" 
									id="tenant_id" 
									name="tenant_id"
									ref={register({required: {value: true, message: 'Requerido'}})}
								>
								<option value="">{loading_tenant ? 'Cargando...' : '- Seleccione -'}</option>
								{
										list_tenants_user.length > 0 && (
											list_tenants_user.map(tenant => <option key={tenant.tenant_id} value={tenant.tenant_id}>{tenant.tenant_name}</option>)
										)
									}
								</select>
								<i></i>
								<label className="label" htmlFor="password">Organización</label>
								{errors.tenant_id && (
									<Form.Text className="text-danger">
										{errors?.tenant_id?.message}
									</Form.Text>
								)}  
							</div>
						</Col>
						<Col md={12} xs={12} className="mb-1">
							<div className="input-group-design">
								<input 
									type={type} 
									name="password" 
									id="password" 
									className="input" 
									ref={register({required: {value: true, message: 'Requerido'}})}
								/>
								<label className="label" htmlFor="password">Contraseña</label>
								<span onClick={()=>showPassword()}></span>
								{errors.password && (
									<Form.Text className="text-danger">
										{errors?.password?.message}
									</Form.Text>
								)}  
							</div>
						</Col>
						{/**<Col xs={12} className="mb-4 text-right">
						 <Link to="/login" className="link-password text-decoration-none">¿Olvidaste tu contraseña?</Link>
						</Col>**/}

						<Col md={12} xs={12} className="card-config mt-5">
							<Button variant="primary" className="btn-continuar btn-panel-config" type="submit" block disabled={loading}>
								{loading ? 'Buscando...' : 'Inicia sesión'} <span></span>
							</Button>
							
						</Col>
					</Row>
				</Form>
			</Panel>
	    </Layout>
	);
}

export default Login;