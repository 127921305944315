import axios from "axios"

const baseURL = process.env.REACT_APP_API_URL 

const axiosInstance = axios.create({
  validateStatus() {
    return true
  },
  withCredentials: false,
})

const generateHeaders =  (token) => {
  return {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
}

const generateHeadersApi =  () => {
  return {
    headers: {
      'content-type': 'application/json',
      'x-api-key': '7Ir28AHWbu0bobR8gjeD66iYYmjJqEZ5Wv0TXHVf'
    }
  }
}



const API = {
  get: (endPoint, token) => {
    return axiosInstance.get(`${baseURL}/${endPoint}`, generateHeaders(token))
  },
  post: (endPoint, body, token) => {
    return axiosInstance.post(`${baseURL}/${endPoint}`, body, generateHeaders(token))
  },
  put: (endPoint, body, token) => {
    return axiosInstance.put(`${baseURL}/${endPoint}`, body, generateHeaders(token))
  },
  delete: (endPoint, token) => {
    return axiosInstance.delete(`${baseURL}/${endPoint}`, generateHeaders(token))
  },
  getDataAPi:(urlApi) => {
    return axiosInstance.get(urlApi, generateHeadersApi())
  }
}

export default API