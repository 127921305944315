import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Login from '../Presentacions/Configuration/Login'
import Register from '../Presentacions/Configuration/Register'
import Help from '../Presentacions/Help'
import Home from '../Presentacions/Landing/Home'
import PanelWidget from '../Presentacions/Dashboard/PanelWidget'
import PanelUser from '../Presentacions/Dashboard/PanelUser'
import Products from '../Presentacions/Dashboard/Products'
import Demo from '../Presentacions/Landing/Demo/Demo'
import About from '../Presentacions/Landing/About'
import DashoardPanel from '../Presentacions/Dashboard/Dashboard'
import 'react-toastify/dist/ReactToastify.css'
//import '../assets/css/global.css'

import CreateProduct from '../Presentacions/Configuration/CreateProduct'
import PreviewProduct from '../Presentacions/Configuration/PreviewProduct'
import TypeWidget from '../Presentacions/Configuration/TypeWidget'
import WidgetFloating from '../Presentacions/Configuration/WidgetFloating'
import WidgetFixed from '../Presentacions/Configuration/WidgetFixed'
import Success from '../Presentacions/Configuration/Success'
import CreateCompaign from '../Presentacions/Configuration/CreateCompaign'
import CreateScript from '../Presentacions/Configuration/CreateScript'
import MisWidgets from '../Presentacions/Dashboard/MisWidgets'
import ClickGlobal from '../Presentacions/Dashboard/ClickGlobal'
import TypeInputProduct from "../Presentacions/Configuration/TypeInputProduct"
import CreateProductApi from "../Presentacions/Configuration/CreateProductApi"

const App = () => {

  const verifyLogged = (Component) => {
      return JSON.parse(localStorage.getItem("userAdmin")) ? <Component /> : <Redirect to="/login" />
  }

  return (
    <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
         <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/help">
             <Help />
          </Route>
          <Route exact path="/demo">
             <Demo />
          </Route>

          <Route exact path="/dashboard" render={() => verifyLogged(DashoardPanel)} />

          <Route exact path="/dashboard/widget" render={() => verifyLogged(MisWidgets)} />

          <Route exact path="/dashboard/widget/:id" render={() => verifyLogged(PanelWidget)} />

          <Route exact path="/dashboard/user" render={() => verifyLogged(PanelUser)} />
          
          <Route exact path="/dashboard/product" render={() => verifyLogged(Products)} />

          <Route exact path="/dashboard/interaccions" render={() => verifyLogged(ClickGlobal)} />

          <Route exact path="/configure/product" render={() => verifyLogged(CreateProduct)} />

          <Route exact path="/configure/product/preview" render={() => verifyLogged(PreviewProduct)} />

          <Route exact path="/configure/widget/type" render={() => verifyLogged(TypeWidget)} />

          <Route exact path="/configure/widget/floating" render={() => verifyLogged(WidgetFloating)} />
          
          <Route exact path="/configure/widget/fixed" render={() => verifyLogged(WidgetFixed)} />
          
          <Route exact path="/configure/compaign" render={() => verifyLogged(CreateCompaign)} />
          
          <Route exact path="/configure/script" render={() => verifyLogged(CreateScript)} />
          
          <Route exact path="/configure/success" render={() => verifyLogged(Success)} />

          <Route exact path="/configure/product/type" render={() => verifyLogged(TypeInputProduct)} />
          <Route exact path="/configure/product/api" render={() => verifyLogged(CreateProductApi)} />
        
        </Switch>
    </Router>
  );
}

export default App;
