import { Row, Col } from 'react-bootstrap'
import Panel from './Panel'

const MenuStep = ({ titleMenu, tab, title, subtitle, isVisible, url, isClass, children, title_1, isActiveLink=true, handleAlert }) => {
  return (
    <>
      <Row>
        	<Col md={12} className="text-center mt-5">
        		<div>
        			<div className={`form-menu mr-2 ${tab>=1 ? 'activo' : ''}`}></div>
  	      		<div className={`form-menu mr-2 ${tab>=2 ? 'activo' : ''}`}></div>
  	      		<div className={`form-menu mr-2 ${tab>=3 ? 'activo' : ''}`}></div>
  	      		<div className={`form-menu mr-2 ${tab>=4 ? 'activo' : ''}`}></div>
  	      	</div>
  	      	<p className="mt-4 text-white font-16 ">{titleMenu}</p>
        	</Col>
      </Row>
      <Panel 
            url={url}
            title={title}
            title_1={title_1}
            subtitle={subtitle}
            isVisible={isVisible}
            children={children}
            isClass={isClass}
            isActiveLink={isActiveLink}
            handleAlert={handleAlert}
      />
    </>  
  );
}

export default MenuStep;