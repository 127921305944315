import { useState } from 'react'
import { Row, Col, Button, Form, Container, Card } from 'react-bootstrap'
import Files from '../../Template/Products/Files'
import Template from '../Events/Template'
import Information from '../Events/Information'
import { getValidationFileInteration, addListInteraccions } from '../../../actions/interaccionAction'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'


const CargaHistoricalInteractions = ({ toast }) => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors, setValue } = useForm()
	const [ loading, setLoading ] = useState(false)

	const onSubmit =  async (data, e) => {
		e.preventDefault()
		setLoading(true)
	
		let response = await dispatch(getValidationFileInteration(data.cargas))
	
		if(response.err===null){
			setLoading(false)
			response = await dispatch(addListInteraccions(response.data))

			if(response.err===null){
				toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}
		}
		setLoading(false)
		
	}

	return (
		<Container>
			<Row>
				<Col xs={6} className="mx-auto">
					<Card body className="mt-5">
						<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" autoComplete="false">
							<Row>
								<Col xs={12} className="d-flex justify-content-between mb-3 flex-column flex-md-row">
									<Template 
										title="Base de datos"
									/>
								</Col>
								<Col xs={12} className="mb-3">
									<Files 
										title="Base de datos producto*" 
										errors={errors} 
										register={register} 
										setValue={setValue}
									/>
								</Col>
								<Col xs={12}>
									<Information />
								</Col>
								
								<Col md={12} xs={12}>
									<Button variant="primary" className="btn-continuar" type="submit" block disabled={loading}>
										{loading ? 'Procesando...' : 'Agregar' }
									</Button>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</Container>
		
	)
}

export default CargaHistoricalInteractions