import { 
	CREATE_WIDGET,
	UPDATE_WIDGET,
	TYPE_WIDGET,
	LIST_WIDGET_ALL,
	SELECTED_WIDGET,
	SEARCH_WIDGET,
	UPDATE_CATEGORIA_WIDGET
} from "../types/widgetType"

const INITIAL_STATE = {
	config_widget: JSON.parse(localStorage.getItem("config_widget")) || {},
	type_widget: "floating",
	list_widgets: [],
	selected_widget: {},
	visible_menu: false,
	list_view_widget: []
}

const widgetReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case CREATE_WIDGET:
			return {
				...state,
				config_widget: action.payload.configuration,
				list_widgets: action.payload.listado,
				list_view_widget: action.payload.listado
			}
		case UPDATE_WIDGET:
			return {
				...state,
				selected_widget: action.payload.configuration,
				list_widgets: action.payload.listado,
				list_view_widget: action.payload.listado
			}
		case UPDATE_CATEGORIA_WIDGET:
			return {
				...state,
				list_widgets: action.payload,
				list_view_widget: action.payload
			}
		case TYPE_WIDGET:
			return {
				...state,
				type_widget:action.payload
			}
		case LIST_WIDGET_ALL:
			return {
				...state,
				list_widgets: action.payload.list,
				selected_widget: action.payload.selected,
				list_view_widget: action.payload.list
			}
		case SELECTED_WIDGET:
			return {
				...state,
				selected_widget: action.payload.selected,
				visible_menu: action.payload.visible
			}
		case SEARCH_WIDGET:
			return {
				...state,
				list_view_widget: action.payload.listado
			}
		default: return state
	}
}

export default widgetReducer