import API from "../config/api"
import {
	CREATE_ADMIN
} from "../types/userAdminType"
import { verifyAccessToken } from '../utils/aws-cognito'

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const addConfigUserAdmin = (body) => async (dispatch, getState) => {
	try{

		const { tenant_id } = getState().tenant

		const data_user_admin = {
			external_id: body.email,
			tenant_id,
			active: true,
			attributes: {
				name: body.name,
				lastname: body.lastname,
				password: body.password
			}
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("admins/", data_user_admin, session?.token)
	  	
	  	const { err, msg } = response.data
		
	    if(err===null){
	    	const user = {
	    		...data_user_admin,
	    		attributes: {
	    			name: body.name,
					lastname: body.lastname
	    		}
	    	}
	        dispatch(requestSuccess(CREATE_ADMIN, user))
	        localStorage.setItem("userAdmin", JSON.stringify(user))
		}

      return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}


export const login = (body) => async (dispatch, getState) => {
	try{

		const information = {
			external_id: body.email,
			tenant_id: body.tenant_id,
			password: body.password
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("admins/login", information, session?.token)

	  	let { data, err, msg } = response.data

	    if(data!==null && Object.keys(data).length>0){
	    	const payload = {
				attributes: { lastname: data.attributes.lastname, name: data.attributes.name },
				external_id: data.external_id,
				id: data.id,
				tenant_id: data.tenant_id
	    	}
    		dispatch(requestSuccess(CREATE_ADMIN, payload))
        	localStorage.setItem("userAdmin", JSON.stringify(payload))
        	localStorage.setItem("tenant", body.tenant_id)
    	}else{
    		msg = err
    		err = false
    	}

		return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}