import { useState, useEffect } from 'react'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import { Form } from 'react-bootstrap'
import Compaign from '../../Components/Template/Compaign'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { checkStatusBellProduct, createBellProduct } from '../../actions/productAction'
import { setInfoWidget } from '../../actions/widgetAction'
import { useDispatch } from 'react-redux'

const CreateCompaign = () => {


	const history = useHistory()
	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()

		setLoading(true)

		let response = await dispatch(checkStatusBellProduct())

		setLoading(false)
		if(response.err===null && response.msg==="ACTIVE"){
			dispatch(setInfoWidget())
			history.push('/configure/script')
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	const isCheckCompaign = async () => {
		setLoading(true)

		let response = await dispatch(checkStatusBellProduct())
		if(response.err!==null){
			response = await dispatch(createBellProduct())
			setLoading(false)
			if(response.err!==null){
				toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
				return false
			}
		}else{
			setLoading(false)
			if(response.msg==="ACTIVE"){
				dispatch(setInfoWidget())
				if(response.err===null){
					history.push('/configure/script')
				}
			}		
		}
	}

	useEffect(() => {
		isCheckCompaign()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Creación de campaña" 
  	  			tab="3" 
  	  			title="Su campaña de recomendaciones" 
  	  			title_1="esta en proceso de creación" 
  	  			subtitle="Esto podría demorar unos minutos." 
  	  			isVisible={false}
  	  			url=""
  	  			isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
		  	>
		  		<Form onSubmit={(event)=>handleSubmit(event)}>
		  			<Compaign  loading={loading} />
		  		</Form>
		  	</MenuStep>
	  	</Layout>
  );
}

export default CreateCompaign;