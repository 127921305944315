import { Row } from 'react-bootstrap'
import Cards from '../../Template/Widget/Cards'
import Border from '../../Template/Widget/Border'
import Input from '../../Template/Widget/Input'
import BackgroundColor from '../../Template/Widget/BackgroundColor'
import Categories from '../../Template/Widget/Categories'
import StyleButton from '../../Template/Widget/StyleButton'
import TypeAction from '../../Template//Widget/TypeAction'

const Fixed = ({config_widget, register, setValue, errors }) => {


	return (	    
    	<Row>
    		<Cards title="Ingrese el nombre" is_class="mb-5 col-md-6 col-12">
  				<Input 
            register={register} 
            errors={errors} 
            value={config_widget?.configuration?.name}
            is_class={`input height_input pt-1 pb-1 pr-2 pl-3 ${config_widget?.configuration?.name!=="" ? 'input--readonly' : ''}`}
            //is_class={`input height_input pt-1 pb-1 pr-2 pl-3`}
            is_readOnly={config_widget?.configuration?.name!==""} 
            //is_readOnly={false} 
            name="name"
  				/>
  			</Cards>
  			
    		<Cards title="Ingrese la URL del sitio web" is_class="mb-5 col-md-6 col-12">
  				<Input 
            register={register} 
            errors={errors} 
            value={config_widget?.configuration?.url_site_web}
            //is_class={`input height_input pt-1 pb-1 pr-2 pl-3 ${config_widget?.configuration?.url_site_web!=="" ? 'input--readonly' : ''}`}
            is_class={`input height_input pt-1 pb-1 pr-2 pl-3`}
            //is_readOnly={config_widget?.configuration?.url_site_web!==""} 
            is_readOnly={false} 
            name="url_site_web"
  				/>
  			</Cards>

  			<Cards title="Seleccione la categoría" is_class="mb-5 col-md-6 col-12">
  				<Categories
  					register={register} 
  					//errors={errors} 
  					value={config_widget?.configuration?.categories} 
  					is_class="input height_input pt-1 pb-1 pr-2 pl-3" 
  					setValue={setValue}
            name="category"
            initial={true}
  				/>
  			</Cards>

		   <Cards title="Seleciona color de fondo" is_class="mb-5 col-md-6 col-12">
		      	<BackgroundColor 
		      		register={register} 
		      		errors={errors} 
		      		color={config_widget?.configuration?.background_color}
		      		setValue={setValue}
		      		is_class="widget-border"
		      		name="background_color"
		      	/>
		    </Cards>

		    {/**<Cards title="Seleciona color del texto" is_class="mb-5 col-md-6 col-12">
		      	<BackgroundColor 
		      		register={register} 
		      		errors={errors} 
		      		color={config_widget?.configuration?.text_color}
		      		setValue={setValue}
		      		is_class="widget-bor"
		      		name="text_color"
		      	/>
		    </Cards>**/}

		    <Cards title="Seleciona estilo del borde" is_class="mb-5 col-md-6 col-12">
		      	<Border register={register} setValue={setValue} value={config_widget?.configuration?.icon_type}/>
		    </Cards>

			
	   <Cards title="Seleccióne la acción" is_class="mb-5 col-md-6 col-12">
          <TypeAction
            register={register} 
            //errors={errors} 
            value={typeof config_widget?.configuration?.type_action==="undefined" ? "" : config_widget?.configuration?.type_action} 
            is_class="input height_input pt-1 pb-1 pr-2 pl-3" 
            setValue={setValue}
            name="type_action"
            initial={true}
          />
        </Cards>

        <Cards title="Seleciona estilos de botón" is_class="mb-5 col-md-6 col-12">
            <StyleButton 
              register={register} 
              errors={errors} 
              data={config_widget?.configuration?.buttons}
              setValue={setValue}
            />
        </Cards>
		</Row>
	);
}

export default Fixed;