import { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import widget_flotante from '../../assets/images/widget_flotante.png'
import widget_fijo from '../../assets/images/widget_fijo.PNG'

import { useDispatch } from 'react-redux'
import { getTypeWidget, getWidgetsByTenant } from '../../actions/widgetAction'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const TypeWidget = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit } = useForm()


	const onSubmit =  async (data, e) => {
		e.preventDefault()
		
		setLoading(true)

		const response = dispatch(getTypeWidget(data.type_widget))

		setLoading(false)
		if(response.err===null){
			if(data.type_widget==="floating"){
				history.push('/configure/widget/floating')
			}else{
				history.push('/configure/widget/fixed')
			}
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			return false
		}		
	}

	const searchDataWidget = async () => {		
		await dispatch(getWidgetsByTenant())
	}

	useEffect(() => {
		document.body.classList.remove('bg-dashboard')
		document.body.classList.add('bg-config')
		searchDataWidget()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Tipo de widget" 
  	  			tab="2" 
  	  			title="Tipo de widget" 
  	  			subtitle="" 
  	  			isVisible={true}
  	  			url="/configure/product"
  	  			isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
		  	>
		  		<Form onSubmit={handleSubmit(onSubmit)}>
			  		<Row>
					    <Col md={12} xs={12} className="mb-4">
					      	<Card body className="preferencia">
					      		<p className="mb-4">Seleciona el tipo de widget</p>
					      		<div>
					      			<div className="d-flex justify-content-around">
						      			<div className="form-check align-self-center pl-1">
										    <input 
										  		className="form-check-input form-check-border" 
										  		type="radio" 
										  		name="type_widget" 
										  		id="type_widget_1" 
										  		value="floating" 
										  		defaultChecked
										  		ref={register({required: {value: true, message: 'Requerido'}})} 
										  	/>
											<label className="form-check-label" htmlFor="type_widget_1">
											    Flotante
											</label>
										</div>
										<img src={widget_flotante} alt="Widget Flotante" height="150" width="170" className="shadow" />
									</div>
					      			<div className="d-flex justify-content-around mt-3">
						      			<div className="form-check align-self-center">
										    <input 
										  		className="form-check-input form-check-border" 
										  		type="radio" 
										  		name="type_widget" 
										  		id="type_widget_0" 
										  		value="fixed" 
										  		ref={register({required: {value: true, message: 'Requerido'}})} 
										  	/>
											<label className="form-check-label" htmlFor="type_widget_0">
											    Fijo
											</label>
										</div>
										<img src={widget_fijo} alt="Widget Flotante" height="120" width="230" className="shadow" />
									</div>
					      		</div>
					      	</Card>
					    </Col>

					    <Col md={12} xs={12} className="mb-5 mt-5">
					    	<Button variant="primary" className="btn-continuar btn-panel-config" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' } <span></span>
							</Button>
					    </Col>
					</Row>
				</Form>
		  	</MenuStep>
	  	</Layout>
  );
}

export default TypeWidget;