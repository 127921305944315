import { useState, useEffect } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  }
};



const GraficMetric = ({ dataGraficLineal }) => {

  const [ dataInitial, setDataInitial ] = useState({
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Dataset 1',
              data: [669, -863, 856, 518, 799, 412, -624],
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ],
  })

  useEffect(() => {
    setDataInitial({
            labels: dataGraficLineal?.labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: dataGraficLineal?.data,
                borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ],
    })
  }, [dataGraficLineal])

  return (
    <Line options={options} data={dataInitial} height={150} width={300}/>
  )
}

export default GraficMetric