import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import IconIAComputerILike from '../../assets/images/ia-computer-i-like.png'
import IconIAComputerDollar from '../../assets/images/ia-computer-dollar.png'
import IconIAComputerSetting from '../../assets/images/ia-computer-setting.png'

const Deal = () => {
  return (
    <Container className="bussiness-container  mb-5">
      <h1 className="text-center title--deal">ROCIO en tu negocio</h1>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
            <p className="pb-2 mb-2 text-center subtitle--deal">Dale a tus clientes lo que ellos quieren, mejora la experiencia dentro de tu sitio web. La personalización es un punto clave en los negocios</p>
        </Col>
      </Row>

        <div className="row box-negocio mt-5">
          <div className="js-scroll slide-left card--deal mb-5">
              <img src={IconIAComputerILike} className="img--center card--deal-eye" alt="IA Computer Eye" />
              <p className="text-center card--deal-title">
                Integración, rápida<br/>
                y sencilla
              </p>
              <p className="p-3 text-center card--deal-subtitle">
                Rocio es muy simple de implementar, inserta un código en tu página web y quedara operativo al instante.
              </p>
          </div>
          <div className="js-scroll fade-in-bottom card--deal mb-5">
              <img src={IconIAComputerDollar} className="img--center card--deal-eye" alt="IA Computer Eye" />
              <p className="text-center card--deal-title">
                Aumenta ventas en el<br/>
                canal digital
              </p>
              <p className="p-3 text-center card--deal-subtitle">
                Mejora la experiencia de usuario y aumenta la tasa conversión en el sitio web.
              </p>
          </div>
          <div className="js-scroll slide-right card--deal mb-5">
              <img src={IconIAComputerSetting} className="img--center card--deal-eye" alt="IA Computer Eye" />
              <p className="text-center card--deal-title">
                100%<br/>
                Autoconfigurable
              </p>
              <p className="p-3 text-center card--deal-subtitle">
                Rocio entrega opciones de personalización, entra a su panel de configuración, carga tu data y escoge el color y la posición de tu widget.
              </p>
          </div>
        </div>
        <br /><br />

    </Container>
  );
}

export default Deal;