import { useDispatch,  useSelector } from 'react-redux'
import { selectedItem } from '../../../../actions/productAction'
import { toast } from 'react-toastify'


const CheckDelete =  (row) => {

	const dispatch = useDispatch()
	const { deleted_option } = useSelector((state) => state.product)

	const selectedCheckProduct = (event, row) => {
		if(deleted_option!==''){
			if(row.active!==deleted_option){
        		toast.error("Debe seleccionar productos con el mismo estatus", {position: toast.POSITION.TOP_RIGHT})
        		event.target.checked= false
        		return false
			}
		}

		dispatch(selectedItem(row, row.active))
		
	}

	return (
		<div className="form-group">
		    <div className="form-check">
		      <input 
		      		className="form-check-input check-product" 
		      		type="checkbox" 
		      		id={`selected_item${row.id}`} 
		      		name="selected_item[]" 
		      		value={row.id}
		      		onChange={(event)=>selectedCheckProduct(event, row)}
		      />
		    </div>
		  </div>
	)
};
export default CheckDelete