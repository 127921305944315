import { 
	ADD_TENANT,
	LIST_TENANT_ASSIGN_USER,
	LOADING_TENANT,
	URL_DASHBOARD,
	SEARCH_TENANT,
	UPDATE_TENANT
} from "../types/tenantType"

const INITIAL_STATE = {
	tenant_id: localStorage.getItem("tenant"),
	list_tenants_user: [],
	loading_tenant: false,
	url_dashboard: "",
	tenant_data: {}
}

const tenantReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case ADD_TENANT:
			return {
				...state,
				tenant_id: action.payload.id,
				tenant_data: action.payload.data
			}
		case LIST_TENANT_ASSIGN_USER:
			return {
				...state,
				list_tenants_user: action.payload.listado,
				loading_tenant: action.payload.loading
			}
		case LOADING_TENANT:
			return {
				...state,
				list_tenants_user: action.payload.listado,
				loading_tenant: action.payload.loading
			}
		case URL_DASHBOARD:
			return {
				...state,
				url_dashboard: action.payload
			}
		case SEARCH_TENANT:
			return {
				...state,
				tenant_data: action.payload
			}
		case UPDATE_TENANT:
			return {
				...state,
				tenant_data: action.payload.data
			}
		default: return state
	}
}

export default tenantReducer