import { 
	CREATE_ADMIN
} from "../types/userAdminType"

const INITIAL_STATE = {
	userAdmin: JSON.parse(localStorage.getItem("userAdmin")) || {},
}

const userAdminReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case CREATE_ADMIN:
			return {
				...state,
				userAdmin: action.payload
			}
		default: return state
	}
}

export default userAdminReducer