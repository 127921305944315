import { useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'

import Layout from '../../Components/Dashboard/Layout'
import Table from '../../Components/Dashboard/Products/Datatable/Table'
import UploadFile from '../../Components/Dashboard/Products/UploadFile'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../../actions/productAction'
import { searchByTenant } from '../../actions/tenantAction'
import ButtonAdd from '../../Components/Dashboard/Products/ButtonAdd'
import Forms from '../../Components/Dashboard/Products/Forms'

const Products = () => {

	const dispatch = useDispatch()
	const { product, tenant  } = useSelector((state) => {
		return {
			product: state.product,
			tenant: state.tenant
		}
	})

	const { tenant_data } = tenant
	const { list, is_loading  } = product

	const [show, setShow] = useState(false)

	const getInfoTenant = async () => {
		await dispatch(searchByTenant())
	}

	useEffect(() => {
		if(list.length===0){
			dispatch(listProducts())	
		}		
		if(Object.keys(tenant_data).length===0){
			getInfoTenant()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	const handleClose = () => setShow(false)
	

	return (
	    <Layout title="CARGAR PRODUCTOS" modulo="product" isVisible={false}>
	    	<Row>
				<Col xs={11} className="mb-5 mx-auto">
					<UploadFile toast={toast} />
				</Col>
			</Row>
	    	<Row>
				<Col xs={11} className="mb-5 mx-auto">
			      	<Card body className="mb-5 pt-2 pl-0 pr-0 panel-product">
			      		<Table data={list} isCargando={is_loading} toast={toast} setShow={setShow} tenant_data={tenant_data} />
			      	</Card>
			    </Col>
			</Row>
			<ButtonAdd setShow={setShow} />
			{show && <Forms show={show} handleClose={handleClose} toast={toast} setShow={setShow} typeEdit={true} />}

	    </Layout>
	);
}

export default Products;