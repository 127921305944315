import { 
	LIST_INTERACCION,
	ADD_INTERACCION,
	INITIAL_LOADING,
	DELETE_INTERACCION,
	SELECTED_INTERACCION,
	EDIT_INTERACCION
} from "../types/interaccionType"

const INITIAL_STATE = {
	listInteraccions: [],
	isLoadingClick: false,
	interactionSelected: {},
	isVisibleModal: false,
	listNameInteraction: [],
	listOptions: []
}

const interaccionReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_INTERACCION:
			return {
				...state,
				listInteraccions: action.payload.listado,
				isLoadingClick: action.payload.isLoading,
				listNameInteraction: action.payload.listNameInteraction,
				listOptions: action.payload.listOptions
			}
		case ADD_INTERACCION:
			return {
				...state,
				listInteraccions: action.payload.listado
			}
		case INITIAL_LOADING:
			return {
				...state,
				isLoadingClick: action.payload
			}
		case DELETE_INTERACCION:
			return {
				...state,
				listInteraccions: action.payload.listado
			}
		case SELECTED_INTERACCION:
			return {
				...state,
				interactionSelected: action.payload.selected,
				isVisibleModal: action.payload.visible
			}
		case EDIT_INTERACCION:
			return {
				...state,
				listInteraccions: action.payload.listado,
				isVisibleModal: action.payload.visible,
				interactionSelected: action.payload.selected
			}
		default: return state
	}
}

export default interaccionReducer