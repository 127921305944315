import { useState, useEffect } from 'react'
import Layout from '../../Components/Dashboard/Layout'
import { Row, Col, Button, Form } from 'react-bootstrap'
import Fixed from '../../Components/Dashboard/Widget/Fixed'
import Floating from '../../Components/Dashboard/Widget/Floating'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { editConfigWiget, getWidgetById, getWidgetsByTenant } from '../../actions/widgetAction'
import { toast } from 'react-toastify'

import { useParams } from 'react-router-dom'
var tinycolor = require("tinycolor2");

const PanelWidget = () => {

	const dispatch = useDispatch()
	const { id } = useParams()
	const { selected_widget, list_view_widget  } = useSelector((state) => state.widget)
	const { register, handleSubmit, setValue, errors } = useForm()
	const [ loading, setLoading ] = useState(false)

	const searchDataWidget = async () => {		
		if(list_view_widget.length===0){
			await dispatch(getWidgetsByTenant())
		}

		if(id){
			dispatch(getWidgetById(id, true))
		}
	}

	useEffect(() => {
		searchDataWidget()		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])


	const onSubmit =  async (data, e) => {
		e.preventDefault()
		setLoading(true)
		
		data.text_color=getColorContrast(data.background_color)

		const body = {
			...data,
			buttons: {
				style_button: data.style_button,
				type_button: data.type_button,
				color_button: data.color_button
			}
		}

		const response = await dispatch(editConfigWiget(body, selected_widget?.configuration?.type, selected_widget.id))
		if(response.err===null){
			toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}else{
			toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
		}

		setLoading(false)
	}

	const getColorContrast = (color) => {
		let value = tinycolor(color)

		if(value.getLuminance() > 0.179) { 
		    return '#000'
		} else {
		    return '#fff'
		}
	}

	return (
	    <Layout title="Configuración de widget" modulo="widget" isVisible={true}>
			<div className="row">
				<div className="col-11 mx-auto">
					<Form onSubmit={handleSubmit(onSubmit)}>
							{
								(selected_widget?.configuration?.type) ? (
									<>
								{
									
									(selected_widget?.configuration?.type==="fixed") ? 
										<Fixed config_widget={selected_widget} register={register} setValue={setValue} errors={errors}  />
										: <Floating config_widget={selected_widget} register={register} setValue={setValue} errors={errors} /> 
								}
									<Row>
										<Col md={{ span: 4, offset: 8 }} xs={11} className="mb-5 mt-5">
											<Button variant="outline-primary" className="text-script pt-3 pb-3" type="submit" block disabled={loading}>
												{loading ? 'Editando...' : 'Guardar'}
											</Button>
										</Col>
									</Row>
									</>
								) : null		    			
							}
							
						</Form>
				</div>
			</div>
	    </Layout>
	);
}

export default PanelWidget;