import template from '../../../download/template_event.xlsx'

const Template = ({ title }) => {

	const dowloandTemplate = (event) => {
		event.preventDefault()
		let a = document.createElement('a');
        a.href = template;
        a.download = `template_event.xlsx`;
        a.click();
	}

	return (
		<>
		  	<p className="product--title">{ title }</p>
			<p className="product--link"><a href="!#" className="btn-link input-link" onClick={(event)=>dowloandTemplate(event)}><span></span> Descargar plantilla de Excel</a></p>
		</>
  	);
}

export default Template;