import { Form, Row, Col, Button } from 'react-bootstrap'
import ModalProduct from './ModalProduct'

import { useProducts } from '../../../hooks/Products/useProducts'

const Forms = ({ show, handleClose, toast, setShow, typeEdit }) => {

	const {
	    register,
		onSubmit,
		handleSubmit, 
		errors,
		bloquear,
		titleBtn,
		titleModal,
		loading
	  } = useProducts(toast, setShow, typeEdit)

	return (
		<ModalProduct show={show} handleClose={handleClose} titleModal={titleModal}>
	       <Form onSubmit={handleSubmit(onSubmit)}>
	       	
				    <Row className="mb-4">
					    <Col md={6} xs={12}>
					       <div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="sku"
					      			autoFocus
					      			id="sku"  
					      			className={`input input--box-shadow ${bloquear ? 'input--readonly' : ''}`}
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      			readOnly={bloquear}
					      		/>
					      		<label className="label" htmlFor="sku">SKU</label>
							    {errors.sku && (
							    	<Form.Text className="text-danger">
							      		{errors?.sku?.message}
							    	</Form.Text>
							    )}            
					        </div>	
					    </Col>
					    <Col md={6} xs={12}>
					      	<div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="product_name" 
					      			id="product_name" 
					      			className="input input--box-shadow"
					      			ref={register({required: {value: true, message: 'Requerido'}})} 
					      		/>
					      		<label className="label" htmlFor="product_name">Nombre</label>
					      		{errors.product_name && (
							    	<Form.Text className="text-danger">
							      		{errors?.product_name?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					</Row>
					<Row className="mb-4">
					    <Col md={6} xs={12}>
					       <div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="count"
					      			id="count"  
					      			className="input input--box-shadow"
					      			ref={register}
					      		/>
					      		<label className="label" htmlFor="count">Cantidad</label>          
					        </div>	
					    </Col>
					    <Col md={6} xs={12}>
					      	<div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="total_sales_before_rocio" 
					      			id="total_sales_before_rocio" 
					      			className="input input--box-shadow"
					      			ref={register} 
					      		/>
					      		<label className="label" htmlFor="product_name">Total de Ventas antes de Rocio</label>
					        </div>	
					    </Col>
					</Row>
					<Row>
					  	<Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="categories" 
					      			id="categories" 
					      			className={`input input--box-shadow ${bloquear ? 'input--readonly' : ''}`}
					      			ref={register({required: {value: true, message: 'Requerido'}})} 
					      		/>
					      		<label className="label" htmlFor="categories">Categorías</label>
					      		{errors.categories && (
							    	<Form.Text className="text-danger">
							      		{errors?.categories?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
						<Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design group-design-back">
					      		<textarea 
					      			type="text" 
					      			name="subcategories" 
					      			id="subcategories" 
					      			className={`textarea input--box-shadow`}
					      			ref={register} 
									rows={3}
									cols={3}
					      		/>
					      		<label className="label" htmlFor="subcategories">Subcategorías</label>
					        </div>	
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="product_image" 
					      			id="product_image" 
					      			className="input input--box-shadow" 
					      			ref={register({required: {value: true, message: 'Requerido'}})} 
					      		/>
					      		<label className="label" htmlFor="product_image">URL Imagen del Producto</label>
					      		{errors.product_image && (
							    	<Form.Text className="text-danger">
							      		{errors?.product_image?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design group-design-back">
					      		<textarea 
					      			type="text" 
					      			name="description_product" 
					      			id="description_product" 
					      			className="textarea input--box-shadow" 
					      			rows="3" 
					      			cols="3"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		<label className="label" htmlFor="description_product">Descripción</label>
					      		{errors.description_product && (
							    	<Form.Text className="text-danger">
							      		{errors?.description_product?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design group-design-back">
					      		<input 
					      			type="text" 
					      			name="url" 
					      			id="url" 
					      			className="input input--box-shadow" 
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		<label className="label" htmlFor="url">Url del producto</label>
					      		{errors.url && (
							    	<Form.Text className="text-danger">
							      		{errors?.url?.message}
							    	</Form.Text>
							    )}  
					        </div>
					    </Col>
					</Row>
					<Row>
						<Col xs={12} className="text-right">
					          <Button type="submit" className="btn-primary--height mr-2" disabled={loading}>
					            { loading ? 'Procesando...' : titleBtn }
					          </Button>
					          <Button variant="secondary" className="font-16" onClick={handleClose}>
					            Cerrar
					          </Button>
						</Col>
					</Row>
				 
			</Form>
		</ModalProduct>
	   
	)
}

export default Forms