import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeActiveProduct } from '../../../../actions/productAction'
import SwitchToogle from '../../../Template/SwitchToogle'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

const Toogle =  (row) => {

	const dispatch = useDispatch()
	const [ checked, setChecked ] = useState(row.active)
	const { product, tenant  } = useSelector((state) => {
		return {
			product: state.product,
			tenant: state.tenant
		}
	})

	const { tenant_data } = tenant
	const { list  } = product

	const selectedCheckProduct = (row) => {
		if(tenant_data?.personalize_data?.dataset_item_id!=="" && tenant_data?.personalize_data?.dataset_item_id!==null){
			const product_search = list.find(prod => prod.id===row.id)
			const message = `¿Desea ${product_search.active ? 'desactivar' : 'activar'} el producto?`
			const text_message = product_search.active ? 'Recuerde que estos productos no se mostrarán en la recomendación' : ''
			
			Swal.fire({
			title: message,
			text: text_message,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
			}).then(async (result) => {
			if (result.isConfirmed) {

				const product = { ...product_search, active: !product_search.active }
				const response = await dispatch(changeActiveProduct(product))
				setChecked(!checked)
				if(response.err===null){
					toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
				}else{
					toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
				}			    
			}
			})
		}else{
			toast.error("No se puede activar o desactivar porque no hay campaña asignada", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	return (
		<div className="container-input mx-auto">
			<SwitchToogle 
				handleActivarDes={()=>selectedCheckProduct(row)} 
				checked={checked} 
				color="active"
				titleOff="Off"
				titleOn="On"
				nameSwitch={row.id}
			/>	
		</div>
	)
};
export default Toogle