import { useState, useEffect } from 'react'
import Layout from '../../Components/Dashboard/Layout'
import { Row, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import CardsDetails from '../../Components/Template/Widget/CardsDetails'
import { getWidgetsByTenant, getWidgetById, changeCategoryById, searchByName } from '../../actions/widgetAction'
import { checkStatusBellProduct } from '../../actions/productAction'
import { searchByTenant } from '../../actions/tenantAction'
import { useHistory } from 'react-router-dom'
import Script from '../../Components/Dashboard/Widget/Script'
import search from '../../assets/images/search.svg'
//import sort from '../../assets/icono/sort.svg'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { FaPlus } from 'react-icons/fa'

const MisWidgets = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const [show, setShow] = useState(false)
	const { register, setValue, errors } = useForm()
	const [ check, setCheck ] = useState(false)
	const { widget, product, tenant  } = useSelector((state) => {
		return {
			widget: state.widget,
			product: state.product,
			tenant: state.tenant
		}
	})

	const { list_view_widget  } = widget
	const { check_bell_status  } = product
	const { tenant_data } = tenant

	const searchDataWidget = async () => {		
		await dispatch(getWidgetsByTenant())
	}

	const checkCompaign = async (status) => {
		setCheck(true)
		const response = await dispatch(checkStatusBellProduct())

		 if(status){
		 	if(response.err === null && response.msg!=="ACTIVE"){
				toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}else if(response.msg==="ACTIVE"){
				toast.success("Su campaña ha sido creada con éxito.",{position: toast.POSITION.TOP_RIGHT})
			}
		 }
		setCheck(false)
	}

	const getInfoTenant = async () => {
		await dispatch(searchByTenant())
	}

	useEffect(() => {
		if(list_view_widget.length===0){
			searchDataWidget()
		}
		
		if(Object.keys(tenant_data).length===0){
			getInfoTenant()
		}
		checkCompaign(false)
		dispatch(getWidgetById('', false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const selectedWidget = (id) => {
		history.push(`/dashboard/widget/${id}`)
	}

	const handleClose = () => setShow(false)

	const handlOpen = (id) => {
		dispatch(getWidgetById(id, false))
		setShow(true)
	}


	const isVerificarScript = (id) => {
		if(check_bell_status==='ACTIVE'){
			return <button className="btn btn-link color_link" onClick={()=>handlOpen(id)}>Ver script</button>
		}
		
		return <button type="button" className="btn btn-link color_link" onClick={()=>checkCompaign(true)}>{check ? 'Verificando...' : 'Verificar campaña'}</button>
	}

	const changeCategory = (event) => {
		let name_category = ""
		const selected_default = list_view_widget.find(wid => wid.id===event.target.dataset.id)
		if(selected_default){
			name_category = selected_default.configuration.categories[0]
		}

		Swal.fire({
		  title: '¿Está seguro de cambiar el widget a esta categoría?',
		  icon: 'warning',
		  showDenyButton: true,
		  confirmButtonColor: '#3085d6',
		  denyButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  denyButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  	const response = await dispatch(changeCategoryById(event.target.dataset.id, event.target.value))
			if(response.err===null){
				toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
			}		    
		  } else if (result.isDenied) {
		   event.target.value=name_category
		  }
		})
	}

	const searchName = (event) => {
		dispatch(searchByName(event.target.value))
	}

	return (
	    <Layout title="Mis widgets" modulo="mis-wigets" isVisible={false}>
	    	<Row className="panel-product">
				<Col lg={8} md={8} sm={8} xs={12} className="search-input">
			      	<Form.Group >
			      		<Form.Control 
						    	id="search" 
						    	type="text" 
						    	placeholder="Busca tu widget aquí…"
						    	aria-label="Busca tu widget aquí…"
						    	className="filter-product pl-4"
						    	autoComplete="off"
						    	onChange={searchName}
						    />
						    <div><img src={search} alt="Búsqueda" className="pl-2 pt-2" /></div>
			    	</Form.Group>
			    </Col>
			    {/**<Col lg={4} md={4} sm={4} xs={12} className="pl-0">
		  			<div className="container_ordenar_widget">
		  				<div className="ordenar_widget">
		  					<img src={sort} alt="Sort" />
		  				</div>
		  				<p className="mb-0 d-flex ml-2">Ordenar por</p>
		  			</div>
		  		</Col>**/}
			</Row>
	    	<Row>
	    		<Col xs={11} className="mx-auto">
	    			<h5 className="widget_all_title">{`Widget Creados (${list_view_widget?.length || 0})`}</h5>
	    		</Col>
				<Col xs={11} className="mx-auto">
					<Row>
						{
							(list_view_widget && list_view_widget.length > 0) && (
								list_view_widget.map((widget, index) => 
									<CardsDetails
										index={index}
										key={widget.id}
										register={register}
										setValue={setValue}
										errors={errors}
										name_widget={widget.configuration?.name || 'Nombre del Widget'}
										url={widget.configuration.url_site_web}
										type_widget={widget.configuration.type==="fixed" ? "Fijo" :"Flotante"}
										category={widget?.configuration?.categories}
										selectedWidget={selectedWidget}
										id={widget.id}
										text_script={isVerificarScript(widget.id)}
										changeCategory={changeCategory}
										initial={index===0 ? true : false}
									/> 
								)
							)
						} 
					</Row>
				</Col>
	    	</Row>
	    	{show && <Script show={show} handleClose={handleClose} setShow={setShow} />}
	    	<a href="/configure/widget/type" target="_blank" className="btn btn-warning text-white btn-add-product-dash btn-add-widget">
				 <FaPlus />
	    	</a>
	    </Layout>
	);
}

export default MisWidgets;