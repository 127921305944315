export const getListValidate = (list_interaccions) => {

	let interaccion_error = {err: null, msg:''}

	interaccion_error= validateHeader(Object.keys(list_interaccions[0]))

	if(interaccion_error.err!==null) return interaccion_error

	if(list_interaccions.length>300) return {err: false, msg:'El máximo de línea por archivo es de 300'}

	for (let clave in list_interaccions){

	  	interaccion_error = validateRow(list_interaccions[clave])
	}

	return interaccion_error
}

export const validateRow = (interaccion) => {

	let interaccion_error = {err: null, msg:''}

	for(let value of Object.entries(interaccion)){

  		interaccion_error = validarString(value)
  		if(interaccion_error.err!==null) return interaccion_error
	}

	return interaccion_error
}

const validarString = (value) => {

	if(value===""){
		return {err: false, msg: 'Favor revisar las columnas que podrían estar vacías.' }
	}

	if(typeof value !== 'number'){

		if(value.indexOf("\r\n")>0){
			return {err: false, msg: 'Favor revisar las columnas que podrían tener saltos de líneas.' }
		}

		let regexp = /\d\.\s+•\s+|[IVX]+\.\s+/g
		if(regexp.test(value)){
			return {err: false, msg: 'Favor revisar las columnas que podrían tener viñetas.' }
		}
	}
	
	return { err: null, msg:'' }
}

const validateHeader = (product_header) => {

	const header = ["product_id", "event_type" ]
	let count_valid = 0

	if(product_header.length===header.length){

		for(let x = 0; x < product_header.length; x++){
			if(header.includes(product_header[x])){
				count_valid++
			}
		}

		if(count_valid!==header.length){
			return { err: false, msg: 'Alguno de los nombre del encabezado no coinciden con el mostrado en el formato.'}
		}
		
		return { err: null, msg:'' }
	}else if(product_header.length>header.length){
		return { err: false, msg:'El archivo puede que contenga una columna adicional distinta a la presentada en el formato.' }
	}

	return { err: false, msg:'Posiblemente falte una columna según lo presentado en el formato.' }
}
