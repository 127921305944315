import { Col, Card, Button } from 'react-bootstrap'
import Categories from './Categories'
import web from '../../../assets/images/web_programming_widget.svg'
import Details from './Details'

const CardsDetails = ({ register, setValue, errors, name_widget, url, type_widget, category, selectedWidget, id, text_script, index, changeCategory, initial }) => {

	return (
	    <Col md={4}> 
			<Card className="mb-5">
			  <Card.Body>
			  	<p className="title_widget">{name_widget}</p>
			  	<div className="mb-4">
				  	<Categories
	  					register={register} 
	  					errors={errors} 
	  					value={category}
	  					is_class="input height_input shadow-none pt-1 pb-1 pr-2 pl-3" 
	  					setValue={setValue}
	  					name={`category${index}`}
	  					changeCategory={changeCategory}
	  					id={id}
	  					initial={initial}
	  				/>
  				</div>
  				<Details 
  					is_class="details_config_widget"
  					title="Url:"
  					texto={url}
  				/>
  				<Details 
  					is_class="details_config_widget"
  					title="Tipo:"
  					texto={type_widget}
  				/>
  				<Details 
  					is_class="details_config_widget details_config_widget--border"
  					title={<img src={web} alt="Ver script"/>}
  					texto={text_script}
  				/>
                <Button className="btn btn-block btn-primary btn-continuar mt-3 mb-5 btn_primary---height" type="button" onClick={()=>selectedWidget(id)}>
				    Configuración de widget
				</Button>
			  </Card.Body>
			</Card>
		</Col>
	);
}

export default CardsDetails;