import { useState, useEffect } from 'react'
import Layout from '../../Components/Dashboard/Layout'
import CardMetric from "../../Components/Dashboard/Metric/CardMetric"
import ItemMetric from "../../Components/Dashboard/Metric/ItemMetric"
import ItemResultCategory from "../../Components/Dashboard/Metric/ItemResultCategory"
import ModalCategory from "../../Components/Dashboard/Metric/ModalCategory"
import { getEventByTenant, getProductByDetails, listProducts, getGroupByCategories, getEventByTenantGrafic } from '../../actions/productAction'
import { searchListCategories } from '../../actions/categoriesAction'

import { useDispatch, useSelector } from 'react-redux'
import grafic_1 from "../../assets/images/grafic-1.svg"
import grafic_2 from "../../assets/images/grafic-2.svg"
import { getTime } from "date-fns"
import { dateFormat } from "../../utils/tool-date"

const Dashboard = () => {

	const dispatch = useDispatch()
	const [ show, setShow ] = useState(false)
	const [ dataCategory, setDataCategory ] = useState({ title: "", items: []})
	const [values, setValues] = useState([
        new Date(),
        new Date(),
      ])
	const [ valuesGrafic, setValuesGrafic ] = useState([
        new Date(`01/01/${new Date().getFullYear()}`),
        new Date(),
      ])
	const { product, categories } = useSelector((state) => {
		return {
			product: state.product,
			categories: state.categories
		}
	})

	const { listFilteredProducts, list, categoriesGroup, dataGraficLinealSell, dataGraficLinealPercentaje } = product
	const { lists_categories } = categories

	const handleGetName = (title) => {
		setDataCategory(title)
		setShow(true)
	}
	
	const handleClose = () => setShow(false)

	const getDataVenta = async (data, initial=false) => {
		if(!initial){
			setValues(data)
		}
		
		let dateFrom = "", dateTo= ""
		if(typeof data[0] !== "undefined"){
			let dateCurrent = data[0].format()
			dateFrom = getTime(dateFormat(dateCurrent))
		}
		if(typeof data[1] !== "undefined"){
			let dateCurrent = data[1].format()
			dateTo = getTime(dateFormat(dateCurrent))
		}
		if(dateFrom!=="" && dateTo!==""){
			await getDataDashboard(dateFrom, dateTo)
		}		
	}

	const getDataDashboard = async (dateFrom, dateTo) => {
		const body = { dateFrom, dateTo }
		const response = await dispatch(getEventByTenant(body))

		if(response.err===null){
			const result = dispatch(getProductByDetails())
			if(result.err===null){
				dispatch(getGroupByCategories())
			}
		}
	}

	const getListProduct = async () => {
		if(list.length===0){
			await dispatch(listProducts())
		}	
		if(lists_categories.length===0){
			await dispatch(searchListCategories())
		}	

		if(list.length===0 && lists_categories.length===0){
			await getDataDashboard(getTime(values[0]), getTime(values[1]))
		}	

		if(list.length===0){
			await getDataGraficDashboard(getTime(valuesGrafic[0]), getTime(valuesGrafic[1]))
		}	
	}

	const getDataGrafic = async (data, initial=false) => {

		if(!initial){
			setValuesGrafic(data)
		}
		
		let dateFrom = "", dateTo= ""
		if(typeof data[0] !== "undefined"){
			let dateCurrent = data[0].format()
			dateFrom = getTime(dateFormat(dateCurrent))
		}
		if(typeof data[1] !== "undefined"){
			let dateCurrent = data[1].format()
			dateTo = getTime(dateFormat(dateCurrent))
		}
		if(dateFrom!=="" && dateTo!==""){
			const body = { dateFrom, dateTo }
			await dispatch(getEventByTenantGrafic(body))
		}
	}

	const getDataGraficDashboard = async (dateFrom, dateTo) => {
		const body = { dateFrom, dateTo }

		await dispatch(getEventByTenantGrafic(body))
	}

	useEffect(() => {
		getListProduct()		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Layout title="Dashboard" modulo="dashboard" isVisible={false}>
			<div>
				<div className="col-11 mx-auto">
					<section className="row">
						<ItemMetric title="Métricas Rocio" getDataEvent={getDataGrafic} values={valuesGrafic}>
							<CardMetric
								price="$92,576"
								title="Ventas mensuales de Rocio"
								classNew="mr-5 card-light-purple dashboard-card-big"
								visiblePorcent={false}
								visibleText={false}
								visibleGrafic={true}
								classIcon=""
								iconGrafic={grafic_1}
								textTooltip=""
								dataGraficLineal={dataGraficLinealSell}
							/>
							<CardMetric
								price="$102,576"
								title="Porcentaje de ventas de Rocio"
								classNew="card-light-purple dashboard-card-big"
								visiblePorcent={true}
								visibleText={false}
								visibleGrafic={true}
								classIcon=""
								iconGrafic={grafic_2}
								textTooltip=""
								dataGraficLineal={dataGraficLinealPercentaje}
							/>
						</ItemMetric>
						<ItemMetric title="Resultados de ventas" getDataEvent={getDataVenta} values={values}>
							<CardMetric
								price={`$ ${listFilteredProducts?.total_cart_generated?.total}`}
								title="Carritos generados"
								classNew="mr-5 card-light-aqua dashboard-card-small"
								visiblePorcent={false}
								visibleText={true}
								visibleGrafic={false}
								classIcon="icon-compras"
								iconGrafic=""
								textTooltip="Cantidad de carritos que han sido creados, pero no necesariamente se han completado"
							/>
							<CardMetric
								price={`$ ${listFilteredProducts?.total_cart_sold?.total}`}
								title="Total carritos vendidos"
								classNew="mr-5 card-light-aqua dashboard-card-small"
								visiblePorcent={false}
								visibleText={true}
								visibleGrafic={false}
								classIcon="icon-ventas"
								iconGrafic=""
								textTooltip="Cantidad de carritos con que han llegado a una compra exitosa"
							/>
							<CardMetric
								price={`$ ${listFilteredProducts?.total_cross_selling?.total}`}
								title="Ventas Cross Selling"
								classNew="card-light-aqua dashboard-card-small"
								visiblePorcent={false}
								visibleText={true}
								visibleGrafic={false}
								classIcon="icon-rocio"
								iconGrafic=""
								textTooltip="Productos que han sido agregados al carrito desde Rocio"
							/>
						</ItemMetric>
						<ItemResultCategory handleGetName={handleGetName} categoriesGroup={categoriesGroup} />
					</section>
				</div>
			</div>
			{
				show && <ModalCategory show={show} handleClose={handleClose} data={dataCategory} />
			}
		</Layout>
	)
}

export default Dashboard