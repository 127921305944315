import { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'


import { useDispatch, useSelector } from 'react-redux'
import { addConfigWiget } from '../../actions/widgetAction'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import PanelPosition from '../../Components/Template/Widget/PanelPosition'
import Cards from '../../Components/Template/Widget/Cards'
import Input from '../../Components/Template/Widget/Input'
import TypeIcon from '../../Components/Template/Widget/TypeIcon'
import BackgroundColor from '../../Components/Template/Widget/BackgroundColor'
import Categories from '../../Components/Template/Widget/Categories'

const WidgetFloating = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit, errors, setValue } = useForm()
	const { type_widget  } = useSelector((state) => state.widget)

	const onSubmit =  async (data, e) => {
		e.preventDefault()
		
		setLoading(true)
		let response = await dispatch(addConfigWiget(data, "floating"))

		setLoading(false)
		if(response.err!==null){
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			history.push('/configure/compaign')
		}	
	}

	useEffect(() => {
		if(type_widget!=="floating"){
			history.push(`/configure/widget/${type_widget}`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps	
	}, [type_widget])

	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Preferencias de widget" 
  	  			tab="2" 
  	  			title="Configurar tu widget" 
  	  			subtitle="" 
  	  			isVisible={true}
  	  			url="/configure/widget/type"
  	  			isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
		  	>
		  		<Form onSubmit={handleSubmit(onSubmit)}>
			  		<Row>
			  			<Cards title="Ingrese el nombre" is_class="mb-4 col-md-12 col-12">
			  				<Input 
			  					register={register} 
			  					errors={errors} 
			  					value=""
			  					is_class="input height_input pt-1 pb-1 pr-2 pl-3"
			  					is_readOnly={false} 
			  					name="name"
			  				/>
			  			</Cards>
			  			<Cards title="Ingrese la URL del sitio web" is_class="mb-4 col-md-12 col-12">
			  				<Input 
			  					register={register} 
			  					errors={errors} 
			  					value="" 
			  					is_class="input height_input pt-1 pb-1 pr-2 pl-3" 
			  					is_readOnly={false}
			  					name="url_site_web"
			  				/>
			  			</Cards>
			  			<Cards title="Seleccione la categoría" is_class="mb-4 col-md-12 col-12">
			  				<Categories
			  					register={register} 
			  					//errors={errors} 
			  					value="" 
			  					is_class="input height_input pt-1 pb-1 pr-2 pl-3"
			  					setValue={setValue}
			  					name="category" 
			  					initial={true}
			  				/>
			  			</Cards>
					    <Cards title="Seleciona tipo de icono" is_class="mb-4 col-md-12 col-12">
					      	<TypeIcon 
					      		register={register} 
					      		setValue={setValue} 
					      		value="circle"
		      				/>
					    </Cards>

					    <Cards title="Seleciona color de fondo" is_class="mb-4 col-md-12 col-12">
					      	<BackgroundColor 
					      		register={register} 
					      		errors={errors} 
					      		color="#0093DF"
					      		setValue={setValue}
					      		is_class="color-icono"
					      		name="background_color"
		      				 />
					    </Cards>

					    <Cards title="Seleciona la posición" is_class="mb-4 col-md-12 col-12">
					      	<PanelPosition 
					      		register={register} 
					      		value={{position: 'position_right_lower' , title: 'Derecha inferior'}} 
					      		setValue={setValue}
					      	/>
					    </Cards>

					    <Col md={12} xs={12} className="mb-5">
					    	<Button variant="primary" className="btn-continuar btn-panel-config" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' } <span></span>
							</Button>
					    </Col>
					 </Row>
				</Form>
		  	</MenuStep>
	  	</Layout>
  );
}

export default WidgetFloating;