import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Panel = ({ url, title, subtitle, isVisible, isClass, children, title_1, isActiveLink, handleAlert}) => {
	return (
		<div className="home-section">
			<div className="home-content">
				<ul className="circles">
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
				<Row className="header-login">
					<div className={`${isClass}`}>
						<Card body className="padding-config mb-md-5 pt-5">
							{
								isVisible && (
									<Row>
										<Col xs={12} className="text-right col-12 mb-4 mt-2">
											{
												isActiveLink ? <Link to={url} className="text-link-volver text-decoration-none"><span className="mr-1"></span>Volver</Link>
												: <a href="!#" className="text-link-volver text-decoration-none" onClick={handleAlert}><span className="mr-1"></span>Volver</a>
											}
										</Col>
									</Row>
								)
							}
							<Row className="panel-form mb-3">
							<Col xs={12} className="text-center">
								<h4 className={`title-panel-conf ${title_1 ? "panel-form-title" : ""}`}>{title}</h4>
								{(title_1 && <h6 className="panel-form-title">{title_1}</h6>)}
								<p className="panel-body">{subtitle}</p>
							</Col>
							</Row>
							{children}
						</Card>
					</div>
				</Row>
			</div>
		</div>
	);
}

export default Panel;