import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Layout from '../../Components/Dashboard/Layout'
import FormClickGlobal from '../../Components/Dashboard/Interacciones/FormClickGlobal'
import CargaHistoricalInteractions from '../../Components/Dashboard/Interacciones/CargaHistoricalInteractions'
import FormModalEdit from '../../Components/Dashboard/Interacciones/Modal/FormModalEdit'

import { getListInteraccion, getDataInteraction } from '../../actions/interaccionAction'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const ClickGlobal = () => {

	const dispatch = useDispatch()
	const [ moduloInteraccion, setModuloInteraccion ] = useState('menuClics')
	const { listInteraccions, isLoadingClick, isVisibleModal, listNameInteraction, listOptions } = useSelector((state) => state.interaccion)

	const visibleInteraccion = (event) => {
		setModuloInteraccion(event.target.value)
	}

	const handleClose = () => {
		dispatch(getDataInteraction({}, false))
	}

	useEffect(() => {
		dispatch(getListInteraccion())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		 <Layout title="Módulo de interacciones" modulo="interacciones" isVisible={false}>
		 	<Row>
			 	<Col xs={11} className="mb-5 mx-auto">
		 			<div className="menu__container">
		 				 {/* <div className="menu-select">
							 <input 
							 	type="radio"
								className="menu-select--input" 
			 					id="menuInteractions" 
			 					value="menuInteractions" 
			 					name="type_interaccion" 
			 					checked={moduloInteraccion==='menuInteractions' ? true : false}
			 					onChange={(event) => visibleInteraccion(event)}
							 />
							 <label htmlFor="menuInteractions">Históricas</label>
						 </div> */}
			 			<div className="menu-select">
			 				<input 
			 					type="radio" 
			 					className="menu-select--input" 
			 					id="menuClics" 
			 					value="menuClics" 
			 					name="type_interaccion" 
			 					checked={moduloInteraccion==='menuClics' ? true : false}
			 					onChange={(event) => visibleInteraccion(event)}
			 				/>
			 				<label htmlFor="menuClics">Clics globales</label>
			 			</div>
						
		 			</div>
		 		</Col>
		 	</Row>
	    	{
	    		(moduloInteraccion==="menuClics") ? (
	    			<FormClickGlobal 
	    				toast={toast} 
	    				interaccionsGlobal={listInteraccions} 
	    				isLoadingClick={isLoadingClick} 
	    				listNameInteraction={listNameInteraction}
						listOptions={listOptions}
	    			/>
				) : <CargaHistoricalInteractions toast={toast} />
	    		
	    	}
	    	{ isVisibleModal && 
				<FormModalEdit 
					show={isVisibleModal} 
					handleClose={handleClose} 
					toast={toast}  
					listNameInteraction={listNameInteraction} 
					listOptions={listOptions}
				/>}
	    </Layout>
	)
}

export default ClickGlobal