import logo from '../../../assets/images/logo_blanco.png'
import { Link } from 'react-router-dom'

const Header = ({ url, children }) => {
  return (
    <div className="container-lg">
      <header className="nav-menu pt-5">
        <div className="mb-4">
          <img className="header-logo" src={logo} alt="Logo" />
        </div>
        <ul className="nav mb-4">
          <li className="nav-item"><Link to="/" className={`nav-link ${url==='home' ? 'nav-link---active' : ''} text-white cursor--pointer`}>Inicio</Link></li>
          {/* <li className="nav-item"><Link to="/" className="nav-link text-white cursor--pointer">Solución</Link></li> */}
          <li className="nav-item"><Link to="/about" className={`nav-link ${url==='about' ? 'nav-link---active' : ''} text-white cursor--pointer`}>Conoce a Rocio</Link></li>
        </ul>
        <div className="nav--box-buttons mb-4">
          <Link className="btn btn-secondary btn--guest-secondary mr-md-4 mb-2" to='/login'>
            Ingresa
          </Link>
          <Link className="btn btn-primary btn--guest-primary mb-2" to='/demo'>
            Solicita tu demo
          </Link>
        </div>
      </header>
      {children}
    </div>
  );
}

export default Header;