import Header from './Header'
import '../../../assets/css/landing.css'
import styled from 'styled-components'
import imgHeaderBackground from '../../../assets/images/ia-computer.png'

const BoxHeader = styled.div`
  background-image: url(${imgHeaderBackground});
  background-position: 67% bottom;
  background-repeat: no-repeat;
  background-size: 870px;
`

const Layout = ({
  children,
  ...props
}) => {
  return (
    <BoxHeader className="container-fluid header--background">
      <Header {...props} />
      {children}
     </BoxHeader>
  );
}

export default Layout;
