import { Alert } from 'react-bootstrap'
import iconAlert from '../../../assets/images/alert.svg'

const Information = () => {


	return (
	  	<>
	  		<Alert  variant="warning" className="d-flex flex-column">
			   <div className="mr-3 mb-3 d-flex flex-row">
			   		<img src={iconAlert} alt="Icono Alerta" className="mr-2" />
			   		<p className="mb-0 mt-1">La tabla debe contener los siguientes datos obligatorios</p>
			   </div>
			   <div className="cuadro-item flex-wrap flex-md-nowrap">
		   			<p className="item_column flex-fill border--left">product_id</p>
		   			<p className="item_column flex-fill">event_type</p>
		   		</div>
			</Alert>
			<hr className="mt-4"/>
	  	</>
  );
}

export default Information;