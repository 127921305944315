
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import { Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import TextScript from '../../Components/Template/Script/TextScript'


const CreateScript = () => {


	const history = useHistory()

	const nextPage = async (e) => {
		history.push('/configure/success')
	}


	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Código script" 
  	  			tab="4" 
  	  			title="Script" 
  	  			subtitle="Copia el script en tu sitio web y comienza a recomendar" 
  	  			isVisible={false}
  	  			url=""
  	  			isClass="col-lg-7 col-md-10 col-12 mx-auto"
		  	>
		  			<Row>
					    <TextScript />
					    

					    <Col md={12} xs={12} className="mb-5 mt-5">
					    	<Button variant="primary" className="btn-continuar btn-panel-config" type="button" block onClick={()=>nextPage()}>
							    Finalizar e ir al panel
							</Button>
					    </Col>

					</Row>
		  	</MenuStep>
	  	</Layout>
  );
}

export default CreateScript;