import {
  Container,
  Row
} from 'react-bootstrap'
import IconUpArrow from '../../assets/images/arrow-up.svg'
import ImageIARobot from '../../assets/images/ia-robot.png'

const ImproveConversation = () => {
  return (
      <div className="home-section w-sm-100" id="home">
        <div className="home-content">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <Container fluid className="improve--conversation-background p-5">
              <Row className="section-home">
                  <div className="section-home__improve">
                    <p className="pt-5 improve--conversation-title mb-0 text-center text-md-left">
                      <span className="text__improve pl-md-5">+30%</span>
                      <img
                        src={IconUpArrow}
                        className="improve--conversation-icon-up-arrow"
                        alt="Up Arrow"
                      />
                    </p>
                    <p className="improve--conversation-subtitle pt-0 text-center">Rocío mejora tu conversión de clientes y aumenta las ventas de tu canal digital</p>                    
                  </div>
                  <div>
                    <img
                      src={ImageIARobot}
                      className="improve--conversation-image-ia-robot"
                      alt="IA Robot"
                      />
                  </div>
              </Row>
          </Container>
        </div>
      </div>
  );
}

export default ImproveConversation;