import { Modal } from "react-bootstrap"
import video_promociona_rocio from "../../assets/video/rocio-widget-con-inteligencia-artificial.mp4"

const ModalInitial = ({ show, handleClose}) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body className="p-0">
                <div className="embed-responsive embed-responsive-16by9">
                    <video id="video" autoPlay muted loop controls>
                        <source src={video_promociona_rocio}  type="video/mp4" />
                    </video>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInitial