import { Modal, Button } from "react-bootstrap"

const ModalCategory = ({ show, handleClose, data }) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{`Resultado de categoría ${data?.title}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">SKU</th>
                        <th scope="col">Imagen</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.items?.length>0 ? data?.items?.map((item, key) =>
                                <tr key={key}>
                                    <th scope="row">{item.sku}</th>
                                    <td><img src={item?.attributes?.product_image} alt={item?.product_name} /></td>
                                    <td>{item?.product_name}</td>
                                    <td>{item?.attributes?.count}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <th colSpan={4}>No hay registros disponibles</th>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCategory