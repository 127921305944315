import { useState } from 'react'
import PanelDemo from '../../../Components/Landing/PanelDemo'
import Formulario from './Formulario'
import Enviado from './Enviado'

const Demo = () => {
	
	const [ page, setPage ] = useState(1)
    const [ message, setMessage ] = useState("")

	return (
    	<PanelDemo>
    		{
    			(page===1) 
    				? <Formulario setPage={setPage} setMessage={setMessage} />
    				: <Enviado message={message} />
    		}
    	</PanelDemo>
	);
}

export default Demo;
