import { useDispatch } from 'react-redux'
import { selectedItemDelete } from '../../../actions/productAction'


const CheckDelete =  (row) => {

	const dispatch = useDispatch()

	const selectedCheckProduct = (event, row) => {
		dispatch(selectedItemDelete(row))
		
	}

	return (
		<div className="form-group">
		    <div className="form-check">
		      <input 
		      		className="form-check-input check-product" 
		      		type="checkbox" 
		      		id={`selected_item${row.id}`} 
		      		name="selected_item[]" 
		      		value={row.id}
		      		onChange={(event)=>selectedCheckProduct(event, row)}
		      />
		    </div>
		  </div>
	)
};
export default CheckDelete