import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import delete_img from '../../../../assets/images/trash-can.svg'

import { deleteInteraccion } from '../../../../actions/interaccionAction'
import Swal from 'sweetalert2'

const CheckDelete =  (row) => {

	const dispatch = useDispatch()

	const deleteClick = (event, row) => {

		Swal.fire({
		  title: '¿Esta seguro que desea eliminar esta interacción?',
		  text: 'Recuerde que esta interacción se eliminará en su recomendación',
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
			const response = await dispatch(deleteInteraccion(row.id))
			if(response.err===null){
				toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
			}			    
		  }
		})
	}

	return (
	    <button type="button" className="btn btn-delete-int" onClick={(event)=>deleteClick(event, row)}>
	    	<img src={delete_img} alt="Eliminar" />
	    </button>
	)
};
export default CheckDelete