import { Row, Col, Container } from 'react-bootstrap'
import logo from '../../assets/images/logo.png'
import logo_white from '../../assets/images/logo_blanco.png'
import { useHistory, Link  } from 'react-router-dom'


const Header = ({
  volver,
  isLogoWhile = false
}) => {
  const history = useHistory()
  
  const goBack = () => {
    history.goBack()
  }
  return (
    <Row className="row--linear-gray">
      <Col>
        <Container>
          <header className="pt-3 pb-3 d-flex justify-content-between align-items-center">
              <img className="header-logo" src={isLogoWhile ? logo_white : logo} alt="Logo" />
              <p className="mb-0 header--text">
                {
                  volver ? <Link to="/help" className="text-white text-decoration-none">Ayuda</Link>
                  : <Link onClick={goBack} className="link-volver-demo text-decoration-none"><span></span> Volver</Link>
                }
              </p>
          </header>
        </Container>
      </Col>
    </Row>
  );
}

export default Header;