import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addInteraccion } from "../../actions/interaccionAction"
import { useDispatch, useSelector } from 'react-redux'


export const useInteraction = (toast) => {

	const dispatch = useDispatch()
	const [ bloquear, setBloquear ] = useState(false)
	const [ titleBtn, setTitleBtn ] = useState('')
	const [ titleModal, setTitleModal ] = useState('')
	const [ visibleAttrib, setVisibleAttrib ] = useState(false)
	
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit, errors, reset } = useForm()
	const { interactionSelected  } = useSelector((state) => state.interaccion )


	useEffect(() => {
		reset(interactionSelected)
		setBloquear(true)
		setTitleBtn('Editar')
		setTitleModal('Editar Interacción')

		if(interactionSelected?.type_attribute==="data"){
			setVisibleAttrib(true)
		}else{
			setVisibleAttrib(false)
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [interactionSelected])

	const onSubmit =   async (data, e) => {
		e.preventDefault()
		setLoading(true)

		const response = await dispatch(addInteraccion(data, "edit"))

		if(response.err===null){
			toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}else{
			toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
		}
		setLoading(false)
	}

	return {
		register,
		onSubmit,
		handleSubmit, 
		errors,
		bloquear,
		titleBtn,
		titleModal,
		loading,
		visibleAttrib,
		setVisibleAttrib		
	}
}