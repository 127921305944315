import {
    Col
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Contactanos = () => {
    return (
        <div className="row w-sm-100 bg-contactanos m-0">
            <Col>
                <div className="bg-contactanos p-5 text-center d-flex flex-column align-items-center justify-content-center">
                    <h1>¿Quieres conocer más de ROCIO?</h1>
                    <p>Agenda tu demo y conversa con uno de nuestros ejecutivos</p>
                    <Link className="btn btn-primary mt-5 btn--guest-primary" to='/demo'>
                        Conversemos
                    </Link>
                </div>
            </Col>
        </div>
    );
}

export default Contactanos;
