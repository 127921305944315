import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import { Form } from 'react-bootstrap'
import { searchListCategories } from '../../../actions/categoriesAction'

const Categories = ({ register, value, is_class, setValue, name, changeCategory, id, initial }) => {

      const dispatch = useDispatch()
      const { lists_categories } = useSelector((state) => state.categories)

      const searchCategories = async () => {
            await dispatch(searchListCategories())
      }

      useEffect(() => {
          if(lists_categories.length===0 && initial){
             searchCategories()
          }

           if(value && lists_categories.length>0){
             setValue(name, value[0])
           }
           // eslint-disable-next-line react-hooks/exhaustive-deps      
      }, [lists_categories.length])

	return (
		<div className="input-group-design mb-2 top_select"> 
      		<select 
                        className={is_class}
                        id={name} 
                        name={name}
                        //ref={register({required: {value: true, message: 'Requerido'}})}
                        ref={register}
                        onChange={changeCategory}
                        data-id={id}
                  >
                  <option value="">- Sin categorías -</option>
                  {
                        lists_categories.length > 0 && (
                              lists_categories.map(category => <option key={category} value={category}>{category}</option>)
                        )
                  }
                  </select>
                  <i></i>
      	 
		</div>
  );
}

export default Categories;