import { Row, Col, Button } from 'react-bootstrap'
import upload from '../../assets/gifs/loading-products.gif'
import { useHistory } from 'react-router-dom'

const Compaign = ({ loading }) => {

	const history = useHistory()

	const nextDashboard =  () => {
		history.push('/dashboard/interaccions')
	}

	return (
		<Row >
		    <Col md={12} xs={12} className="mb-5 text-center mt-0">
		    	<p className="mb-4 font-16">Recibiras un correo cuando el proceso haya finalizado</p>
		      	<img alt="Exito" src={upload} className="img-fluid size-icon-step" />
		    </Col>
		    
		    <Col md={12} xs={12} className="mb-5">
		    	<Button 
		    		variant="primary" 
		    		className="btn-continuar" 
		    		type="submit" 
		    		block 
		    		disabled={loading}
		    	>
				    {loading ? 'Verificando...' : 'Verificar campaña' }
				</Button>
				<Button 
					variant="primary" 
					className="btn-continuar mt-3" 
					type="button" 
					block 
					onClick={()=>nextDashboard()}
				>
				    Ir al panel
				</Button>
		    </Col>
		</Row>
  );
}

export default Compaign;