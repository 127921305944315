import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { addInteraccion } from '../../../../actions/interaccionAction'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

const FormInteraccion = ({ 
	title, nameLoginValue, descriptionValue, value, index, 
	type, toast, readOnlyInput, readOnlyInputName, initial,
	listNameInteraction, listOptions
}) => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors, reset } = useForm()
	const [ loading, setLoading ] = useState(false)
	const [ visibleAttribute, setVisibleAttribute ] = useState(false)

	const onSubmit =  async (data, e) => {
		e.preventDefault()
		setLoading(true)

		const information = { 
			name: data.name[index],
			class_action: data.class_action[index],
			description: data.description[index],
			type,
			id: initial,
			type_attribute: data.type_attribute[index],
			value_attribute: data?.value_attribute ? data?.value_attribute[index] : ''
		}
		let type_action = typeof initial === 'undefined' ? "add" : "edit"
		const response = await dispatch(addInteraccion(information, type_action))
		if(response.err===null){
			if(type==='globals'){
				reset({ name:'', class_action:'', description: ''})
				setVisibleAttribute(false)
			}
			toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}else{
			toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
		}
		setLoading(false)

	}

	const verifyTypeAttribute = (e) => {
		if(e.target.value==='data'){
			setVisibleAttribute(true)
		}else{
			setVisibleAttribute(false)
		}
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className="form__container">
			<div className="col-10">
				<div className="form-row">
				    <div className="form-group col-md-3 form-interaccion">
				    	<label htmlFor={`name_login${index}`}>Nombre</label>
				    	 <select 
					    	name={`name[${index}]`} 
					    	id={`name_login${index}`} 
					     	ref={register({required: {value: true, message: 'Requerido'}})}
					     	className={`form-control ${readOnlyInputName ? 'input--readonly' : ''}`}
					    	disabled={readOnlyInputName}
					     >
						    <option value="">Seleccione</option>
						    {
					    		listNameInteraction.length>0 && 
					    		listNameInteraction.map((item, key) => 
					    			<option key={key} value={item.name}>{item.name}</option>
					    		)
					    	}
						</select>
					    {errors.name && (
					    	<Form.Text className="text-danger">
					      		{errors?.name?.message}
					    	</Form.Text>
					    )}
				    </div>
				    <div className="form-group col-md-4 form-interaccion">
				      	<label htmlFor={`clase_login${index}`}>Clase</label>
					    <textarea 
					    	name={`class_action[${index}]`} 
					    	id={`clase_login${index}`}
					    	cols="2" 
					    	rows="2"
					    	className="form-control" 
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    	placeholder="Escribe la clase"
					    	defaultValue={value}
					    ></textarea>
					    {errors.clase && (
					    	<Form.Text className="text-danger">
					      		{errors?.clase?.message}
					    	</Form.Text>
					    )}
				    </div>
				    <div className="form-group col-md-5 form-interaccion">
				    	<label htmlFor={`description_login${index}`}>Descripción</label>
					    <textarea 
					    	name={`description[${index}]`}
					    	id={`description_login${index}`}
					    	cols="2" 
					    	rows="2"
					    	className={`form-control ${readOnlyInput ? 'input--readonly' : ''}`}
					    	readOnly={readOnlyInput}
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    	defaultValue={descriptionValue}
					    	placeholder="Escribe la descripción"
					    ></textarea>
					    {errors.description && (
					    	<Form.Text className="text-danger">
					      		{errors?.description?.message}
					    	</Form.Text>
					    )}
				    </div>
				</div>
				<div className="form-row">
				    <div className="form-group col-md-3 form-interaccion">
				    	<label htmlFor={`type_attribute${index}`} >Tipo de Atributo</label>
					    <select 
					    	name={`type_attribute[${index}]`} 
					    	id={`type_attribute${index}`} 
					     	className="form-control"
					     	ref={register({required: {value: true, message: 'Requerido'}})}
					     	onChange={(e)=>verifyTypeAttribute(e)}
					     >
						    <option value="">Seleccione</option>
							{
								listOptions.length>0 &&
								listOptions.map((item, key) => 
								<option key={key} value={item.name}>{item.name}</option>
								)
							}
						</select>
					    {errors.type_attribute && (
					    	<Form.Text className="text-danger">
					      		{errors?.type_attribute?.message}
					    	</Form.Text>
					    )}
				    </div>
				    {
				    	visibleAttribute && (
				    		 <div className="form-group col-md-3 form-interaccion">
						    	<label htmlFor={`value_attribute${index}`}>Valor del Atributo</label>
							    <input 
							    	type="text"
							    	name={`value_attribute[${index}]`}
							    	id={`value_attribute${index}`}
							    	className={`form-control ${readOnlyInput ? 'input--readonly' : ''}`}
							    	readOnly={readOnlyInput}
							    	ref={register}
							    	defaultValue={descriptionValue}
							    	placeholder="Escribe el valor del attributo"
							    />
						    </div>
				    	)
				    }
				   
				</div>
			</div>
			<div className="form-group col-2">
			    <Button 
			    	variant="primary" 
			    	className={`btn-add-interaccion ${value!=="" && 'btn-add-blue'} w-100`} 
			    	type="submit"
			    >
				   {loading ? 'Procesando..' : (value==="" ? 'Agregar' : 'Editar') }
				</Button>
			</div>
		</Form>

	)
}

export default FormInteraccion