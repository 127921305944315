import { Row, Col, Card } from 'react-bootstrap'
import FormInteraccion from './Custom/FormInteraccion'
import Table from './Datatable/Table'


const FormClickGlobal = ({ toast, interaccionsGlobal, isLoadingClick, listNameInteraction, listOptions }) => {


	return (
		<div>
			<Row>
				<Col xs={11} className="mx-auto">
					<p className="mb-4 mt-5 font-interaccion">Clics globales: Aumenta el ranking del producto dentro de la recomendación desde tu tienda</p>
	    		</Col>
			</Row>
			<Row>
				<Col xs={11} className="mx-auto">
			    	<FormInteraccion
						descriptionValue=""
						value=""
						index="0"
						type="globals"
						toast={toast}
						readOnlyInput={false}
						listNameInteraction={listNameInteraction}
						listOptions={listOptions}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={11} className="mx-auto">
					<Card body className="mb-5 pt-2 pl-0 mt-5 pr-0 panel-product">
			      		<Table data={interaccionsGlobal} isCargando={isLoadingClick} />
			      	</Card>
	    		</Col>
			</Row>
    	</div>
	)
}

export default FormClickGlobal