import API from '../config/api'
import {
	LIST_CATEGORIES
} from '../types/categoriesType'
import { verifyAccessToken } from '../utils/aws-cognito'

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const searchListCategories = () => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

	  	let response = await API.get(`category/${tenant_id}`, session?.token)

	  	const { data, err, msg } = response.data
		    
		if(err===null){
	        dispatch(requestSuccess(LIST_CATEGORIES, data.categories))
		}

		return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}