import React from 'react'
import CheckDelete from './CheckDelete'


export const ColumnProduct  = (hideDelete) => {

  	const columns = React.useMemo(() => [
    	{
			name: '',
			sortable: false,
			center: true,
			cell: row =>  <CheckDelete {...row} />,
			width:'7rem',
			omit: hideDelete,
		},
		{
			name: 'Imagen',
			selector: 'attributes.product_image',
			sortable: true,
			width:'7rem',
			cell: row =>  <>
				{row.attributes?.product_image && (
					<img className="img-foto mr-2"  src={row.attributes?.product_image} alt="Foto del Producto" />
				)}
			  </>
		},
		{
			name: 'SKU',
			selector: 'sku',
			sortable: true,
			width:'7rem'
		},
		{
			name: 'Nombre',
			selector: 'product_name',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Descripción',
			selector: 'attributes.description_product',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Url',
			selector: 'attributes.url',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Categoría',
			selector: 'attributes.categories',
			sortable: true,
			width:'10rem',
			cell: row => row.attributes?.categories
		},
		{
			name: 'Subcategoría',
			selector: 'attributes.subcategories',
			sortable: true,
			width:'10rem',
			cell: row => row.attributes?.subcategories
		}
  ], [hideDelete])

  	return {
  		columns
  	}
}