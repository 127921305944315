import { useState, useEffect } from 'react'
import { Row, Col, Card, Button, Form } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import Table from '../../Components/Configuration/Product/Table'
import Forms from '../../Components/Dashboard/Products/Forms'

import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from '../../actions/productAction'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

const PreviewProduct = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const { list_product_preview, is_loading  } = useSelector((state) => state.product)
	const [show, setShow] = useState(false)


	const handleSubmit =  async (e) => {
		e.preventDefault()
		setLoading(true)
		
		const response = await dispatch(addProduct())

		setLoading(false)
		if(response.err===null){
			history.push('/configure/widget/type')
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleClose = () => setShow(false)

	const handleAlert = (event) => {
		event.preventDefault()

		Swal.fire({
		  title: '¿Seguro quieres regresar?',
		  text: 'Sí regresa tendrá que volver a subir el archivo con los productos',
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
			history.push('/configure/product')		    
		  }
		})
	}

	useEffect(() => {
		if(list_product_preview.length===0){
			history.push('/configure/product')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps	
	}, [list_product_preview])

	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
  	  			titleMenu="Carga de productos" 
  	  			tab="1" 
  	  			title="Previsualizar productos recomendados" 
  	  			subtitle="Listado de los productos que quieres comenzar a recomendar" 
  	  			isVisible={true}
  	  			url="/configure/product"
  	  			isClass="col-lg-10 col-md-10 col-12 mx-auto"
  	  			isActiveLink={false} 
  	  			handleAlert={handleAlert}
		  	>
		  		<Form onSubmit={(event)=>handleSubmit(event)}>
		  			<Row>
					    <Col xs={12} className="mb-5">
					      	<Card body className="mb-5 pt-2 pl-0 pr-0 panel-product">
					      		<Table data={list_product_preview} isCargando={is_loading} toast={toast} setShow={setShow} />
					      	</Card>
					    </Col>
					    <Col className="col-xs-12 col-md-6 mx-auto">
					    	<Button variant="primary" className="btn-continuar mb-5 btn-panel-config" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' }
							</Button>
					    </Col>
					</Row>
		  		</Form>
		  		{show && <Forms show={show} handleClose={handleClose} toast={toast} setShow={setShow} typeEdit={false} />}
		  	</MenuStep>
	  	</Layout>
  );
}

export default PreviewProduct;