import { useState, useEffect } from 'react'

const PanelPosition = ({ register, value, setValue }) => {

   const [titlePosition, setTitlePosition ] = useState("Derecha inferior")

   const changePosition = (event) => {
      const { name } =event.target.dataset
      setTitlePosition(name)
    }

    useEffect(() => {
      
      setTitlePosition(value?.title)
      setValue('widget_position', value?.position)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


  	return (
        <div className="d-flex flex-column flex-sm-row justify-content-between preferencia-details">
            <p>{titlePosition}</p>
        		<div className="pantalla-position pb-3">
              <div className="pantalla--item d-flex justify-content-between pt-2 pb-3">
                <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_izq_sup" 
                data-name="Izquierda superior" 
                value="position_left_higher"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
              <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_cen_sup" 
                data-name="Central superior" 
                value="position_center_higher"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
                <input 
                className="item"
                data-item="der_sup"
                type="radio" 
                name="widget_position" 
                id="position_der_sup" 
                data-name="Derecha superior" 
                value="position_right_higher"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
              </div>
              <div className="pantalla--item d-flex justify-content-between pt-2 pb-3">
                <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_cen_izq" 
                data-name="Central izquierdo" 
                value="position_left_center"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
                <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_cen_der" 
                data-name="Central derecha" 
                value="position_right_center"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
              </div>
              <div className="pantalla--item d-flex justify-content-between pt-2 pb-3">
                <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_izq_inf" 
                data-name="Izquierda inferior" 
                value="position_left_lower"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
                <input 
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_cen_inf" 
                data-name="Central inferior" 
                value="position_center_lower"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
              />
                <input                        
                className="item"
                type="radio" 
                name="widget_position" 
                id="position_der_inf" 
                data-name="Derecha inferior" 
                value="position_right_lower"
                onChange={(event) => changePosition(event)}
                ref={register({required: {value: true, message: 'Requerido'}})} 
                defaultChecked
              />
              </div>
            </div>
        </div>
  	);
}

export default PanelPosition;