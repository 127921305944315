import { FaPlus } from 'react-icons/fa'

import { initialValueFormProduct } from '../../../actions/productAction'
import { useDispatch } from 'react-redux'

const ButtonAdd = ({ setShow }) => {

	const dispatch = useDispatch()

	const handleShow = () => {
		dispatch(initialValueFormProduct({}))
		setShow(true)
	}


	return (
		<button type="button" className="btn btn-warning text-white btn-add-product-dash" onClick={()=>handleShow()}>
			 <FaPlus />
    	</button>
	)
}

export default ButtonAdd