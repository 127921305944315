import API from '../config/api'
import { verifyAccessToken } from '../utils/aws-cognito'
import { 
	LIST_INTERACCION,
	ADD_INTERACCION,
	INITIAL_LOADING,
	DELETE_INTERACCION,
	SELECTED_INTERACCION,
	EDIT_INTERACCION
} from "../types/interaccionType"
import { getListValidate } from '../utils/validation-interaccion'
import { v4 as uuidv4 } from 'uuid'

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const getListInteraccion = () => async (dispatch, getState) => {
	try{

		const { tenant_id } = getState().tenant

		dispatch(requestSuccess(INITIAL_LOADING, true))
		
		const session = await verifyAccessToken()

	  	let response = await API.get(`interaction-config/${tenant_id}`, session?.token)

	  	let { data, err, msg } = response.data

	  	if(err===null){

			const listNameInteraction = [
				{ name: "LO - Iniciar sesión" },
				{ name: "LO - Email Iniciar sesión" },
				{ name: "LOG - Cerrar Sesión" },
				{ name: "VP - Ver Producto" },
				{ name: "VP - SKU" },
				{ name: "ADP - Agregar al carrito" },
				{ name: "ADP - SKU" },
				{ name: "BUY - Tabla Producto" },
				{ name: "BUY - Compra Exitosa URL" }
			]

			const listOptions = [
				{ name: "value" },
				{ name: "src" },
				{ name: "href" },
				{ name: "data" },
				{ name: "text" },
				{ name: "action" },
				{ name: "url" },
			]

			const payload = { 
				isLoading: false, 
				listado: data.map(item=> {
					return {
						...item,
						name: item.name ? item.name.trim() : ""
					}
				}),
				listNameInteraction,
				listOptions
			}

			dispatch(requestSuccess(LIST_INTERACCION, payload))
		}

		return { err, msg }


    }catch(error){
      return {err: false, msg:error}
    }
}

export const addInteraccion = (body, type_action) => async (dispatch, getState) => {
	try{
		const { listInteraccions, interactionSelected } = getState().interaccion
		const { tenant_id } = getState().tenant

	  	const session = await verifyAccessToken()
	  	let payload = {}
	  	
		let response = ''

		if(type_action==="add"){
			const information = {
				class_action: body.class_action,
				description: body.description,
				name: body.name,
				tenant_id,
				type: body.type,
				type_attribute: body.type_attribute,
				value_attribute: body.value_attribute ? body.value_attribute : ""
			}

			response = await API.post("interaction-config/", information, session?.token)
		}else{
			const informationEdit = {
				class_action: body.class_action,
				description: body.description,
				name: interactionSelected.name,
				tenant_id: interactionSelected.tenant_id,
				type: interactionSelected.type,
				type_attribute: body.type_attribute,
				value_attribute: body.value_attribute ? body.value_attribute : ""
			}
			
			response = await API.put(`interaction-config/${interactionSelected.id}`, informationEdit, session?.token)
		}

	  	let { data, err, msg } = response.data

	  	let message = (type_action!=="add") ? "Se ha editado con éxito" : "Se ha agregado con éxito"

	  	if(err===null){
	  		
	  		let interacciones = listInteraccions
	  		let listado = []

	  		if(type_action==="edit"){

		  		let indexInicio = interacciones.findIndex(item => item.id===data.id)
		  		interacciones[indexInicio]= data
		  		listado = interacciones
				  
				payload = { listado, visible: false, selected: {} }
				dispatch(requestSuccess(EDIT_INTERACCION, payload))
		  	}else{
		  		listado = [...interacciones, data]
				
				payload = { listado }
			 	dispatch(requestSuccess(ADD_INTERACCION, payload))
		  	}

		}else {
			message = msg
		}

		return { err, msg: message }

    }catch(error){
      return {err: false, msg:error}
    }
}

export const deleteInteraccion = (id) => async (dispatch, getState) => {
	try{
		const { listInteraccions } = getState().interaccion

		const session = await verifyAccessToken()

	  	let response = await API.delete(`interaction-config/${id}`, session?.token)

	  	let { err, msg } = response.data

	  	if(err===null){

	  		const listado = listInteraccions.filter(click => click.id!==id)

			const payload = { listado }

			dispatch(requestSuccess(DELETE_INTERACCION, payload))

			msg="Se ha eliminado con éxito"
		}

		return { err, msg }	

    }catch(error){
      return {err: false, msg:error}
    }
}

export const getValidationFileInteration = (list_interaccions) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		const { userAdmin } = getState().userAdmin

		if(tenant_id){

			const isValid = getListValidate(list_interaccions)

			if(isValid.err === null){

				let interaccions = list_interaccions.map(item => {
					return {
			  			tenant_id,
			  			product_id: item.product_id,
			  			event_type: getIdEventType(item.event_type),
			  			is_batch: true,
			  			session_id: uuidv4(),
			  			external_id: userAdmin.external_id
			  		}
			  	})

		        return { err: null, msg: "El archivo ha sido procesado", data: interaccions }
			}

			return isValid
		}

		return { err: true, msg: 'Debes crear una cuenta de usuario con el nombre de la organización'}
	  	
    }catch(error){
      return {err: true, msg:error}
    }
}

const getIdEventType = (nameEvent) => {
	switch (nameEvent){
		case 'add_to_cart':
			return 2
		case 'sign_out':
			return 1
		case 'sign_in':
			return 0
		default:
			return null
	}
}

export const addListInteraccions = (listado) => async (dispatch, getState) => {
	try{
		//const { listInteraccions } = getState().interaccion

		if(listado.length>0){
			const session = await verifyAccessToken()

		  	let response = await API.post('event/createbatch', listado, session?.token)

	  		let { err, msg } = response.data

		  	if(err === null){
		  		//const listado = [...listInteraccions, ...items]

				//const interaccionsGlobal = listado.filter(item => item.type==='globals')

				//const payload = { listado, interaccionsGlobal }

		  		//dispatch(requestSuccess(ADD_FILE_INTERACCIONS, payload))
		  		msg = "Se ha agregado con éxito"
		  	}

	        return { err, msg }
		}

		return { err: true, msg: "Debe ingresar las interacciones"}
		
    }catch(error){
      return {err: true, msg:error}
    }
}

export const getDataInteraction =  (data, visible) => async (dispatch, getState) => {
	const payload = { selected: data,	visible	}

	dispatch(requestSuccess(SELECTED_INTERACCION, payload))
}