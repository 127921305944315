import { useEffect } from 'react'
import { Form } from 'react-bootstrap'


const BackgroundColor = ({ register, errors, color, setValue, is_class, name }) => {

	const changeColor = (event) => {
		if(name==="background_color"){
			setColorTypeIcon(event.target.value)
		}
	}

	const setColorTypeIcon = (colors) => {
		Array.from(document.getElementsByClassName(is_class)).forEach(function(item) {
		   item.style.backgroundColor=colors
		})
	}

	useEffect(() => {
		if(color!==""){
			setValue(name, color)
			if(name==="background_color"){
				setColorTypeIcon(color)	
			}
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [color])

	return (
		<div className="d-flex justify-content-md-around align-items-center flex-column flex-md-row">
  			<div className="container-color mr-0 mr-md-5 mb-2">
				<input 
	      			type="color" 
	      			id={name}  
	      			name={name}
	      			className="form-control form-control-color" 
	      			title="Choose your color"
	      			//defaultValue={color}
	      			ref={register({required: {value: true, message: 'Requerido'}})}
	      			onChange={(event)=>changeColor(event)}
	      		/>
			    {errors.background_color && (
			    	<Form.Text className="text-danger">
			      		{errors?.background_color?.message}
			    	</Form.Text>
			    )}   
  			</div>
  		</div>
  );
}

export default BackgroundColor;