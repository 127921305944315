import { Row, Col } from 'react-bootstrap'
import ModalProduct from '../Products/ModalProduct'
import TextScript from '../../Template/Script/TextScript'

const Script = ({ show, handleClose, setShow }) => {



	return (
		<ModalProduct show={show} handleClose={handleClose} titleModal="Script">
	      	<Row>
			    <Col xs={12} className="mb-5">
			      	<div className="pl-2 pl-2">
			      		<p className="mb-4 ml-3 font-16">Copia el script en tu sitio web y comienza a recomendar</p>
				    	<TextScript />
			      	</div>
			    </Col>
			</Row>
		</ModalProduct>
	   
	)
}

export default Script