import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GraficMetric from "./GraficMetric"
const CardMetric = ({ price, title, classNew, visiblePorcent, visibleText, visibleGrafic, 
    classIcon, iconGrafic, textTooltip, dataGraficLineal }) => {
    
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {textTooltip}
      </Tooltip>
    );

    return (
        <section className={`dashboard-card card ${classNew}`}>
            <div className="dashboard-card__body card-body">
                               
                <div className="dashboard-details">
                    {
                        textTooltip!=="" ? (
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <p className="dashboard-details__title">{title}</p>
                            </OverlayTrigger>
                        ) : (
                            <p className="dashboard-details__title">{title}</p>
                        )
                    }       
                    {
                        visibleGrafic ? (
                            <div className="dashboard-grafic">
                                <GraficMetric dataGraficLineal={dataGraficLineal} />
                            </div>
                        ) : (
                            <p className="dashboard-details__subtitle">

                            <span className={classIcon}></span>{price}
                        </p>
                        )
                    }              
                    
                    {
                        visibleText && (
                            <p className="dashboard-details__small">Semana pasada</p>
                        )
                    }
                </div>               
            </div>
        </section>
    )
}

export default CardMetric