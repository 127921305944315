export const getListValidate = (list_products) => {

	let product_error = {err: null, msg:''}

	product_error= validateHeader(Object.keys(list_products[0]))

	if(product_error.err!==null) return product_error

	if(list_products.length>1000) return {err: false, msg:'El máximo de línea por archivo es de 300'}

	for (let clave in list_products){
	  	product_error = validateRow(list_products[clave])
	}

	return product_error
}

export const validateRow = (product) => {

	let product_error = {err: null, msg:''}

	for(const [key, value] of Object.entries(product)){

  		product_error = validarString(value)
  		if(product_error.err!==null) return product_error

		if(key==='product_image' || key==='url'){
			product_error = isUrlApi(value.trim())
			if(product_error.err!==null) return product_error
		}
	}

	return product_error
}

const validarString = (value) => {

	if(value===""){
		return {err: false, msg: 'Favor revisar las columnas que podrían estar vacías.' }
	}

	if(typeof value !== 'number'){

		if(value.indexOf("\r\n")>0){
			return {err: false, msg: 'Favor revisar las columnas que podrían tener saltos de líneas.' }
		}

		let regexp = /\d\.\s+•\s+|[IVX]+\.\s+/g
		if(regexp.test(value)){
			return {err: false, msg: 'Favor revisar las columnas que podrían tener viñetas.' }
		}
	}
	
	return { err: null, msg:'' }
}

/**const isUrl = (url) => {
	let regexp = /^(http:\/\/www\.|https:\/\/www\.|www\.)?[a-z0-9]+([a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/

    if(!regexp.test(url)){
    	return {err: false, msg: 'Url incorrecta, por favor revise las columnas que contengan direccion web (URL o product_image).' }
    }

    return { err: null, msg:'' }
}**/


export const isUrlApi = (url) => {
	let regexp = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi

    if(!regexp.test(url)){
    	return {err: false, msg: 'Url inválida, verifique la url de la api' }
    }

    return { err: null, msg:'' }
}

const validateHeader = (product_header) => {

	const header = ["sku", "categories", "item_name", "product_image", "url", "subcategories", "ecommerceId", "total_sales_before_rocio", "count"]
	let count_valid = 0

	if(product_header.length===header.length){

		for(let x = 0; x < product_header.length; x++){
			if(header.includes(product_header[x])){
				count_valid++
			}
		}

		if(count_valid!==header.length){
			return { err: false, msg: 'Alguno de los nombre del encabezado no coinciden con el mostrado en el formato.'}
		}
		
		return { err: null, msg:'' }
	}else if(product_header.length>header.length){
		return { err: false, msg:'El archivo puede que contenga una columna adicional distinta a la presentada en el formato.' }
	}

	return { err: false, msg:'Posiblemente falte una columna según lo presentado en el formato.' }
	//return { err: null, msg:'' }
}


export const validateDuplicateProduct = (data) => {
	let products = []
	
	for(let key in data){
		const detail = products.find(item => item.product_id===data[key].product_id)
		
		if(!detail){
			products.push({ product_id: data[key].product_id})
		}
	}

	return products
}

export const crearSlug = (slug) => {
 
    // eslint-disable-next-line
    slug = slug.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
 
 	// eslint-disable-next-line
    slug = slug.replace(/^\s+|\s+$/gm, '');
 
    // eslint-disable-next-line
    slug = slug.replace(/\s+/g, '-');
 
    return slug
 
}

export const generateArrayCategory = (sortCategories, countProducts) => {
	let categoriesView = []
	
	categoriesView.push(dataProductArray(sortCategories[0], countProducts))
	categoriesView.push(dataProductArray(sortCategories[1], countProducts))

	let itemsProduct = []
	for(let index in sortCategories){
		if(index!==0 && index!==1 && sortCategories[index]?.items.length>0){

			for(let key in sortCategories[index]?.items){
				
				const product = itemsProduct.find(prod=>prod.id===sortCategories[index]?.items[key].id)
				if(!product){
					itemsProduct = [...itemsProduct, sortCategories[index]?.items[key]]
				}
			}
		}
	}

	const productItem = { title: "Otros", items: itemsProduct, count: itemsProduct.length}
	categoriesView.push(dataProductArray(productItem, countProducts))

	return categoriesView
}

const dataProductArray = (product, countProducts) => {
	let dataSelling = countProducts - product.count
	let dataNotSelling = countProducts - dataSelling

	return {...product, data: [ dataSelling, dataNotSelling] }
}

export const validateDuplicateProductDate = (data) => {
	let products = []
	
	for(let key in data){
		const detail = products.find(item => item.product_id===data[key].product_id)
		
		if(!detail){
			products.push({ product_id: data[key].product_id, month: data[key].month })
		}
	}

	return products
}

export const validateProductClic = (listProductAddCart, listProductSuccessful) => {

	let productSuccess = []

	for(let index in listProductSuccessful){
		const existe = listProductAddCart.find(item => item.id===listProductSuccessful[index].id)
		if(existe){
			productSuccess.push(listProductSuccessful[index])
		}
	}

	return productSuccess
}