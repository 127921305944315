import { 
	GENERATE_TOKEN
} from "../types/tokenType"

const INITIAL_STATE = {
	token_type: '',
	access_token:  '',
	expire_in:  0
}

const tokensReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case GENERATE_TOKEN:
			return {
				...state,
				token_type: action.payload.token_type,
				access_token: action.payload.access_token,
				expire_in: action.payload.expire_in
			}
		default: return state
	}
}

export default tokensReducer