import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes/App';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.css'
import './assets/css/configuration.css'

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducers from "./reducers/"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

const store = createStore(
	rootReducers,
	{},
	composeWithDevTools(applyMiddleware(thunk))
)


ReactDOM.render(
  <Provider store={ store }>
    		<App />
  	</Provider>,
  document.getElementById('root')
);