import {  
	Spinner
} from 'react-bootstrap'

const Progress = () => (
	  	<div>
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		  <Spinner animation="grow" variant="dark" />
		</div>
);
export default Progress