import { Row, Col, Button } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import upload from '../../assets/images/cloud.svg'

import { useHistory } from 'react-router-dom'

const Success = () => {

	const history = useHistory()


	const openDashboard = (event) => {
		event.preventDefault()
		history.push('/dashboard')
        //window.location.reload()
	}

	return (
		<Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Carga de productos" 
  	  			tab="4" 
  	  			title="Tu widget ha sido configurado satisfactoriamente" 
  	  			subtitle="Solo debes copiar el script en tu sitio web y todo estará listo" 
  	  			isVisible={false}
  	  			url="/login"
  	  			isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
		  	>
		  		<Row className="mb-5">
				    <Col md={12} xs={12} className="mb-5 text-center mt-5">
				      	<img alt="Exito" src={upload} className="img-fluid" />
				    </Col>
				    

				    <Col md={12} xs={12} className="mb-5 mt-5">
				    	<Button variant="primary" className="btn-continuar btn-panel-config" type="button" block onClick={(event)=>openDashboard(event)}>
						    OK
						</Button>
				    </Col>
				</Row>
		  	</MenuStep>
	  	</Layout>
  );
}

export default Success;