import { useSelector } from 'react-redux'
import { Col } from 'react-bootstrap'
import Clipboard from 'react-clipboard.js'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const url_widget_prod = process.env.REACT_APP_WIDGET_URL

const TextScript = ({ nextPage }) => {

    const { widget,  tenant } = useSelector((state) => {
      return {
        widget: state.widget,
        tenant: state.tenant
      }
    })
    const { selected_widget  } = widget
    const { tenant_data  } = tenant

    const copyText = () => {
      let textToCopy = document.getElementById("textToCopy").textContent
      return textToCopy
    }

    const onSuccess = () => {
      toast.success("Copiado",{position: toast.POSITION.TOP_RIGHT})
    }

  	return (
      <>
        <Col md={12} xs={12} className="mb-4">
              <div className="cuadro-script" id="textToCopy">
                <p className="mb-0">{`<div class="widget">`}</p>
                <p className="mb-0">{`<script src="${url_widget_prod}/Configuration.js" type="text/javascript"></script>`}</p>
                <p className="mb-0">{`<script type="text/javascript">`}</p>


                <p className="mb-0">{`const log_user = {`}</p>
                <p className="mb-0">{`log_user_action: '.submit_button',`}</p>
                <p className="mb-0">{`log_email_user: {name: '#username',attribute: 'value'}};`}</p>

                <p className="mb-0">{`const config_widget = {`}</p>
                <p className="mb-0">{`widget_id: '${selected_widget.id}', tenant_id: '${tenant_data.id}', type: '${selected_widget?.configuration?.type_action}'  };`}</p>

                <p className="mb-0">{`Configuration.init(log_user, config_widget)`}</p>

                <p className="mb-0">{`</script>`}</p>
                <p className="mb-0">{`<script src="${url_widget_prod}/Actions.js" type="text/javascript"></script>`}</p>
                <p className="mb-0">{`<script src="${url_widget_prod}/Widget.js" type="text/javascript"></script>`}</p>
                <p className="mb-0">{`</div>`}</p>
              </div>
              <Clipboard option-text={copyText} onSuccess={onSuccess} className="mt-3 btn-script text-script btn btn-outline-primary">
                Copiar script
              </Clipboard>
        </Col>
      </>
  	);
}

export default TextScript;
