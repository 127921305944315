import { useState, useMemo } from 'react'
import DataTable from 'react-data-table-component'

import Filter from './Filter'
import { ColumnProduct } from './ColumnProduct'
import Progress from '../../Progress'

import { initialValueFormProduct } from '../../../../actions/productAction'
import { useDispatch } from 'react-redux'


const Table = ({data, isCargando, toast, setShow, tenant_data }) => {

	
	const dispatch = useDispatch()
	
	const [ hidenDelete, setHideDelete ] = useState(true);
	const { columns } = ColumnProduct(hidenDelete)

	const paginationOptions = {
		rowsPerPageText: 'Filas por Página',
		rangeSeparatorText:'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	}

	

	const [filterText, setFilterText] = useState('')
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
	const filteredItems = data.filter(item => {
		return item.product_name.toLowerCase().includes(filterText.toLowerCase())
				|| (item.attributes?.description_item && item.attributes.description_item.toLowerCase().includes(filterText.toLowerCase()))
				|| (item.sku && item.sku.toString().toLowerCase().includes(filterText.toLowerCase()))
				|| (item.attributes?.categories && item.attributes.categories.toString().toLowerCase().includes(filterText.toLowerCase()))
	})

	

  	const subHeaderComponentMemo = useMemo(() => {
    	const handleClear = () => {
	      	if (filterText) {
	        	setResetPaginationToggle(!resetPaginationToggle);
	        	setFilterText('');
	      	}
	    }

		return <Filter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText}  toast={toast} setHideDelete={setHideDelete} hidenDelete={hidenDelete} />;
	}, [filterText, resetPaginationToggle, toast, setHideDelete, hidenDelete])

  	const handleRowClicked = row => {

		if(tenant_data?.personalize_data?.dataset_item_id!=="" && tenant_data?.personalize_data?.dataset_item_id!==null){
			dispatch(initialValueFormProduct(row))
			setShow(true)
		}else{
			toast.error("No se puede editar porque no hay campaña asignada", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	return (
		<div className="table-product-dashboard">
			<DataTable 
				columns={columns}
				data={filteredItems}
			    pagination
			    paginationResetDefaultPage={resetPaginationToggle}
			    subHeader
			    paginationComponentOptions={paginationOptions}
      			subHeaderComponent={subHeaderComponentMemo}
      			persistTableHead
      			dense
      			progressPending={isCargando}
      			progressComponent={<Progress />}
				noDataComponent={<div>No hay datos registros disponible.</div>}
          		selectableRowsHighlight={true}
          		onRowClicked={handleRowClicked}
			/>

		</div>
	)
}

export default Table