import { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import search from '../../../assets/images/search.svg'
import { useDispatch, useSelector } from 'react-redux'
import { deselectedCheckedProduct, deleteProducts } from '../../../actions/productAction'
import Swal from 'sweetalert2'

const Filter = ({ filterText, onFilter, onClear, hidenDelete, setHideDelete, toast }) => {

	const dispatch = useDispatch()
	const { items_delete  } = useSelector((state) => state.product)
	const [ loadingDelete, setLoadingDelete ] = useState(false)

	const hiddenBtn = () => {
		dispatch(deselectedCheckedProduct())
		setHideDelete(!hidenDelete)
	}

	const selectedChecked = async () => {
		setLoadingDelete(true)
		Swal.fire({
		  title: '¿Desea eliminar los productos seleccionados',
		  text: 'Recuerde que estos productos se eliminarán de la lista',
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
			if(items_delete.length===0){
				toast.error('Debe seleccionar una fila de la tabla', {position: toast.POSITION.TOP_RIGHT})
			}else{
				
				const response = await dispatch(deleteProducts(items_delete))
				setLoadingDelete(false)
				if(response.err===null){
					toast.success(response.msg,{position: toast.POSITION.TOP_RIGHT})
					setHideDelete(!hidenDelete)
				}else{
					toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT})
				}
			}			    
		  }
		})
		setLoadingDelete(false)
	}

	return (
		<>
		  <Row className="w-100 pb-3">
		  		<Col lg={6} md={6} sm={6} xs={12} className="pl-0">
		  			<h5 className="title-h5">Productos cargados</h5>
		  		</Col>
				<Col lg={6} md={6} sm={6} xs={12}>
			      	<Form.Group >
			      		<Form.Control 
						    	id="search" 
						    	type="text" 
						    	placeholder="Buscar productos aquí…"
						    	aria-label="Buscar productos aquí…"
						    	value={filterText} 
						    	onChange={onFilter}
						    	className="filter-product pl-4"
						    	autoComplete="off"
						    />
						    <div><img src={search} alt="Búsqueda" className="pl-2 pt-2" /></div>
			    	</Form.Group>
			    </Col>
			</Row>
			<Row className="w-100">
				<Col xs={12} className="pl-0 mb-2 d-flex align-items-center">
					<div className="form-group mr-5">
					    <Button variant="primary" className="h-25 btn-sm" onClick={() => hiddenBtn()}>
					    	{ hidenDelete ? 'Selección múltiple' : 'Ocultar selección'}
					    </Button>
					  	{
							Object.keys(items_delete).length>0 && (
								<Button type="button" 
									variant="primary"
									className="ml-3 h-25 btn-sm"
									 onClick={()=>selectedChecked()}
									 disabled={loadingDelete}
								>Eliminar</Button>
							)
						}	
					</div>						
				</Col>
			</Row>
		</>
	)
}

export default Filter