import { combineReducers } from 'redux'
import widget from './widgetReducer'
import userAdmin from './userAdminReducer'
import product from './productReducer'
import tenant from './tenantReducer'
import tokens from './tokensReducer'
import categories from './categoriesReducer'
import interaccion from './interaccionReducer'

export default combineReducers({
	widget,
	userAdmin,
	product,
	tenant,
	tokens,
	categories,
	interaccion
})