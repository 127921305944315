import BtnDelete from './BtnDelete'

const ColumnClick = { 
	columnas: [
		{
			name: 'Nombre',
			selector: 'name',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Clase',
			selector: 'class_action',
			sortable: true,
			width:'20rem'
		},
		{
			name: 'Descripción',
			selector: 'description',
			sortable: true,
			width:'25rem'
		},
		{
			name: 'Tipo de Atributo',
			selector: 'type_attribute',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Valor del Atributo',
			selector: 'value_attribute',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Eliminar',
			selector: 'eliminar',
			sortable: false,
			cell: row => <BtnDelete {...row} />,
			width:'8rem'
		}
	]
}

export default ColumnClick