import { useState } from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { sendEmail } from '../../../actions/emailAction'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import DocumentLegal from '../../../Components/Configuration/DocumentLegal'

const Formulario = ({ setPage, setMessage }) => {
	
	const dispatch = useDispatch()
	const { register, handleSubmit, errors } = useForm()
	const [ loading, setLoading ] = useState(false)
	const [show, setShow] = useState(false)
	const [ title, setTitle ] = useState("")
	const [ url, setUrl ] = useState("")

	const onSubmit =  async (data, e) => {
		e.preventDefault()

		setLoading(true)

		/**let check = document.querySelector('#terminos_condicion')
		if(!check.checked){
			toast.error("Debe aceptar los términos y condiciones",{position: toast.POSITION.TOP_RIGHT})
			setLoading(false)
			return false
		}**/
		
		let response = await dispatch(sendEmail(data))

		setLoading(false)
		
		if(response.err===null){
			setMessage(response.msg)
			setPage(2)
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleClose = () => setShow(false)

	const viewTerminos = (type, event) => {
		event.preventDefault()
		setTitle((type==="terminos") ? "Términos y condiciones" : "Políticas de privacidad")
        setUrl("https://www.rocio.ai/docs/TC-Rocio.pdf")
        setShow(true)
	}

	return (
    	<>
    		<Row>
    			<Col xs={12} className="text-right">
    			    <Link to={'/'} className="link-volver-demo text-decoration-none"><span className="mr-1"></span>Volver</Link>
        	  </Col>
    		</Row>
    		<Row>
    			<Card body className="col-12 pl-md-5 mt-3 pr-md-5 card-demo">
	            <Row className="panel-form mb-3">
	              <Col xs={12}>
	                <p className="panel-body">Completa el formulario para que podamos contactarte y responder tus dudas de manera más eficiente.</p>
	              </Col>
	            </Row>
	            <Form  onSubmit={handleSubmit(onSubmit)}>
	            	<Row>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="name"
					      			autoFocus
					      			id="name"  
					      			className="input" 
					      			placeholder="Nombre*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.name && (
							    	<Form.Text className="text-danger">
							      		{errors?.name?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="email" 
					      			name="email"
					      			id="email"  
					      			className="input" 
					      			placeholder="Correo*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.email && (
							    	<Form.Text className="text-danger">
							      		{errors?.email?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="phoneNumber"
					      			id="phoneNumber"  
					      			className="input" 
					      			placeholder="Número de contacto*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.phoneNumber && (
							    	<Form.Text className="text-danger">
							      		{errors?.phoneNumber?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="businessName"
					      			id="businessName"  
					      			className="input" 
					      			placeholder="Nombre empresa*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.businessName && (
							    	<Form.Text className="text-danger">
							      		{errors?.businessName?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="siteUrl"
					      			id="siteUrl"  
					      			className="input" 
					      			placeholder="Sitio web*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.siteUrl && (
							    	<Form.Text className="text-danger">
							      		{errors?.siteUrl?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<textarea 
					      			type="text" 
					      			name="message" 
					      			id="message" 
					      			className="textarea input--box-shadow" 
					      			rows="3" 
					      			cols="3"
					      			placeholder="Mensaje*"
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		{errors.message && (
							    	<Form.Text className="text-danger">
							      		{errors?.message?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col md={12} xs={12}>
					    	<Button variant="primary" type="submit" block disabled={loading}>
							    { loading ? 'Enviando...' : 'Enviar'} 
							</Button>
					    </Col>
					    <Col md={12} xs={12} className="mb-3 mt-4">
							  <label className="form-check-label font-label-demo" htmlFor="terminos_condicion">
					    		Ver  
					    		<a className="ml-1 btn-link input-link mr-1" href="!#" onClick={(event)=>viewTerminos("terminos", event)}>
					    			términos y condiciones
					    		</a> 
						    </label>
					    </Col>
	            	</Row>
	           	</Form>
	          </Card>
	        </Row>
	        {show && <DocumentLegal show={show} handleClose={handleClose} setShow={setShow} title={title} url={url} />}
	        <ToastContainer />
    	</>
	);
}

export default Formulario;
