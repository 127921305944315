import API from '../config/api'
import {
	CREATE_WIDGET,
	UPDATE_WIDGET,
	TYPE_WIDGET,
	LIST_WIDGET_ALL,
	SELECTED_WIDGET,
	SEARCH_WIDGET,
	UPDATE_CATEGORIA_WIDGET
} from '../types/widgetType'
import { verifyAccessToken } from '../utils/aws-cognito'


export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const addConfigWiget = (body, type) => async (dispatch, getState) => {
	try{
		const { tenant_id } = getState().tenant
		const { list_widgets } = getState().widget

		const data_widget = {
			tenant_id,
			configuration: {
				widget_position: type==="fixed" ? {} : namePosition(body.widget_position),
				background_color: body.background_color,
				icon_type: body.icon_type,
				url_site_web: body.url_site_web,
				type,
				text_color: type==="fixed" ? body.text_color : '',
				categories: [ body.category ],
				name: body.name,
				buttons: body?.buttons,
				type_action: body?.type_action ? body?.type_action : ""
			}
		}

		const session = await verifyAccessToken()

	  	let response = await API.post("widget/", data_widget, session?.token)

	  	const { data, err } = response.data

	    if(err===null){
	    	const configuration = { ...data_widget, id: data.id	}

	    	const listado = [...list_widgets, data ]

	    	const payload = { configuration, listado }

	        dispatch(requestSuccess(CREATE_WIDGET, payload))
	        localStorage.setItem("config_widget", JSON.stringify(configuration))
		}

      	return { err, msg: err }
    }catch(error){
      return {err: false, msg:error}
    }
}

const namePosition = (name) => {
	const listado = [
		{ position: 'position_right_lower', title: 'Derecha inferior' },
		{ position: 'position_left_lower', title: 'Izquierda inferior' },
		{ position: 'position_center_lower', title: 'Central inferior' },
		{ position: 'position_right_higher', title: 'Derecha superior' },
		{ position: 'position_left_higher', title: 'Izquierda superior' },
		{ position: 'position_center_higher', title: 'Central superior' },
		{ position: 'position_left_center', title: 'Central izquierdo' },
		{ position: 'position_right_center', title: 'Central derecha' }
	]

	const search = listado.find(pos => pos.position === name)
	return search
}

export const editConfigWiget = (body, type, id) => async (dispatch, getState) => {
	try{
		const { selected_widget, list_widgets } = getState().widget

		const data_widget = {
			tenant_id: selected_widget.tenant_id,
			configuration: {
				widget_position:  type==="fixed" ? {} : namePosition(body.widget_position),
				background_color: body.background_color,
				icon_type: body.icon_type,
				url_site_web: body.url_site_web, //body.url_site_web,
				type: type,
				text_color: type==="fixed" ? body.text_color : '',
				categories: [ body.category ],
				name: selected_widget.configuration.name, //body.name
				buttons: body?.buttons,
				type_action: body?.type_action ? body?.type_action : ""
			}
		}
	
		const session = await verifyAccessToken()

	  	let response = await API.put(`widget/${id}`, data_widget, session?.token)

	  	let { err, msg } = response.data

	    if(err===null){
	    	
	    	const configuration = { ...data_widget, id: id }
	    	
    		const listado = list_widgets.map(obj => {
    			if(obj.id===id){
    				return configuration
    			}
    			return obj
    		})

    		const payload = { configuration, listado }
	        dispatch(requestSuccess(UPDATE_WIDGET, payload))
	        //localStorage.setItem("config_widget", JSON.stringify(configuration))
	        msg = "Se ha editado con éxito"
		}else{
			msg = err
		}

      	return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}

export const getConfigWidget = (id)  => async (dispatch, getState) => {
	try{

		const session = await verifyAccessToken()

	  	let response = await API.get(`widget/${id}`, session?.token)
	  	const { data, err, msg } = response.data

		if(err===null){
			 dispatch(requestSuccess(CREATE_WIDGET, data))
			 localStorage.setItem("config_widget", JSON.stringify(data))
		}
	  	
		return { err, msg }
    }catch(error){
      	return {err: false, msg:error}
    }
}

export const getWidgetsByTenant = ()  => async (dispatch, getState) => {
	try{

		const { tenant_id } = getState().tenant

		const session = await verifyAccessToken()

	  	let response = await API.get(`widget/index/${tenant_id}`, session?.token)
	  	
	  	const { data, err, msg } = response.data
	  	
		if(err===null){
			const payload= {
				list: data,
				selected: {}
			}
	
			 dispatch(requestSuccess(LIST_WIDGET_ALL, payload))
		}
	  	
		return { err, msg }
    }catch(error){
      	return {err: false, msg:error}
    }
}

export const getTypeWidget = (type)  => (dispatch, getState) => {
	const { list_widgets } = getState().widget

	if(list_widgets.length>0 && type==="floating"){
		const widget_flotante = list_widgets.filter(wid => wid.configuration.type.includes("floating"))
		if(widget_flotante.length>0){
			return { err: true, msg: "Solo está permitido crear un widget flotante "}
		}
	}

	dispatch(requestSuccess(TYPE_WIDGET, type))
	return { err: null, msg: ""}
}

export const getWidgetById = (id, visible) => (dispatch, getState) => {
	const { list_widgets } = getState().widget
	
	let selected = {}
	if(id!==""){
		selected = list_widgets.find(wid => wid.id===id)
	}
	const payload = { selected, visible }

	dispatch(requestSuccess(SELECTED_WIDGET, payload))
}

export const setInfoWidget = () => (dispatch, getState) => {
	const { config_widget } = getState().widget
	dispatch(requestSuccess(SELECTED_WIDGET, {selected: config_widget, visible: false } ))
}

export const changeCategoryById = (id, category) => async (dispatch, getState) => {
	try{
		let { list_widgets } = getState().widget

		const widget = list_widgets.find(wid => wid.id===id)

		if(widget){

			const data_widget = {
				...widget,
				configuration: {
					...widget.configuration,
					categories: [ category ]
				}
			}

			const session = await verifyAccessToken()

		  	let response = await API.put(`widget/${id}`, data_widget, session?.token)

		  	let { err, msg } = response.data

		    if(err===null){
		    	const index = list_widgets.findIndex(obj => obj.id===id)

				list_widgets[index]= { ...data_widget, id: id }

		        dispatch(requestSuccess(UPDATE_CATEGORIA_WIDGET, list_widgets))
		        msg = "Se ha editado con éxito"
			}else{
				msg = err
			}

			return { err, msg }
		}

		return { err: false, msg: "Error al buscar el widget" }
      	
    }catch(error){
      return {err: false, msg:error}
    }
}

export const searchByName = (name) => (dispatch, getState) => {
	const { list_widgets } = getState().widget
	
	let listado = list_widgets

	if(name!==""){
		listado = list_widgets.filter(wid=>wid.configuration?.name?.toLowerCase()?.includes(name.toLowerCase()))
	}

	const payload = {listado}
	
	dispatch(requestSuccess(SEARCH_WIDGET, payload))
}