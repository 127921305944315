import API from '../config/api'
import { verifyAccessToken } from '../utils/aws-cognito'

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const sendEmail = (body) => async (dispatch, getState) => {
	try{

		const session = await verifyAccessToken()

	  	let response = await API.post("email/demo", body, session?.token)

	  	let { data, err, msg } = response.data

	  	if(Object.keys(data).length>0){
	  		msg = data.result
	  	}

		return { err, msg }
    }catch(error){
      return {err: false, msg:error}
    }
}