import {
    Row,
    Col
} from 'react-bootstrap'
import widget from '../../assets/images/configurar/widget.png'
import favorito from '../../assets/images/configurar/heart.svg'

const Configurar = () => {
    return (
        <Row className="configurar_widget mt-5 w-sm-100">
            <Col xs={12} className="p-5 text-center d-flex flex-column align-items-center justify-content-center">
                <h1>¿Cómo funciona ROCIO?</h1>
                <p>Entrega una experiencia innovadora a tus clientes en tu plataforma web</p>
            </Col>
            <Col xs={12}>
                <div className="section-configure">
                   
                    <div className=" js-scroll slide-left card-funciona-widget mr-md-5">
                       <img src={widget} alt="Widget" />
                    </div>
                    <div className="ml-md-5">
                        <div className=" js-scroll slide-right mt-5 card-configurar p-4 d-flex card-funciona">
                            <div className="mr-3">
                                <img src={favorito} alt="Recomendados" />
                            </div>
                            <div>
                                <h5 className="title-funciona">Recomendados</h5>
                                <p className="subtitle-funciona">Aumenta la posibilidad de conversión de sus clientes con recomendaciones impulsadas por IA. Muestra a tus clientes lo que ellos buscan.</p>
                            </div>
                        </div>
                        <div className=" js-scroll slide-right card-configurar  mt-4 d-flex card-funciona">
                            <div className="mr-3">
                                <img src={favorito} alt="Recomendados" />
                            </div>
                            <div>
                                <h5 className="title-funciona">Productos favoritos</h5>
                                <p className="subtitle-funciona">Selecciona y guarda productos recomendados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Configurar;