import { useState } from 'react'
import { Form } from 'react-bootstrap'
import XLSX from 'xlsx'


const Files = ({ title, errors, register, setValue}) => {

	const [ titleFile, setTitleFile ] = useState('Subir archivo  CVC, XLS')

	const handleFile = (event) => {
		if (!event.target.files || event.target.files.length === 0) {
			setTitleFile('Subir archivo CVC, XLS')
		}else{
			let file = event.target.files[0]
			setTitleFile(file.name)		

			let reader = new FileReader()

	        reader.onload = function (e) {
	            let data = e.target.result
	            data = new Uint8Array(data)

	            let workbook = XLSX.read(data, {type: 'array'})

	            let first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
				let result = XLSX.utils.sheet_to_json(first_worksheet, {header:2})

	            register({ name: "cargas" })
	            setValue("cargas", result)
	        };
	        reader.readAsArrayBuffer(file);
	    }
	}

	return (
		<>
			<p className="input-title mb-1">{title}</p>
			<div className="form-group custom-file">
			    <input 
			    	type="file" 
			    	className="custom-file-input mb-5" 
			    	name="base_producto" 
			    	id="base_producto"
			    	ref={register({required: {value: true, message: 'Requerido'}})}
			    	accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
			    	onChange={(event)=>handleFile(event)} 
			    />
			    <label className="custom-file-label" htmlFor="base_producto" >{titleFile}</label>
			    {errors.base_producto && (
			    	<Form.Text className="text-danger">
			      		{errors?.base_producto?.message}
			    	</Form.Text>
			    )}
			</div>
		</>
  );
}

export default Files;