import { Row, Col, Card } from 'react-bootstrap'
import email from '../../../assets/images/email.svg'
import { Link } from 'react-router-dom'

const Enviado = ({ message }) => {
	

	return (
    	<>
    		<Card body className="pl-md-4 mt-3 pr-md-4 mb-md-4 card-demo">	            
	            <Row className="mt-5">
	            	<Col className="text-center mb-4 mt-5">
	            		<img src={email} alt="E-mail enviado" />
	            	</Col>
	            	<Col xs={12} className="text-center mb-5">
		                <p className="text-email">Tu solicitud ha sido enviada</p>
		                <p className="mb-5 pl-5 pr-5 subtext-email">{message}</p>
		            </Col>
					<Col md={12} xs={12} className="mt-5 mb-5">
				    	<Link className="btn btn-primary btn-block btn-line" to="/" role="button">
						    Ok 
						</Link>
				    </Col>
	            </Row>
	          </Card>
    	</>
	);
}

export default Enviado;
