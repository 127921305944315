import DatePicker from "react-multi-date-picker"

const ItemMetric = ({ title, getDataEvent, children, values }) => {


      const handleMetric = (dateObjects) => {
        getDataEvent(dateObjects)
      }

    return (
        <div className="col-12 mb-5">
            <div className="dashboard-container">
                <div className="dashboard-header">
                    <p className="dashboard-header__title">{title}</p>
                    <DatePicker
                        value={values}
                        range
                        format="DD/MM/YYYY"
                        onChange={dateObjects => handleMetric(dateObjects)}
                    />
                </div>
                <div className="dashboard-body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ItemMetric