import React from 'react'
import CheckDelete from './CheckDelete'
import Toogle from './Toogle'


export const ColumnProduct  = (hideDirector) => {

  	const columns = React.useMemo(() => [
    	{
			name: '',
			sortable: false,
			center: true,
			cell: row =>  <CheckDelete {...row} />,
			width:'2rem',
			omit: hideDirector,
		},
		{
			name: 'Desactivar/Activar',
			selector: 'attributes.active',
			sortable: false,
			width:'7rem',
			center: true,
			cell: row => <Toogle {...row} />
		},
		{
			name: 'Imagen',
			selector: 'attributes.product_image',
			sortable: true,
			width:'7rem',
			cell: row =>  <>
				{row.attributes?.product_image && (
					<img className="img-foto mr-2"  src={row.attributes?.product_image} alt="Foto del Producto" />
				)}
			  </>
		},
		{
			name: 'SKU',
			selector: 'sku',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Nombre',
			selector: 'product_name',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Descripción',
			selector: 'attributes.description_product',
			sortable: true,
			width:'15rem',
			cell: row =>  <>
				{row.attributes?.description_product && (
					<div className="des-prod"><div dangerouslySetInnerHTML={{__html: row.attributes?.description_product}}></div></div>
				)}
			  </>
		},
		{
			name: 'Url',
			selector: 'attributes.url',
			sortable: true,
			width:'15rem'
		},
		{
			name: 'Categoría',
			selector: 'attributes.categories',
			sortable: true,
			width:'10rem',
			cell: row => <div data-tag="allowRowEvents" className="table-categories">{row.attributes?.categories}</div>
		},
		{
			name: 'Subcategoría',
			selector: 'attributes.subcategories',
			sortable: true,
			width:'10rem',
			cell: row => <div data-tag="allowRowEvents" className="table-categories">{row.attributes?.subcategories}</div>
		},
		{
			name: 'Cantidad',
			selector: 'count',
			sortable: true,
			width:'8rem'
		}
  ], [hideDirector])

  	return {
  		columns
  	}
}