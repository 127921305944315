import { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import MenuStep from '../../Components/Template/MenuStep'
import Layout from '../../Components/Template/Layout'
import template from '../../assets/images/xls.svg'

import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'


const TypeInputProduct = () => {

	const history = useHistory()
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit } = useForm()


	const onSubmit =  async (data, e) => {
		e.preventDefault()

        setLoading(true)


        if(data.type_product==="fileExcel"){
            setLoading(false)
            history.push('/configure/product')
        }else{
			setLoading(false)
			history.push('/configure/product/api')
        }
	}

	useEffect(() => {
		document.body.classList.remove('bg-dashboard')
		document.body.classList.add('bg-config')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    return (
        <Layout volver={true} isLogoWhile={true}>
		  	<MenuStep 
		  	  	titleMenu="Cargar productos recomendados" 
  	  			tab="2" 
  	  			title="Cargar productos recomendados" 
  	  			subtitle="" 
  	  			isVisible={true}
  	  			url="/configure/product"
  	  			isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
		  	>
		  		<Form onSubmit={handleSubmit(onSubmit)}>
			  		<Row>
					    <Col md={12} xs={12} className="mb-4">
					      	<Card body className="preferencia p-4">
					      		<p className="mb-4">Seleciona como va ser la carga de productos</p>
					      		<div>
					      			<div className="d-flex justify-content-around">
						      			<div className="form-check align-self-center form-input-product">
										    <input 
										  		className="form-check-input form-check-border" 
										  		type="radio" 
										  		name="type_product" 
										  		id="type_product_1" 
										  		value="fileExcel" 
										  		defaultChecked
										  		ref={register} 
										  	/>
											<label className="form-check-label" htmlFor="type_product_1">
											    Archivo excel
											</label>
										</div>
										<img src={template} alt="Widget Flotante" height="50" width="50"  />
									</div>
					      			<div className="d-flex justify-content-around mt-3">
						      			<div className="form-check align-self-center form-input-product">
										    <input 
										  		className="form-check-input form-check-border" 
										  		type="radio" 
										  		name="type_product" 
										  		id="type_product_0" 
										  		value="fileApi" 
										  		ref={register} 
										  	/>
											<label className="form-check-label" htmlFor="type_product_0">
											    A través de una API
											</label>
										</div>
										<img src={template} alt="Widget Flotante" height="50" width="50" />
									</div>
                                                                      
					      		</div>
					      	</Card>
					    </Col>

					    <Col md={12} xs={12} className="mb-5 mt-5">
					    	<Button variant="primary" className="btn-continuar btn-panel-config mb-5" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' } <span></span>
							</Button>
					    </Col>
					</Row>
				</Form>
		  	</MenuStep>
	  	</Layout>
    )
}

export default TypeInputProduct