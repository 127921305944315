import { 
	LIST_PRODUCT,
	LOADING_PRODUCT,
	DELETE_PRODUCT,
	CHECK_BELL_STATUS_PRODUCT,
	EDIT_PRODUCT,
	DATA_PRODUCT,
	DELETE_PRODUCTS,
	SELECT_DELETE_PRODUCTS,
	ADD_PRODUCT_SINGLE,
	RESET_LIST_PRODUCT,
	DELETE_PRODUCTS_PREVIEW,
	LIST_PRODUCT_PREVIEW,
	ADD_PRODUCT_LIST,
	FILTERED_PRODUCT_DASHBOARD,
	GROUP_BY_CATEGORY_PRODUCT,
	GRAFIC_DATA_DASHBOARD
} from "../types/productType"

const INITIAL_STATE = {
	list: [],
	is_loading: false,
	item: {},
	item_delete_selected: [],
	deleted_option: '',
	check_bell_status: '',
	items_delete: [],
	list_product_preview: [],
	listFilteredProducts: {
		total_cart_generated: {items: [], total: 0},
		total_cart_sold: {items: [], total: 0},
		total_cross_selling: {items: [], total: 0}
	},
	categoriesGroup:[
		{title: "Categoria 1", items: [], data: [100, 0]},
		{title: "Categoria 2", items: [], data: [100, 0]},
		{title: "Otros", items: [], data: [100, 0]}
	],
	dataGraficLinealSell: { label: [], data: []	},
	dataGraficLinealPercentaje: { label: [], data: []	}
}

const productReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_PRODUCT:
			return {
				...state,
				list: action.payload.products,
				//list_product_preview: action.payload.products,
				is_loading: action.is_loading
			}
		case LIST_PRODUCT_PREVIEW:
			return {
				...state,
				//list: action.payload.products,
				list_product_preview: action.payload.products,
				is_loading: action.is_loading
			}
		case LOADING_PRODUCT:
			return {
				...state,
				is_loading: action.payload
			}
		case DELETE_PRODUCT:
			return {
				...state,
				list: action.payload
			}
		case CHECK_BELL_STATUS_PRODUCT:
			return {
				...state,
				check_bell_status: action.payload
			}
		case EDIT_PRODUCT:
			return {
				...state,
				list: action.payload
			}
		case ADD_PRODUCT_LIST:
			return{
				...state,
				list: action.payload
			}
		case DATA_PRODUCT: 
			return {
				...state,
				item: action.payload
			}
		case SELECT_DELETE_PRODUCTS:
			return {
				...state,
				item_delete_selected: action.payload.item_delete_selected, 
				deleted_option: action.payload.option
			}
		case DELETE_PRODUCTS:
			return {
				...state,
				item_delete_selected: action.payload.delete_selected,
				list: action.payload.item
			}
		case ADD_PRODUCT_SINGLE:
			return {
				...state,
				list: action.payload
			}
		case RESET_LIST_PRODUCT:
			return {
				...state,
				list: action.payload
			}
		case DELETE_PRODUCTS_PREVIEW:
			return {
				...state,
				items_delete: action.payload
			}
		case FILTERED_PRODUCT_DASHBOARD:
			return {
				...state,
				listFilteredProducts: action.payload.listFilteredProducts
			}
		case GROUP_BY_CATEGORY_PRODUCT:
			return {
				...state,
				categoriesGroup: action.payload.categoriesView
			}
		case GRAFIC_DATA_DASHBOARD:
			return {
				...state,
				dataGraficLinealSell: action.payload.dataGraficLinealSell,
				dataGraficLinealPercentaje: action.payload.dataGraficLinealPercentaje
			}
		default: return state
	}
}

export default productReducer