import { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
//import terminos from '../../download/terminos.pdf'
//import privacidad from '../../download/privacidad.pdf'
import Panel from '../../Components/Template/Panel'
import Layout from '../../Components/Template/Layout'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import md5 from 'md5'
import DocumentLegal from '../../Components/Configuration/DocumentLegal'
import { addConfigUserAdmin } from '../../actions/userAdminAction'
import { addTenant } from '../../actions/tenantAction'

const Register = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const { register, handleSubmit, errors, getValues } = useForm()
	const [ loading, setLoading ] = useState(false)
	const [show, setShow] = useState(false)
	const [ title, setTitle ] = useState("")
	const [ url, setUrl ] = useState("")

	const viewTerminos = (type, event) => {
		event.preventDefault()
		setTitle((type==="terminos") ? "Términos y condiciones" : "Políticas de privacidad")
		//const urlName = (type==="terminos") ? "hola.pdf" : "hola.pdf"
		/**let a = document.createElement('a');
        a.href = url;
        a.target="_blank";
        a.click();**/
        setUrl("https://www.rocio.ai/docs/TC-Rocio.pdf")
        setShow(true)


	}

	const handleClose = () => setShow(false)

	const onSubmit =  async (data, e) => {
		e.preventDefault()

		setLoading(true)

		let check = document.querySelector('#terminos_condicion')
		if(!check.checked){
			toast.error("Por favor, acepte los términos y condiciones",{position: toast.POSITION.TOP_RIGHT})
			setLoading(false)
			return false
		}

		let response = await dispatch(addTenant(data.tenant_name))
		
		if(response.err!==null){
			setLoading(false)
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
			return false
		}

		const body = {
			...data,
			password: md5(data.password)
		}
		response = await dispatch(addConfigUserAdmin(body, response.id))
		setLoading(false)

		if(response.err===null){
			history.push('/configure/product/type')
		}else{
			toast.error(response.msg,{position: toast.POSITION.TOP_RIGHT})
		}
	}

	return (
		<Layout volver={true} isLogoWhile={true}>
	  	  	<Panel 
	  	  		url="/login"
	  	  		title="Bienvenido"
	  	  		subtitle="Ingresa tus datos y comienza a configurar Rocio"
	  	  		isVisible={true}
	  	  		isClass="col-xs-12 col-sm-9 col-lg-5 mx-auto"
				isClassCard="padding-config"
	  	  	>
	  	  		<Form  onSubmit={handleSubmit(onSubmit)}>
				    <Row className="mb-3 mt-6">
					    <Col md={6} xs={12} className="mb-3">
					       <div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="name"
					      			autoFocus
					      			id="name"  
					      			className="input" 
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		<label className="label" htmlFor="name">Nombre</label>
							    {errors.name && (
							    	<Form.Text className="text-danger">
							      		{errors?.name?.message}
							    	</Form.Text>
							    )}            
					        </div>	
					    </Col>
					    <Col md={6} xs={12} className="mb-3">
					      	<div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="lastname" 
					      			id="lastname" 
					      			className="input"
					      			ref={register({required: {value: true, message: 'Requerido'}})} 
					      		/>
					      		<label className="label" htmlFor="lastname">Apellido</label>
					      		{errors.lastname && (
							    	<Form.Text className="text-danger">
							      		{errors?.lastname?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					</Row>
					<Row>
					  	<Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="tenant_name" 
					      			id="tenant_name" 
					      			className="input" 
					      			ref={register({required: {value: true, message: 'Requerido'}})} 
					      		/>
					      		<label className="label" htmlFor="tenant_name">Organización</label>
					      		{errors.tenant_name && (
							    	<Form.Text className="text-danger">
							      		{errors?.tenant_name?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design">
					      		<input 
					      			type="text" 
					      			name="email" 
					      			id="email" 
					      			className="input" 
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		<label className="label" htmlFor="email">Correo</label>
					      		{errors.email && (
							    	<Form.Text className="text-danger">
							      		{errors?.email?.message}
							    	</Form.Text>
							    )}  
					        </div>	
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design">
					      		<input 
					      			type="password" 
					      			name="password" 
					      			id="password" 
					      			className="input" 
					      			ref={register({required: {value: true, message: 'Requerido'}})}
					      		/>
					      		<label className="label" htmlFor="password">Contraseña</label>
					      		{errors.password && (
							    	<Form.Text className="text-danger">
							      		{errors?.password?.message}
							    	</Form.Text>
							    )}  
					        </div>
					    </Col>
					    <Col md={12} xs={12} className="mb-4">
					      	<div className="input-group-design">
					      		<input 
					      			type="password" 
					      			name="rep_password" 
					      			id="password" 
					      			className="input" 
					      			 ref={register({
			                              required: {value: true, message: 'Requerido'},
			                              validate: value => {
			                                if (value === getValues()["password"]) {
			                                  return true;
			                                } else {
			                                  return "Las claves no coinciden";
			                                }
			                              }
			                            })}
					      		/>
					      		<label className="label" htmlFor="rep_password">Repetir Contraseña</label>
					      		{errors.rep_password && (
							    	<Form.Text className="text-danger">
							      		{errors?.rep_password?.message}
							    	</Form.Text>
							    )}  
					        </div>
					    </Col>
					    <Col md={12} xs={12}>
					    	<Button variant="primary" className="btn-continuar btn-panel-config mt-5" type="submit" block disabled={loading}>
							    {loading ? 'Procesando...' : 'Continuar' } <span></span>
							</Button>
					    </Col>
					    <Col md={12} xs={12} className="mb-3 mt-4">
							 <div className="form-group form-check">
							    <input type="checkbox" className="form-check-input form-check-border" id="terminos_condicion" />
							    <label className="form-check-label" htmlFor="terminos_condicion">
						    		Acepto  
						    		<a className="ml-1 btn-link input-link mr-1" href="!#" onClick={(event)=>viewTerminos("terminos", event)}>
						    			términos y condiciones
						    		</a> 
						    		{/**y las 
						    		<a className=" ml-2 btn-link input-link" href="!#" onClick={(event)=>viewTerminos("privacidad", event)}>
						    			políticas de privacidad
						    		</a>**/}
							    </label>
							  </div>
					    </Col>
					</Row>
				</Form>
	      	</Panel>
	      	{show && <DocumentLegal show={show} handleClose={handleClose} setShow={setShow} title={title} url={url} />}
	    </Layout>
	);
}

export default Register;