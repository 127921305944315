import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import AWS from 'aws-sdk'

const type_env = process.env.REACT_APP_API_URL==="https://prod.api.rocio.ai" ? "ENV_PROD" : "ENV_UAT"

const COGNITO_USERNAME = "cbastias@morrisopazo.com"
const COGNITO_PASSWORD = "RedRed@1029"
const COGNITO_USER_POOL_ID = type_env==="ENV_PROD" ? "us-east-1_MRk7vNs6G" : "us-east-1_Js4lEXt5F"
const COGNITO_CLIENTE_ID = type_env==="ENV_PROD" ? "5a92b0vujobsij5ogj3obqo2c2" : "5b43e58ioc7tv0pob677ebf1p4"
const COGNITO_REGION = "us-east-1"


const getTokenCognito = async() => {

	AWS.config.update({region: COGNITO_REGION})

	let authenticationData = {
		Username: COGNITO_USERNAME,
		Password: COGNITO_PASSWORD,
	}

	let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
		authenticationData
	)

	let poolData = {
		UserPoolId: COGNITO_USER_POOL_ID, 
		ClientId: COGNITO_CLIENTE_ID, 
	}

	let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
	let userData = {
		Username: authenticationData.Username,
		Pool: userPool,
	}

	let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

	return new Promise(function (resolve, reject) {
	    cognitoUser.authenticateUser(authenticationDetails, {
	      onSuccess: resolve,
	      onFailure: reject,
	      newPasswordRequired: resolve,
	    })
	})
}

export const getAccessToken = async () => {
	try{
		
		const result = await getTokenCognito()
		
		let accessToken = result.getAccessToken().getJwtToken()
		return { status: true, message: '', token: accessToken, refresh: result.getRefreshToken().getToken() }
	}catch(error){
		return { status: false, message: error.message || JSON.stringify(error) }
	}
}

const getSessionUser = async () => {

	let poolData = {
		UserPoolId: COGNITO_USER_POOL_ID, 
		ClientId: COGNITO_CLIENTE_ID, 
	}
	
	let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

	const cognitoUser = userPool.getCurrentUser()

	if(cognitoUser!==null){
		const sessionUser = cognitoUser.getSession(function(err, data) {
	      if (err || !data) {
		    return {status:false, message: err.message || JSON.stringify(err) }
		   }
		   const session = {
		     access_token: data.accessToken.jwtToken,
		     id_token: data.idToken.jwtToken,
		     refresh_token: data.refreshToken.token
		    }
		    return { status: true, session: session, message: '' }
		   
	    })
	    return sessionUser
	}
	
	return { status: false, message: 'No existe usuario'}
}

export const verifyAccessToken = async () => {
	try{
		let token = ''
		const result = await getSessionUser()

		if(typeof result==='undefined' || !result.status){
			let response = await getAccessToken()
			token = response.token
		}else{
			token = result.session.access_token
		}

	    return { status: true, message: '', token }
	}catch(error){
		return { status: false, message: error.message }
	}
}