//import { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
//import ModalProduct from '../Dashboard/Products/ModalProduct'



const DocumentLegal = ({ show, handleClose, setShow, title, url }) => {



	return (
		<Modal
	        show={show}
	        onHide={handleClose}
	        dialogClassName="modal-70w"
	        aria-labelledby="example-custom-modal-styling-title"
	      >
	        <Modal.Header closeButton>
	          <Modal.Title id="example-custom-modal-styling-title">
	            {title}
	          </Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	          <Row>
			    <Col xs={12} className="mb-5">
			      	 <div className="embed-responsive embed-responsive-16by9">
					  <iframe title="Preview Consulta de Ordenes" className="embed-responsive-item" src={url} width="100%" height="300px"></iframe>
					</div>
			    </Col>
			</Row>
	        </Modal.Body>
	      </Modal>
	   
	)
}

export default DocumentLegal