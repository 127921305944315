import { Row, Col, Form } from 'react-bootstrap'
import search from '../../../../assets/images/search.svg'

const Filter = ({ filterText, onFilter, onClear }) => {

	return (
		 <Row className="w-100 pb-3">
	  		<Col lg={6} md={6} sm={6} xs={12} className="pl-0">
	  			<h5 className="title-table">Click globales</h5>
	  		</Col>
			<Col lg={6} md={6} sm={6} xs={12}>
		      	<Form.Group >
		      		<Form.Control 
					    	id="search" 
					    	type="text" 
					    	placeholder="Buscar…"
					    	aria-label="Buscar…"
					    	value={filterText} 
					    	onChange={onFilter}
					    	className="filter-product pl-4"
					    	autoComplete="off"
					    />
					    <div><img src={search} alt="Búsqueda" className="pl-2 pt-2" /></div>
		    	</Form.Group>
		    </Col>
		</Row>
	)
}
	

export default Filter