export const LIST_PRODUCT = "LIST_PRODUCT"
export const LOADING_PRODUCT = "LOADING_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const CHECK_BELL_STATUS_PRODUCT = "CHECK_BELL_STATUS_PRODUCT"
export const EDIT_PRODUCT = "EDIT_PRODUCT"
export const DATA_PRODUCT = "DATA_PRODUCT"
export const DELETE_PRODUCTS = "DELETE_PRODUCTS"
export const SELECT_DELETE_PRODUCTS = "SELECT_DELETE_PRODUCTS"
export const ADD_PRODUCT_SINGLE = "ADD_PRODUCT_SINGLE"
export const RESET_LIST_PRODUCT = "RESET_LIST_PRODUCT"
export const DELETE_PRODUCTS_PREVIEW = "DELETE_PRODUCTS_PREVIEW"
export const LIST_PRODUCT_PREVIEW = "LIST_PRODUCT_PREVIEW"
export const ADD_PRODUCT_LIST = "ADD_PRODUCT_LIST"
export const FILTERED_PRODUCT_DASHBOARD = "FILTERED_PRODUCT_DASHBOARD"
export const GROUP_BY_CATEGORY_PRODUCT = "GROUP_BY_CATEGORY_PRODUCT"
export const GRAFIC_DATA_DASHBOARD = "GRAFIC_DATA_DASHBOARD"