import { Modal } from 'react-bootstrap'


const ModalProduct = ({ show, handleClose, titleModal, children }) => {


	return (
		<Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
	        <Modal.Header closeButton>
	          <Modal.Title id="example-modal-sizes-title-lg" className="title-modal">{ titleModal }</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	{ children }
	        </Modal.Body>
	    </Modal>
	)
}

export default ModalProduct