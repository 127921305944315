import { Col, Card } from 'react-bootstrap'


const Cards = ({ title, is_class, children }) => {

	return (
		<Col className={is_class}>
			<Card body className="preferencia admin-preferencia">
	      		<p className="mb-3">{ title }</p>
	      		{ children }
	      	</Card>
      	</Col>
  );
}

export default Cards;